import { action, observable, makeObservable } from 'mobx'
import * as R from 'ramda'

export const DISPLAY_TIME = 7000

class FlashMessagesStore {
  @observable messages = []

  constructor() {
    makeObservable(this)
  }

  @action('[FlashMessagesStore] add flash message')
  addFlashMessage = ({ text, type, linkText = null, linkUrl = null }) => {
    const indexMessage = this.messages.findIndex(message => (
      message.text === text && message.type === type
    ))

    if (indexMessage === -1) {
      this.messages = [...this.messages, { text, type, linkText, linkUrl }]
    }

    if (type === 'success') {
      setTimeout(this.removeFlashMessage, DISPLAY_TIME, { text, type, linkText, linkUrl })
    }
  }

  @action('[FlashMessagesStore] remove flash message')
  removeFlashMessage = (removeMessage) => {
    this.messages = this.messages.filter(message => !R.equals(message, removeMessage))
  }
}

export default FlashMessagesStore
