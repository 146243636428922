import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers, withProps } from 'recompose'
import cn from 'classnames'

const enhance = compose(
  withProps(({ max, value }) => ({
    value: value || '',
    charsLimit: max - (value || '').toString().length
  })),
  withHandlers({
    onChange: ({ onChange }) => e => {
      onChange(e.target.value)
    }
  })
)

const FieldWithCharacterLimits = ({ charsLimit, ...props }) => (
  <div className='FieldWithCharacterLimits'>
    <input type='text' {...props} />
    <div className={cn('FieldWithCharacterLimits-charsLimit', { isAlert: charsLimit < 0 })}>
      {charsLimit}
    </div>
  </div>
)

FieldWithCharacterLimits.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onChange: PropTypes.func.isRequired,
  charsLimit: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
}

export default enhance(FieldWithCharacterLimits)
