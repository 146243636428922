import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent, renderNothing } from 'recompose'
import ContentEditable from '../../ContentEditable'

const TextField = ({
  onChange,
  onKeyDown,
  onPaste,
  onFocus,
  onBlur,
  onKeyPress,
  text,
  placeholder,
  className,
  editableClassName,
  editableRef
}) => (
  <div className={className}>
    <ContentEditable
      className={editableClassName}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onPaste={onPaste}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      onRef={editableRef}
    >
      { text }
    </ContentEditable>
  </div>
)

const SearchField = branch(
  ({ isActive }) => isActive,
  renderComponent(TextField),
  renderNothing
)

TextField.propTypes = {
  isActive: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onPaste: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  editableClassName: PropTypes.string,
  editableRef: PropTypes.func
}

export default SearchField()
