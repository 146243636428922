import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { defaultProps, withHandlers, compose } from 'recompose'
import cn from 'classnames'

const enhance = compose(
  defaultProps({
    identity: v => v,
    label: v => v,
    onChange: () => {},
    disabledOptions: []
  }),
  withHandlers({
    onRadioButtonChange: ({ onChange }) => e => {
      onChange(e.target.value)
    }
  })
)

const RadioButtonGroup = ({ value, identity, label, options, onRadioButtonChange, className, disabledOptions }) => (
  <div className={cn('RadioButtonGroup', className)}>
    {
      options.map(option => (
        <div key={identity(option)} className='RadioButtonGroup-radio'>
          <div className={cn('Radio', { isDisabled: disabledOptions.includes(identity(option)) })}>
            <label className='Radio-label'>
              <input
                name={options.join(',')}
                className='Radio-input'
                type='radio'
                checked={value === identity(option)}
                value={identity(option)}
                onChange={onRadioButtonChange}
                disabled={disabledOptions.includes(identity(option))}
              />
              <span className='Radio-icon'/>
              {label(option)}
            </label>
          </div>
        </div>
      ))
    }
  </div>
)

RadioButtonGroup.propTypes = {
  value: PropTypes.string,
  options: MobxPropTypes.arrayOrObservableArray,
  onChange: PropTypes.func.isRequired,
  onRadioButtonChange: PropTypes.func.isRequired,
  identity: PropTypes.func,
  label: PropTypes.func,
  className: PropTypes.string,
  disabledOptions: MobxPropTypes.arrayOrObservableArray
}

export default enhance(RadioButtonGroup)
