import { withStateHandlers, lifecycle, compose, withHandlers } from 'recompose'
import { replaceImage } from '../../lib/utils/dom'

const waitImagesLoad = ({ getImages, callback, getPreviewVariant, placeholderImage }) => compose(
  withStateHandlers(
    { imagesLoaded: false },
    { setImagesLoaded: () => v => ({ imagesLoaded: v })}
  ),
  withHandlers({
    checkLoadImages: props => () => {
      const { setImagesLoaded } = props
      const images = getImages(props)
      const previewVariant = getPreviewVariant(props)

      let loadedImages = 0
      const setLoadImages = () => {
        loadedImages = loadedImages + 1
        if (loadedImages === images.length) {
          callback(props)
          setImagesLoaded(true)
        }
      }

      images.forEach(image => {
        const img = new Image()
        img.onload = setLoadImages
        img.onerror = replaceImage(placeholderImage)
        img.src = image[previewVariant].webp || image[previewVariant]
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.checkLoadImages()
    }
  })
)

export default waitImagesLoad
