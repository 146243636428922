import React from 'react'
import PropTypes from 'prop-types'

const Input = props => {
  let { tagName: Tag = 'input', value, inputRef, ...otherProps } = props

  delete otherProps.meta

  if (value === null) {
    value = ''
  }

  return (
    <Tag
      {...otherProps}
      value={value}
      ref={inputRef}
      onChange={e => props.onChange(e.target.value, e)}
    />
  )
}

Input.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  tagName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  inputRef: PropTypes.func,
  meta: PropTypes.object
}

export default Input
