import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent } from 'recompose'

const DetailInfo = ({ item }) => (
  <div className='Card-detailInfo'>
    <ul className='Card-detailGroup'>
      <li className='Card-detailItem'>
        <span className='Card-itemNumber'>
          { item.beds }
        </span>
        { ' ' }
        <span className='Card-itemUnit'>
          BD
        </span>
      </li>
      <li className='Card-detailItem'>
        <span className='Card-itemNumber'>
          { item.baths }
        </span>
        { ' ' }
        <span className='Card-itemUnit'>
          BA
        </span>
      </li>
    </ul>
    <ul className='Card-detailGroup'>
      <li className='Card-detailItem'>
        <span className='Card-itemNumber'>
          { item.acreage }
        </span>
        { ' ' }
        <span className='Card-itemUnit'>
          ACR
        </span>
      </li>
      <li className='Card-detailItem'>
        <span className='Card-itemNumber'>
          { item.sqft }
        </span>
        { ' ' }
        <span className='Card-itemUnit'>
          FT<sup className='Card-itemUnit Card-itemUnit--sup'>2</sup>
        </span>
      </li>
    </ul>
  </div>
)

const DetailInfoLand = ({ item }) => (
  <div className='Card-detailInfo'>
    <div className='Card-detailGroup'>
      <div className='Card-detailItem'>
        <span className='Card-itemNumber'>
          { item.acreage }
        </span>
        { ' ' }
        <span className='Card-itemUnit'>
          ACR
        </span>
      </div>
    </div>
  </div>
)

const propTypes = {
  item: PropTypes.shape({
    land: PropTypes.bool,
    baths: PropTypes.number,
    beds: PropTypes.number,
    acreage: PropTypes.number,
    sqft: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })
}

DetailInfo.propTypes = propTypes
DetailInfoLand.propTypes = propTypes

export default branch(
  ({ item }) => item.land,
  renderComponent(DetailInfoLand),
  renderComponent(DetailInfo)
)()
