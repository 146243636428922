import { useState, useCallback } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { GOOGLE_MAP_API_KEY } from '../../lib/config/googleMapApi'

export const useGoogleMap = () => {
  const [map, setMap] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    nonce: 'google-map',
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  })

  const onMapLoad = useCallback(mapInstance => {
    setMap(mapInstance)
  }, [])

  const onMapUnmount = useCallback(() => {
    setMap(null)
  }, [])

  return {
    map,
    isLoaded,
    onMapLoad,
    onMapUnmount
  }
}
