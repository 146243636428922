import React from 'react'
import { defaultProps } from 'recompose'
import { isEmailValid } from './utils'
import TokenField from '../TokenField'

const enhance = defaultProps({
  breakKeys: [',', 'Enter', ' ']
})

const EmailTokenField = props => (
  <TokenField {...props} validateText={isEmailValid} />
)

export default enhance(EmailTokenField)
