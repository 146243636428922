import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from '../../lib/propTypes'

const withPricePeriod = WrappedSelectPricePeriod => {
  const PricePeriod = props => {
    return (
      <Fragment>
        <WrappedSelectPricePeriod {...props} />
        {props.children}
      </Fragment>
    )
  }

  PricePeriod.propTypes = {
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.any,
    optionIdentity: PropTypes.func.isRequired,
    optionTemplate: PropTypes.func,
    children: childrenPropType
  }

  return PricePeriod
}

export default withPricePeriod
