import * as yup from 'yup'

yup.addMethod(yup.number, 'lessOrEqualToField', function (ref, message) {
  return this.test('lessOrEqualToField', message, function (value) {
    return value <= this.resolve(ref)
  })
})

yup.addMethod(yup.number, 'greaterOrEqualToField', function (ref, message) {
  return this.test('lessOrEqualToField', message, function (value) {
    return value >= this.resolve(ref)
  })
})

const priceRangeSchema = (min, max) => yup.object({
  priceFrom: yup.number()
    .max(max, `Greater than ${max}`)
    .min(min, `Less than ${min}`)
    .lessOrEqualToField(yup.ref('priceTo'), 'Greater than Max price'),
  priceTo: yup.number()
    .max(max, `Greater than ${max}`)
    .min(min, `Less than ${min}`)
    .greaterOrEqualToField(yup.ref('priceFrom'), 'Less than Min price')
})

export default priceRangeSchema
