import { reaction } from 'mobx'
import UIStateStore from './UIStateStore'
import RecentSearchesStore from './RecentSearchesStore'
import SearchResultsStore from './SearchResultsStore'
import RecentViewsStore from './RecentViewsStore'
import { isEmptyObject } from '../../../lib/utils/common'

class RootStore {
  constructor(context) {
    this.uiStateStore = new UIStateStore(context)
    this.recentSearchesStore = new RecentSearchesStore(context)
    this.searchResultsStore = new SearchResultsStore(context)
    this.recentViewsStore = new RecentViewsStore(context)
    this.setReactions()
  }

  fetchInitialData = () => {
    this.recentViewsStore.fetchInitialData()
    this.recentSearchesStore.fetchInitialData()
  }

  resetSearch = () => {
    this.uiStateStore.toggleSearchActive(false)
    this.searchResultsStore.clearRequestCache()
  }

  setReactions() {
    reaction(
      () => this.recentViewsStore.collection.length > 0,
      isCollectionPresent => {
        this.uiStateStore.toggleRecentViewsPresent(isCollectionPresent)
      }
    )

    reaction(
      () => this.recentSearchesStore.collection.length > 0,
      isCollectionPresent => {
        this.uiStateStore.toggleRecentSearchesPresent(isCollectionPresent)
      }
    )

    reaction(
      () => !isEmptyObject(this.searchResultsStore.results),
      isResultsFetched => {
        this.uiStateStore.toggleSearchStarted(isResultsFetched)
      }
    )

    reaction(
      () => this.uiStateStore.isSearchActive,
      isSearchActive => {
        if (!isSearchActive) {
          this.searchResultsStore.reset()
        }
      }
    )
  }
}

export default RootStore
