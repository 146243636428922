import React from 'react'
import PropTypes from 'prop-types'
import { withHandlers, compose } from 'recompose'

const enhance = compose(
  withHandlers({
    onChangeHandler: ({ onChange }) => e => {
      onChange(e.target.value)
    }
  })
)

const RadioTab = ({ id, value, name, label, checked, onChangeHandler}) => (
  <div className='RadioTab'>
    <input
      id={id}
      name={name}
      className='RadioTab-radioInput'
      type='radio'
      checked={checked}
      value={value}
      onChange={onChangeHandler}
    />
    <label htmlFor={id} className='RadioTab-label'>
      {label || value}
    </label>
  </div>
)

RadioTab.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired
}

export default enhance(RadioTab)
