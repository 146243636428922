const getFileName = path => path.split(/(\\|\/)/g).pop().split('.').shift()

const getComponent = (reqctx, key) => {
  let component = reqctx(key)
  if (component.__esModule) {
    component = component.default
  }
  return component
}

const buildComponentsRegister = reqctx => {
  let cache = {}
  reqctx.keys().forEach(key => {
    const name = getFileName(key)
    cache[name] = getComponent(reqctx, key)
  })

  return cache
}

export default buildComponentsRegister
