import React from 'react'
import PropTypes from 'prop-types'
import { Recent } from '../../../../Svg'

const AdditionalOptionTemplate = ({ name }) => (
  <span>
    <span className='Multiselect-optionIcon'>
      <Recent />
    </span>
    { name }
  </span>
)

AdditionalOptionTemplate.propTypes = {
  name: PropTypes.string.isRequired
}

export default AdditionalOptionTemplate
