import { useRef } from 'react'

const useCaptchaHandlers = () => {
  const captchaRef = useRef(null)
  const resetCaptcha = () => {
    if (captchaRef.current) {
      captchaRef.current.reset()
    }
  }

  return {
    captchaRef,
    resetCaptcha
  }
}

export default useCaptchaHandlers
