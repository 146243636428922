import React from 'react'
import PropTypes from 'prop-types'
import { replaceImage } from '../../lib/utils/dom'
import { ProfileDefaultPhotoUrl } from '../../components/Svg'

const Avatar = ({ companyName, photo }) => (
  <div className='Avatar'>
    <img
      loading='auto'
      className='Avatar-image'
      alt={companyName}
      src={photo || ProfileDefaultPhotoUrl}
      onError={replaceImage(ProfileDefaultPhotoUrl)}
    />
  </div>
)

Avatar.propTypes = {
  companyName: PropTypes.string,
  photo: PropTypes.string
}

export default Avatar
