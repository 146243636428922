import React from 'react'
import OptionTemplate from './OptionTemplate'
import AdditionalOptionTemplate from './AdditionalOptionTemplate'
import TownWrapper from '../TownWrapper'
import Multiselect from '../../Multiselect'

const PillsTownSelect = props => (
  <Multiselect
    optionIdentity={({name}) => name}
    wrapperComponent={TownWrapper}
    optionTemplate={OptionTemplate}
    selectedOptionSort={(a, b) => (a.name > b.name)}
    additionalOptionTemplate={AdditionalOptionTemplate}
    {...props}
  />
)

export default PillsTownSelect
