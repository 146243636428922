import React from 'react'
import ReactDOM from 'react-dom'
import { isString, isServer } from '../../lib/utils/common'
import { identity } from '../../lib/utils/selectors'

const withPortal = destination => Component => (
  isServer()
    ? identity
    : (props => {
      const domElement = isString(destination)
        ? document.querySelector(destination)
        : destination

      if (domElement) {
        return ReactDOM.createPortal(<Component {...props} />, domElement)
      }
      // eslint-disable-next-line no-console
      console.warn(`${Component.displayName} use withPortal HOC, but not find dom element`)
      return null
    })
)

export default withPortal
