import { withHandlers } from 'recompose'
import { isTouchScreen } from '../../lib/utils/viewport'

const withScrollToAnchor = withHandlers(() => {
  let anchorElement

  return {
    registerAnchorElement: () => ref => {
      anchorElement = ref
    },
    scrollToAnchor: () => () => {
      if (isTouchScreen()) {
        anchorElement.scrollIntoView()
      }
    }
  }
})

export default withScrollToAnchor
