import { observable, action, makeObservable } from 'mobx'
import { fromServerToClient } from '../../../lib/utils/collection'

class RecentSearchesStore {
  @observable collection = []

  constructor({ transport }) {
    makeObservable(this)

    this.transport = transport
  }

  fetchInitialData() {
    this.transport.RecentSearches.get().then(
      action('[RecentSearchesStore] fetch initial data', response => {
        this.collection = fromServerToClient(response)
      })
    )
  }
}

export default RecentSearchesStore
