import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import ClickOutHandler from 'react-onclickout'
import classNames from 'classnames'
import { removeByIndex, removeFromArray, flatten, uniqBy } from '../../../../lib/utils/collection'
import { fromContentEditable, onKey, watchKey } from '../../../../lib/utils/dom'
import SearchField from '../SearchField'
import Options from './Options'
import AdditionalOptionGroups from './AdditionalOptionGroups'
import WrapperSelectedOptions from './WrapperSelectedOptions'

const WAIT_FOR_FOCUS_AFTER_REDRAW = 200

class Multiselect extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    additionalOptionGroups: PropTypes.object,
    additionalOptionTemplate: PropTypes.func,
    value: MobxPropTypes.arrayOrObservableArray,
    onChange: PropTypes.func,
    onActiveChanged: PropTypes.func,
    optionIdentity: PropTypes.func,
    filterIdentity: PropTypes.func,
    selectedOptionTemplate: PropTypes.func,
    selectedOptionSort: PropTypes.func,
    optionTemplate: PropTypes.func,
    height: PropTypes.number,
    tabindex: PropTypes.number,
    wrapperComponent: PropTypes.func,
    wrapperSelectedOptions: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    options: [],
    value: [],
    optionIdentity: ({id}) => id,
    filterIdentity: ({name}) => name,
    selectedOptionTemplate: ({name}) => name,
    selectedOptionSort: () => {},
    optionTemplate: ({name}) => name,
    additionalOptionTemplate: ({name}) => name,
    onChange: () => {},
    onActiveChanged: () => {},
    height: 53,
    tabindex: 0,
    wrapperComponent: (({children}) => children),
    wrapperSelectedOptions: WrapperSelectedOptions
  }

  state = {...this.getInitialState(this.props), isActive: false, selectedIndex: 0}

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState(() => this.getInitialState(props))
  }

  getInitialState(props) {
    return {
      value: props.value,
      searchText: '',
      markForDestroyIndex: -1
    }
  }

  setActiveOption = option => {
    let index = this.availableOptions.findIndex(o => o === option)

    if (index === -1) {
      index = this.availableAdditionalOptions.findIndex(o => o === option)
      index = index + this.availableOptions.length
    }

    this.setState(() => ({
      selectedIndex: index
    }))
  }

  unSetActiveOption = () => {}

  nextActiveOption = () => {
    const max = this.availableOptions.length + this.availableAdditionalOptions.length - 1

    this.setState(state => ({
      selectedIndex: Math.min(state.selectedIndex + 1, max)
    }))
  }

  prevActiveOption = () => {
    const min = 0

    this.setState(state => ({
      selectedIndex: Math.max(state.selectedIndex - 1, min)
    }))
  }

  applySelectedOption = () => {
    const { selectedIndex } = this.state
    let option = this.availableOptions[selectedIndex]

    if (!option) {
      const indexOffset = this.availableOptions.length
      const index = selectedIndex - indexOffset
      option = this.availableAdditionalOptions[index]
    }

    if (option) {
      this.setState(
        () => ({ searchText: '' }),
        () => { this.onSelect(option) }
      )
    }
  }

  updateSelectedIndex = () => {
    const optionsLength = this.availableOptions.length + this.availableAdditionalOptions.length

    this.setState(state => {
      const selectedIndex = Math.min(state.selectedIndex, Math.max(optionsLength - 1, 0))

      return {
        selectedIndex
      }
    })
  }

  sortByMatch(a, b) {
    const { filterIdentity } = this.props
    const { searchText } = this.state
    const optionA = filterIdentity(a).toLocaleLowerCase().match(searchText.toLowerCase()).index
    const optionB = filterIdentity(b).toLocaleLowerCase().match(searchText.toLowerCase()).index

    return optionA - optionB
  }

  get selectedOptions() {
    const { options, optionIdentity, additionalOptionGroups, selectedOptionSort } = this.props
    const { value } = this.state
    const fromRegularOptions = options.filter(o => value.includes(optionIdentity(o)))
    const fromAdditionalOptions = additionalOptionGroups ?
      flatten(Object.values(additionalOptionGroups)).filter(o => value.includes(optionIdentity(o))) : []

    return (
      uniqBy([...fromRegularOptions, ...fromAdditionalOptions], optionIdentity)
        .sort(selectedOptionSort)
    )
  }

  get availableOptions() {
    const { options, optionIdentity, filterIdentity } = this.props
    const { value, searchText } = this.state
    let result = options.filter(o => !value.includes(optionIdentity(o)))

    if (searchText !== '') {
      result = result
        .filter(o => filterIdentity(o).toLocaleLowerCase().includes(searchText.toLowerCase()))
        .sort((a, b) => this.sortByMatch(a, b))
    }

    return result
  }

  get availableAdditionalOptions() {
    const { additionalOptionGroups, optionIdentity } = this.props
    const { value } = this.state
    return additionalOptionGroups ?
      flatten(Object.values(additionalOptionGroups)).filter(o => !value.includes(optionIdentity(o)))
      : []
  }

  get highlightedOption() {
    const { selectedIndex } = this.state
    const availableOptions = this.availableOptions
    const availableAdditionalOptions = this.availableAdditionalOptions

    if (selectedIndex < availableOptions.length) {
      return availableOptions[selectedIndex]
    }

    const indexOffset = availableOptions.length
    const index = selectedIndex - indexOffset

    return availableAdditionalOptions[index]
  }

  get style() {
    const { height } = this.props

    return { minHeight: height }
  }

  get hasSelected() {
    return this.selectedOptions.length > 0
  }

  get hasRegularOptions() {
    return this.availableOptions.length > 0
  }

  get hasAdditionalOptions() {
    return this.props.additionalOptionGroups &&
           this.availableAdditionalOptions.length > 0
  }

  get hasOptions() {
    return this.hasRegularOptions || this.hasAdditionalOptions
  }

  onKeyDown = e => {
    this.unMarkDestory()

    onKey(e, 'ArrowDown', this.nextActiveOption)
    onKey(e, 'ArrowUp', this.prevActiveOption)
    onKey(e, 'Escape', this.onDeactivateOptions)

    watchKey(e, 'Backspace', this.onTryDestroy)
  }

  onKeyPress = e => {
    onKey(e, 'Enter', this.applySelectedOption)
  }

  onTryDestroy = () => {
    const { searchText, markForDestroyIndex } = this.state

    if (searchText.length < 1) {
      if (markForDestroyIndex !== -1) {
        const option = this.selectedOptions[markForDestroyIndex]
        this.onDestroy(option)
      } else {
        this.setState(() => ({ markForDestroyIndex: this.selectedOptions.length - 1 }))
      }
    }
  }

  unMarkDestory = () => {
    this.setState(() => ({ markForDestroyIndex: -1 }))
  }

  onDestroy = option => {
    const { optionIdentity, onChange } = this.props

    this.setState(
      state => {
        const itemToRemove = optionIdentity(option)
        const indexToRemove = state.value.findIndex(i => i === itemToRemove)

        return {
          value: removeByIndex(state.value, indexToRemove),
          markForDestroyIndex: -1
        }
      },
      () => { onChange(this.state) }
    )
  }

  onSelect = option => {
    const { onChange, optionIdentity } = this.props

    this.setState(
      state => {
        const id = optionIdentity(option)
        const value = state.value.includes(id) ? removeFromArray(state.value, id) : [...state.value, id]

        return {
          value,
          markForDestroyIndex: -1
        }
      },
      () => {
        this.updateSelectedIndex()
        onChange(this.state)
      }
    )
  }

  onActivateOptions = () => {
    this.setState(() => ({ isActive: true }), () => {
      this.props.onActiveChanged(this.state.isActive)
    })
  }

  onDeactivateOptions = () => {
    this.setState(() => ({ isActive: false }), () => {
      this.props.onActiveChanged(this.state.isActive)
    })
  }

  onSearch = ({target: {value}}) => {
    this.setState(() => ({ searchText: fromContentEditable(value) }))
  }

  onBlur = e => {
    const currentTarget = e.currentTarget

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.onDeactivateOptions()
      }
    }, WAIT_FOR_FOCUS_AFTER_REDRAW)
  }

  render() {
    const {
      optionIdentity, selectedOptionTemplate,
      height, optionTemplate, tabindex, wrapperComponent,
      additionalOptionTemplate, className, placeholder,
      wrapperSelectedOptions,
      additionalOptionGroups
    } = this.props
    const { isActive, searchText, markForDestroyIndex } = this.state
    const hasSelected = this.hasSelected
    const WrapperComponent = wrapperComponent
    const WrapperSelectedOptionsComponent = wrapperSelectedOptions
    const cn = classNames('Multiselect', className, { isActive, hasSelected })

    return (
      <ClickOutHandler onClickOut={this.onDeactivateOptions}>
        <div
          className={cn}
          style={this.style}
          onClick={this.onActivateOptions}
          onBlur={this.onBlur}
        >
          <WrapperComponent {...this.props}>
            <div className='Multiselect-content'>
              <div className='Multiselect-selectedOptions'>
                <WrapperSelectedOptionsComponent
                  hasSelected={this.hasSelected}
                  placeholder={placeholder}
                  selectedOptions={this.selectedOptions}
                  height={height}
                  isActive={isActive}
                  optionIdentity={optionIdentity}
                  selectedOptionTemplate={selectedOptionTemplate}
                  onDestroy={this.onDestroy}
                  options={this.selectedOptions}
                  markForDestroyIndex={markForDestroyIndex}
                />
                <SearchField
                  className='Multiselect-searchField'
                  editableClassName='Multiselect-editable'
                  isActive={isActive}
                  placeholder={null}
                  onChange={this.onSearch}
                  onKeyDown={this.onKeyDown}
                  onKeyPress={this.onKeyPress}
                  text={searchText}
                />
              </div>

              <div
                className='Multiselect-focusDetector'
                tabIndex={isActive ? null : tabindex}
                onFocus={this.onActivateOptions}
              />

              <Options
                isActive={isActive && this.hasOptions}
                selectedOptions={this.state.value}
                highlightedOption={this.highlightedOption}
                options={this.availableOptions}
                optionTemplate={optionTemplate}
                optionIdentity={optionIdentity}
                onSelect={this.onSelect}
                onMouseEnter={this.setActiveOption}
                onMouseLeave={this.unSetActiveOption}
                matchText={searchText}
              >
                <AdditionalOptionGroups
                  isActive={isActive && this.hasAdditionalOptions}
                  selectedOptions={this.state.value}
                  highlightedOption={this.highlightedOption}
                  additionalOptionGroups={additionalOptionGroups}
                  optionTemplate={additionalOptionTemplate}
                  optionIdentity={optionIdentity}
                  onSelect={this.onSelect}
                  onMouseEnter={this.setActiveOption}
                  onMouseLeave={this.unSetActiveOption}
                />
              </Options>
            </div>
          </WrapperComponent>
        </div>
      </ClickOutHandler>
    )
  }
}

export default Multiselect
