import React from 'react'
import PropTypes from 'prop-types'
import FilterPill from '../../FilterPill'

const Option = ({option, optionIdentity, selectedOptionTemplate, onDestroy, isMarked}) => (
  <div key={optionIdentity(option)} className='Multiselect-selectedOption'>
    <FilterPill
      content={selectedOptionTemplate(option)}
      isMarked={isMarked}
      onDestroy={() => {
        onDestroy(option)
      }}
    />
  </div>
)

Option.propTypes = {
  option: PropTypes.any.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  selectedOptionTemplate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  isMarked: PropTypes.bool
}

export default Option
