import React from 'react'
import PropTypes from 'prop-types'
import { formatValue } from './utils'
import { Viewport } from '../../Viewport'

const NumberField = ({
  id, name, values, transientValue, currentIndex, onKeyDown,
  step, onChange, onInputChange, min, max
}) => {
  const value = transientValue === null ? values[currentIndex] : transientValue

  return (
    <div className='NumberInput-field'>
      <input type='text'
        id={id}
        name={name}
        value={value}
        className='NumberInput-fieldInput'
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        autoComplete='off'
        role='spinbutton'
        aria-valuemax={max}
        aria-valuemin={min}
        aria-valuenow={value}
      />

      <Viewport only='mobile'>
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          id={id}
          value={currentIndex}
          onChange={e => {
            onChange(Number(e.target.value))
          }}
          name={name}
          className='NumberInput-ghostSelect'
        >
          {
            values.filter((_, i) => i % step === 0).map((v, i) => (
              <option key={i} value={i * step}>
                {formatValue(v)}
              </option>
            ))
          }
        </select>
      </Viewport>
    </div>
  )
}

NumberField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  transientValue: PropTypes.any,
  currentIndex: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
}

export default NumberField
