import * as R from 'ramda'
import { isPresent, isSuperset } from '../utils/collection'

export const groupByRoot = collection => R.pipe(
  R.groupBy(({ parent }) => parent.id),
  R.toPairs,
  R.map(([key, val]) => (
    {
      id: key,
      name: key,
      parent: { id: null },
      children: val
    }
  ))
)(collection)

export const flatByChildren = node => {
  return R.isEmpty(node.children) ? node : [node, ...node.children.map(item => flatByChildren(item))]
}

export const normalizeChildren = node => {
  const children = node.children

  return R.isEmpty(children) ? node : { ...node, children: R.pluck('id', children) }
}

export const deepFindChildrenId = (iDs = []) => (collection = []) => {
  if (R.isEmpty(iDs)) { return iDs }

  const childrenID = R.pipe(
    R.filter(n => R.includes(n.id, iDs)),
    R.pluck('children')
  )(collection)

  return R.flatten([...iDs, deepFindChildrenId(childrenID)(collection)])
}

export const parentIdentity = R.pathOr(null, ['parent', 'id'])
export const hasParent = option => isPresent(parentIdentity(option))
export const hasChildren = option => isPresent(option.children)
export const getParentNode = parentId => R.find(R.propEq('id', parentId))

export const findParentsId = (option = {}, iDs = []) => collection => {
  const parentId = parentIdentity(option)

  if (R.isNil(parentId)) {
    return iDs
  }

  const parentNode = R.find(R.propEq('id', parentId), collection)
  return findParentsId(parentNode, [...iDs, parentNode.id])(collection)
}

export const addParentId = options => {
  const addId = (option, value) => {
    const result = [...value]
    const parentId = parentIdentity(option)
    const parentNode = getParentNode(parentId)(options)
    const children = R.propOr([], 'children', parentNode)
    const hasInactiveChild = !isSuperset(new Set(result), new Set(children))

    if (R.isNil(parentId) || hasInactiveChild) {
      return result
    }

    result.push(parentId)

    return addId(parentNode, result)
  }

  return addId
}

export const buildTreeTownOptions = collection => (
  R.pipe(
    groupByRoot,
    R.map(flatByChildren),
    R.flatten,
    R.map(normalizeChildren)
  )(collection)
)

const manageParentNode = optionsById => {
  const addNode = (acc, option) => {
    acc[option.id] = option
    if (!hasParent(option)) { return acc }

    const parentId = parentIdentity(option)
    const parent = optionsById[parentId]
    acc[parentId] = parent

    return addNode(acc, parent)
  }
  return addNode
}

export const buildSelectedOptionsWithParents = (options, selectedOptions, optionsById) => {
  const selectedWithParents = selectedOptions.reduce(manageParentNode(optionsById), {})
  const isSelected = id => id in selectedWithParents
  const hasAllChildrenSelected = ({ children }) => !children.every(id => id in selectedWithParents)

  if (!isPresent(selectedWithParents)) {
    return []
  }

  // We should use raw options as they have the correct order to build selected ones
  const result = options
    .filter(({ id }) => id in selectedWithParents)
    .filter(option => {
      const parentId = parentIdentity(option)
      return !parentId ? true : hasAllChildrenSelected(selectedWithParents[parentId])
    })
    .map(option => ({ ...option, children: option.children.filter(isSelected) }))

  return result
}
