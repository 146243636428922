import React from 'react'
import PropTypes from 'prop-types'
import Placeholder from './Placeholder'

const SelectedOptions = ({ placeholder, isActive, hasSelected, options, selectedOptionTemplate }) => {
  if (placeholder && !isActive && !hasSelected) {
    return (
      <Placeholder>{ placeholder }</Placeholder>
    )
  } else if (hasSelected && !isActive) {
    return (
      <div className='Multiselect-truncatedSelectedOptions'>
        { options.map(selectedOptionTemplate).join(', ') }
      </div>
    )
  }

  return null
}

SelectedOptions.propTypes = {
  placeholder: PropTypes.string,
  isActive: PropTypes.bool,
  hasSelected: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selectedOptionTemplate: PropTypes.func.isRequired
}

export default SelectedOptions
