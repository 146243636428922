import React from 'react'
import PropTypes from 'prop-types'

const HtmlErrorMessageWithIcon = ({ children }) => (
  <div className='ErrorMessageWithIcon'>
    <div className='ErrorMessageWithIcon-content' dangerouslySetInnerHTML={{__html: children}} />
  </div>
)

HtmlErrorMessageWithIcon.propTypes = {
  children: PropTypes.string
}

export default HtmlErrorMessageWithIcon
