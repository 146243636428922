import { useEffect } from 'react'

const resetStore = store => {
  store.reset()
}

const useTurbolinksReload = ({
  store,
  action = resetStore,
  event = 'turbolinks:before-cache'
}) => {
  const bindAction = () => { action(store) }

  useEffect(() => {
    global.document.addEventListener(event, bindAction)
    return () => {
      global.document.removeEventListener(event, bindAction)
    }
  }, [])
}

export default useTurbolinksReload
