import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent, renderNothing } from 'recompose'
import cn from 'classnames'
import { isPresent } from '../../../../lib/utils/collection'
import OptionsTree from './OptionsTree'

const ActiveOptions = ({
  options,
  values,
  optionIdentity,
  optionTemplate,
  onSelect,
  onClearAll,
  withClearAll
}) => (
  <div className={cn('CheckyTreeSelect-options', { 'CheckyTreeSelect-options--withClearAll': withClearAll })}>
    <OptionsTree
      className='CheckyTreeSelect-option'
      options={options}
      optionIdentity={optionIdentity}
      optionTemplate={optionTemplate}
      onSelect={onSelect}
      values={values}
    />
    {withClearAll &&
     <div
       onClick={onClearAll}
       className={cn('CheckyTreeSelect-clearButton', { isActive: isPresent(values) })}
     >
       Clear selection
     </div>}
  </div>
)

ActiveOptions.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  withClearAll: PropTypes.bool
}

const Options = branch(
  ({ isActive }) => isActive,
  renderComponent(ActiveOptions),
  renderNothing
)

export default Options()
