import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { branch, renderComponent, renderNothing } from 'recompose'
import { isPresent, isEmpty } from '../../../../lib/utils/collection'
import { noop } from '../../../../lib/utils/common'
import { withScrollToActiveOption } from '../../../Hocs'
import OptionScroller from '../Shared/OptionScroller'

const ActiveOptions = props => {
  const {
    id,
    options,
    selectedOptions,
    optionIdentity,
    optionTemplate,
    onSelect,
    onClearAll,
    withClearAll,
    className,
    wrapperOptionsComponent,
    selectedIndex,
    onMouseEnter,
    onMouseLeave,
    setActiveOptionRef,
    setOptionsRef,
    withScrollIntoView,
    onKeyDownContent
  } = props

  const optionsRef = useRef(null)

  useEffect(() => {
    const contentNode = optionsRef.current
    contentNode.addEventListener('keydown', onKeyDownContent)

    if (withScrollIntoView) { contentNode.scrollIntoView() }

    return () => {
      contentNode.removeEventListener('keydown', onKeyDownContent)
    }
  }, [])

  const handleStopPropagation = e => e.stopPropagation()
  const handleMouseEnter = index => () => onMouseEnter(index)
  const handleMouseLeave = () => onMouseLeave(-1)
  const handleClick = option => () => onSelect(option)

  const WrapperOptionsComponent = wrapperOptionsComponent

  return (
    <div
      className={cn('CheckySelect-options', { 'CheckySelect-options--withClearAll': withClearAll })}
      ref={optionsRef}
    >
      <WrapperOptionsComponent {...props}>
        <OptionScroller setOptionsRef={setOptionsRef}>
          <ul
            className='CheckySelect-optionsList'
            role='listbox'
            id={id}
          >
            {options.map((option, index) => {
              const isActive = selectedOptions.includes(option)

              return (
                <li
                  role='option'
                  id={option.id}
                  aria-selected={isActive}
                  className={cn(
                    'CheckySelect-option',
                    className,
                    { isActive: isActive, isHighlighted: index === selectedIndex }
                  )}
                  key={optionIdentity(option)}
                  onClick={handleClick(option)}
                  onKeyDown={noop}
                  onMouseEnter={handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  tabIndex='-1'
                  ref={setActiveOptionRef(option)}
                >
                  <div className='Checkbox Checkbox--grayscale' role='presentation'>
                    <input type='checkbox'
                      checked={selectedOptions.includes(option)}
                      onClick={handleStopPropagation}
                      tabIndex='-1'
                      className='Checkbox-input'
                      readOnly
                    />
                    <span className='Checkbox-icon'/>
                  </div>
                  <div className='CheckySelect-optionText'>
                    {optionTemplate(option)}
                  </div>
                </li>
              )
            })}
          </ul>
        </OptionScroller>
      </WrapperOptionsComponent>

      {withClearAll &&
        <button
          onClick={onClearAll}
          className={cn('CheckySelect-clearButton', { isActive: isPresent(selectedOptions) })}
          disabled={isEmpty(selectedOptions)}
        >
          Clear selection
        </button>
      }
    </div>
  )
}

ActiveOptions.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  withClearAll: PropTypes.bool,
  className: PropTypes.string,
  wrapperOptionsComponent: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  id: PropTypes.string.isRequired,
  setActiveOptionRef: PropTypes.func.isRequired,
  setOptionsRef: PropTypes.func.isRequired,
  isSelectedOption: PropTypes.func,
  withScrollIntoView: PropTypes.bool,
  onKeyDownContent: PropTypes.func.isRequired
}

ActiveOptions.defaultProps = {
  wrapperOptionsComponent: (({ children }) => children)
}

const Options = branch(
  ({ isActive }) => isActive,
  renderComponent(withScrollToActiveOption(ActiveOptions)),
  renderNothing
)()

export default Options
