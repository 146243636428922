import React from 'react'
import PropTypes from 'prop-types'

const SearchLink = ({ url, title, className, ...props }) => (
  <a href={url} className={className} { ...props } data-turbolinks='false'>
    { title }
  </a>
)

SearchLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
}

export default SearchLink
