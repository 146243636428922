import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { placeCaretAtEnd } from '../../lib/utils/dom'

class ContentEditable extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onPaste: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onRef: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.string,
    withoutHideOnMobileKeyboard: PropTypes.bool
  }

  static defaultProps = {
    withoutHideOnMobileKeyboard: false,
    onPaste: e => {
      e.preventDefault()
      const text = (e.originalEvent || e).clipboardData.getData('text/plain')
      global.document.execCommand('insertText', false, text)
    }
  }

  componentDidMount() {
    const { onRef } = this.props

    setTimeout(() => { this.editable.focus() }, 200)

    this.setCaretAtEnd()

    if (onRef) {
      onRef(this)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.setCaretAtEnd()
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.lastHtml !== nextProps.children) {
      this.lastHtml = nextProps.children
    }
  }

  emitChange = () => {
    const element = ReactDOM.findDOMNode(this)
    const html = element.textContent

    if (this.props.onChange && html !== this.lastHtml) {
      this.props.onChange({
        target: {
          value: html
        }
      })
    }

    if (!html.replace(' ', '').length) {
      element.innerHTML = ''
    }

    this.lastHtml = html
  }

  setCaretAtEnd = () => {
    placeCaretAtEnd(this.editable)
  }

  onClick = e => {
    e.stopPropagation()
  }

  onBlur = e => {
    this.emitChange()

    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  render() {
    const {
      className, placeholder, onPaste, onKeyDown,
      onKeyPress, withoutHideOnMobileKeyboard, children,
      onFocus
    } = this.props

    const dataWithoutHideOnMobileKeyboard =
      withoutHideOnMobileKeyboard ? { 'data-without-hide-on-mobile-keyboard': true } : {}

    return (
      <div onClick={this.onClick}
        suppressContentEditableWarning={true}
        className={className}
        placeholder={placeholder}
        onInput={this.emitChange}
        onFocus={onFocus}
        onBlur={this.onBlur}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        onKeyPress={onKeyPress}
        contentEditable
        ref={el => {
          this.editable = el
        }}
        {...dataWithoutHideOnMobileKeyboard}
      >
        { children }
      </div>
    )
  }
}

export default ContentEditable
