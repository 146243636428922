import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStateHandlers, defaultProps, compose, withHandlers, withProps, withState } from 'recompose'
import ClickOutHandler from 'react-onclickout'
import { childrenPropType, numberOrString } from '../../../lib/propTypes'
import { onKey, watchKey, isTouchDevice } from '../../../lib/utils/dom'
import { noop } from '../../../lib/utils/common'
import KEY from '../../../lib/constants/key'
import DropdownContent from './DropdownContent'

const enhance = compose(
  // withStateHandlers hoc should be first in composition
  // to make state handlers available through ref.
  // For example in ListingTypeSelector component
  withStateHandlers(
    { isActive: false },
    {
      toggleActive: ({ isActive }) => () => ({ isActive: !isActive }),
      deactivate: () => () => ({ isActive: false }),
      setIsActive: () => value => ({ isActive: value })
    }
  ),
  withState('focusableElements', 'setFocusableElements', []),
  defaultProps({ onActiveChanged: noop, isActive: false }),
  withProps(() => ({
    controllerRef: React.createRef()
  })),
  withHandlers({
    handleClick: ({ toggleActive }) => () => {
      toggleActive()
    },
    setFocusToController: ({ controllerRef }) => () => {
      controllerRef.current.focus()
    },
    onMouseOver: ({ withHover, setIsActive }) => () => {
      if (isTouchDevice) { return }
      if (withHover) { setIsActive(true) }
    },
    onMouseLeave: ({ withHover, setIsActive }) => () => {
      if (isTouchDevice) { return }
      if (withHover) { setIsActive(false) }
    }
  }),
  withHandlers({
    deactivateDropdown: ({ deactivate, setFocusToController }) => () => {
      deactivate()
      setFocusToController()
    }
  }),
  withHandlers({
    onKeyDownController: ({ toggleActive, deactivateDropdown }) => e => {
      onKey(e, KEY.SPACE, toggleActive)
      onKey(e, KEY.RETURN, toggleActive)
      onKey(e, KEY.DOWN, toggleActive)
      onKey(e, KEY.UP, toggleActive)
      onKey(e, KEY.ESC, () => {
        deactivateDropdown()
      })
    },
    onKeyDownContent: ({ focusableElements, deactivateDropdown }) => e => {
      const keyTabCallback = () => {
        global.requestAnimationFrame(() => {
          const { activeElement } = document

          if (!focusableElements.includes(activeElement)) {
            deactivateDropdown()
          }
        })
      }

      watchKey(e, KEY.TAB, keyTabCallback)
      onKey(e, KEY.ESC, () => {
        deactivateDropdown()
      })
      onKey(e, KEY.RETURN, () => {
        deactivateDropdown()
      })
    }
  })
)

const Dropdown = ({
  id,
  isActive,
  deactivate,
  title,
  children,
  className,
  titleClassName,
  contentClassName,
  controllerRef,
  onKeyDownController,
  onKeyDownContent,
  handleClick,
  setFocusableElements,
  onMouseOver,
  onMouseLeave
}) => {
  const dropdownClass = classNames('Dropdown', className)
  const dropdownTitleClass = classNames('Dropdown-title', titleClassName, { isActive })

  return (
    <ClickOutHandler onClickOut={deactivate}>
      <div className={dropdownClass} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onFocus={noop}>
        <div
          id={id}
          role='button'
          aria-expanded={isActive}
          className={dropdownTitleClass}
          tabIndex='0'
          aria-haspopup='true'
          onClick={handleClick}
          onKeyDown={onKeyDownController}
          ref={controllerRef}
        >
          <div className={classNames('Dropdown-titleInner', { isActive })}>
            {title}
          </div>
        </div>

        <DropdownContent
          isActive={isActive}
          setFocusableElements={setFocusableElements}
          onKeyDown={onKeyDownContent}
          ariaLabelledby={id}
          contentClassName={contentClassName}
        >
          {children}
        </DropdownContent>
      </div>
    </ClickOutHandler>
  )
}

Dropdown.propTypes = {
  id: numberOrString.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  children: childrenPropType.isRequired,
  isActive: PropTypes.bool.isRequired,
  toggleActive: PropTypes.func.isRequired,
  deactivate: PropTypes.func.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  onActiveChanged: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  controllerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  onKeyDownController: PropTypes.func.isRequired,
  onKeyDownContent: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  setFocusableElements: PropTypes.func.isRequired,
  withHover: PropTypes.bool
}

export default enhance(Dropdown)
