import React from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'

const Option = ({
  option, optionTemplate, onSelect, isActive, isHighlighted,
  onMouseEnter, onMouseLeave, matchText, activeRef
}) => (
  <div
    className={className('Multiselect-option', { isActive: isHighlighted })}
    onClick={() => { onSelect(option) }}
    onMouseEnter={() => onMouseEnter(option)}
    onMouseLeave={() => onMouseLeave(option)}
    ref={activeRef(option)}
  >
    { optionTemplate({...option, matchText, isActive}) }
  </div>
)

Option.defaultProps = {
  matchText: ''
}

Option.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  matchText: PropTypes.string,
  activeRef: PropTypes.func.isRequired
}

export default Option
