import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { isEmpty } from '../../../../lib/utils/collection'
import { identity } from '../../../../lib/utils/selectors'
import { observer } from 'mobx-react'

const ErrorMessages = ({ messages, contentClassName }) => {
  const errorMessages = Array.isArray(messages) ? messages : [messages].filter(identity)

  useEffect(() => {isEmpty(messages)}, [])

  return (
    <div className='ErrorMessages'>
      <div className={cn('ErrorMessages-content', contentClassName)}>
        {
          errorMessages.map((message, index) => (
            <div key={index} className='ErrorMessages-error'>
              <div className='ErrorMessages-icon'/>
              <div className='ErrorMessages-text' dangerouslySetInnerHTML={{ __html: message }}/>
            </div>
          ))
        }
      </div>
    </div>
  )
}

ErrorMessages.propTypes = {
  messages: PropTypes.array,
  contentClassName: PropTypes.string
}

export default observer(ErrorMessages)
