export const capitalize = str => (
  str.charAt(0).toUpperCase() + str.slice(1)
)

export const escapeRegExp = s => (
  s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
)

export const containsWord = (text, word) => (
  new RegExp(`\\b${escapeRegExp(word.toLowerCase())}\\b`).test(text.toLowerCase())
)

export const wrapMatched = (text, className = '') => (
  text.replace(/<em>(.*?)<\/em>/g, (_, str) => (
    `<span class='${className}'>${str}</span>`
  ))
)

export const sanitizeID = str => String(str).replace(/[\W]/gm, '')
