import { useState, useRef, useEffect } from 'react'

const useReplaceBrokenImage = ({ src, source, fallback }) => {
  const [imgSrc, setImgSrc] = useState(src || fallback.src)
  const [imgSource, setSource] = useState(source || fallback)
  const imgRef = useRef(null)

  const handleFallback = () => {
    setImgSrc(fallback.src)
    setSource({ srcSet: fallback.src, type: fallback.type })
  }

  useEffect(() => {
    const instance = imgRef.current
    instance.addEventListener('error', handleFallback)

    return () => {
      instance.removeEventListener('error', handleFallback)
    }
  }, [])

  return ({
    src: imgSrc,
    source: imgSource,
    imgRef
  })
}

export default useReplaceBrokenImage
