import React from 'react'
import { branch, renderComponent, renderNothing } from 'recompose'
import PropTypes from 'prop-types'
import priceArrows from '../../Arrows/priceArrows'

const PriceChange = ({ change }) => (
  <img
    src={priceArrows[change]}
    className='Card-priceArrow'
    alt={change === 'up' ? 'Price increased' : 'Price reduced'}
  />
)

PriceChange.propTypes = {
  change: PropTypes.string
}

export default branch(
  ({ change }) => change,
  renderComponent(PriceChange),
  renderNothing
)()
