import React from 'react'
import { branch, renderComponent, renderNothing } from 'recompose'
import PropTypes from 'prop-types'
import { OpenHouseWhiteUrl } from '../../../Svg'

const OpenHouse = ({ item }) => (
  <div className='OpenHouseCard exclude-mobile-viewport'>
    <div className='OpenHouseCard-items'>
      <div className='OpenHouseCard-item'>
        <img className='OpenHouseCard-icon' src={OpenHouseWhiteUrl} alt=''/>
        <div className='OpenHouseCard-info OpenHouseCard-info--date'>{item.date}</div>
        <div className='OpenHouseCard-info'>{item.time}</div>
      </div>
    </div>
  </div>
)

OpenHouse.propTypes = {
  item: PropTypes.shape({
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired
  })
}

export default branch(
  ({ item }) => item,
  renderComponent(OpenHouse),
  renderNothing
)()
