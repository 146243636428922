import { withStateHandlers, lifecycle, compose } from 'recompose'
import { makeId } from '../../lib/utils/common'

const withRemountOnResize = compose(
  withStateHandlers({
    onResizeKey: 'onResizeKey'
  }, {
    updateOnResizeKey: () => e => ({
      onResizeKey: e.target.outerWidth + e.target.outerHeight
    }),
    forceOnResizeKey: () => () => ({
      onResizeKey: makeId()
    })
  }),
  lifecycle({
    componentDidMount() {
      global.addEventListener('resize', this.props.updateOnResizeKey)
    },
    componentWillUnmount() {
      global.removeEventListener('resize', this.props.updateOnResizeKey)
    }
  })
)

export default withRemountOnResize
