import React from 'react'
import PropTypes from 'prop-types'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import enUS from 'rc-calendar/lib/locale/en_US'
import moment from 'moment'
import { compose, withHandlers, withState, defaultProps } from 'recompose'
import { noop } from '../../../../../lib/utils/common'
import { DATE_FORMAT, formatDateValue, formatUIDateValue, isCustomDateValue } from '../../../../../lib/utils/date'
import Footer from './Footer'

const currentDate = moment().hour(0).minute(0).second(0)

const toMomentRange = ({ value }) => (
  [value.from, value.to].map(v => moment(v, DATE_FORMAT))
)

const enhance = compose(
  defaultProps({
    onChange: noop
  }),
  withState('valueS', 'setValue', ({ selectedOption }) => (
    isCustomDateValue(selectedOption.value)
      ? toMomentRange(selectedOption)
      : []
  )
  ),
  withHandlers({
    disabledDate: () => date => (
      date.isBefore(currentDate, 'day')
    ),
    clearRange: ({ setValue }) => () => {
      setValue([])
    },
    selectRange: ({ onChange, valueS }) => () => {
      const [from, to] = valueS.map(formatDateValue)
      const [fromDateUI, toDateUI] = valueS.map(formatUIDateValue)

      onChange({
        name: `${fromDateUI} - ${toDateUI}`,
        value: { from, to }
      })
    }
  })
)

const CalendarOptions = ({ disabledDate, goBack, setValue, valueS, clearRange, selectRange }) => (
  <RangeCalendar
    className='DateRangePicker'
    showClear={true}
    format='MM/DD/YYYY'
    locale={enUS}
    disabledDate={disabledDate}
    onSelect={setValue}
    selectedValue={valueS}
    showToday={false}
    showDateInput={false}
    showClear={false}
    mode={['date', 'date']}
    renderFooter={() => (
      <Footer
        clearRange={clearRange}
        goBack={goBack}
        selectRange={selectRange}
      />
    )}
  />
)

CalendarOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  valueS: PropTypes.array.isRequired,
  disabledDate: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  clearRange: PropTypes.func.isRequired,
  selectRange: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
}

export default enhance(CalendarOptions)
