import React from 'react'
import PropTypes from 'prop-types'

const ToggleSwitch = ({ value, onChange, isDisabled }) => (
  <div className='ToggleSwitch'>
    <label>
      <input
        className='ToggleSwitch-item'
        type='checkbox'
        checked={value}
        onChange={onChange}
        disabled={isDisabled}
      />
      <span className='ToggleSwitch-switcher'>ToggleSwitch</span>
    </label>
  </div>
)

ToggleSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool
}

export default ToggleSwitch
