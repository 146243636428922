import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { compose, withHandlers, withState, setPropTypes } from 'recompose'

const enhance = compose(
  setPropTypes({
    selectedOptions: PropTypes.array.isRequired,
    rawOptions: PropTypes.array.isRequired,
    optionIdentity: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired
  }),
  withState('initialSelection', 'setInitialSelection', ({ selectedOptions }) => selectedOptions),
  withHandlers({
    cancelHandler: ({
      initialSelection,
      selectedOptions,
      rawOptions,
      optionIdentity,
      onSelect,
      onRequestClose
    }) => e => {
      e.stopPropagation()

      const options = R.symmetricDifference(selectedOptions, initialSelection)

      rawOptions
        .filter(o => options.includes(optionIdentity(o)))
        .forEach(onSelect)

      onRequestClose()
    },
    applyHandler: ({ cancelHandler }) => e => {
      e.stopPropagation()
      cancelHandler(e)
    }
  })
)

const MapSelectorActions = ({ cancelHandler, applyHandler }) => (
  <div className='AreaMapSelector-actions'>
    <div className='AreaMapSelector-action'>
      <button className='Button Button--secondary'
        onClick={cancelHandler}>
        Cancel
      </button>
    </div>
    <div className='AreaMapSelector-action'>
      <button className='Button Button--primary'
        onClick={applyHandler}>
        Apply
      </button>
    </div>
  </div>
)

MapSelectorActions.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  applyHandler: PropTypes.func.isRequired
}

export default enhance(MapSelectorActions)
