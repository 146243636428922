export const getId = ({ id }) => id

export const getName = ({ name }) => name

export const getFullName = ({ fullName }) => fullName

export const getItem = ({ item }) => item

export const getValue = ({ value }) => value

export const getLabel = ({ label }) => label

export const getChildren = ({ children }) => children

export const identity = v => v
