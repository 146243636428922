import { withStateHandlers, withState, withHandlers, lifecycle, compose } from 'recompose'
import { calculateTextEllipsis, fontFaceObserver, findAncestor } from '../../lib/utils/dom'
import { makeCancelable } from '../../lib/utils/common'


const getInnerImage = (element, parentElementSelector) => {
  if (parentElementSelector) {
    const parentElement = findAncestor(element, parentElementSelector)
    if (parentElement) {
      return parentElement.querySelector('img')
    }
  }

  return null
}

const withTextEllipsis = (parentElementSelector) => compose(
  withStateHandlers(
    { textWithEllipsis: null },
    {
      setEllipsisText: () => el => ({
        textWithEllipsis: calculateTextEllipsis(el)
      })
    }
  ),
  // We need to cancel font load promise on componentWillUnmount
  // to prevent state change for unmounted component.
  //
  // Thats why we save cancelable promise and element in state.
  // Inside componentDidUpdate we check if promise appeared in state,
  // after that we subscribe to font load event, and only after that
  // try to calculate new text size.
  withState('fontLoadPromise', 'setFontLoadPromise', null),
  withHandlers({
    applyEllipsisFor: ({ setFontLoadPromise }) => element => {
      setFontLoadPromise({
        element,
        fontLoad: makeCancelable(fontFaceObserver(element))
      })
    },
    cancelFontLoad: ({ fontLoadPromise }) => () => {
      if (fontLoadPromise) {
        fontLoadPromise.cancel()
      }
    }
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.cancelFontLoad()
    },
    componentDidUpdate(prevProps) {
      const { fontLoadPromise, setFontLoadPromise, setEllipsisText } = this.props

      if (fontLoadPromise && fontLoadPromise !== prevProps.fontLoadPromise) {
        const { fontLoad, element } = fontLoadPromise
        fontLoad.promise.then(() => {
          const innerImage = getInnerImage(element, parentElementSelector)
          if (innerImage) {
            const imageLoading = new Image()
            imageLoading.onload = () => { setEllipsisText(element) }
            imageLoading.src = innerImage.getAttribute('src')
          } else {
            setEllipsisText(element)
          }
        })
        setFontLoadPromise(null)
      }
    }
  })
)

export default withTextEllipsis
