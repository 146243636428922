import React, { useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withProps, withHandlers, compose, defaultProps } from 'recompose'
import moment from 'moment'
import cn from 'classnames'
import enUS from 'rc-calendar/lib/locale/en_US'
import Calendar from 'rc-calendar'
import Picker from 'rc-calendar/lib/Picker'
import { CicleX as Close, Calendar as CalendarSvg } from '../../../Svg'

const enhance = compose(
  defaultProps({
    format: 'YYYY-MM-DD',
    disabled: false,
    className: 'TextField',
    icon: false,
    iconClassName: 'DatePicker-icon',
    timePicker: null
  }),
  withProps(({ value }) => ({
    pickerValue: moment(value || new Date())
  })),
  withProps(({ date, disabledDate, format, timePicker }) => ({
    calendar: (
      <Calendar
        locale={enUS}
        defaultValue={date}
        showDateInput={true}
        format={format}
        disabledDate={disabledDate}
        showToday={false}
        timePicker={timePicker}
        clearIcon={<Fragment/>}
      />
    )
  })),
  withHandlers({
    clearInput: ({ onChange }) => () => {
      onChange(null)
    }
  })
)

const Icon = ({ icon, iconClassName, isActive, isDisabled, closeHandler }) => (
  isActive && !isDisabled
    ? <Close onClick={closeHandler} className='DatePicker-clear' />
    : icon && <CalendarSvg className={cn(iconClassName, { isActive, isDisabled })} />
)
Icon.propTypes = {
  icon: PropTypes.bool,
  iconClassName: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  closeHandler: PropTypes.func
}

const DatePicker = ({
  id,
  value,
  name,
  pickerValue,
  calendar,
  onChange,
  className,
  disabled,
  format,
  errorClass,
  icon,
  iconClassName,
  clearInput
}) => {
  const node = useRef(null)
  const getCalendarContainer = () => node.current

  return (
    <div className='DatePicker' ref={node}>
      <Picker
        value={pickerValue}
        calendar={calendar}
        onChange={onChange}
        getCalendarContainer={getCalendarContainer}
        placement='topLeft'
        align={{
          points: ['tl', 'tr'],
          offset: [0, 100],
          targetOffset: ['100%', '100%'],
          overflow: { adjustX: true, adjustY: true }
        }}
        disabled={disabled}
      >
        {
          () => (
            <input
              readOnly
              id={id}
              value={value ? pickerValue.format(format) : ''}
              placeholder={format}
              className={cn(className, errorClass)}
              name={name}
            />
          )
        }
      </Picker>
      <Icon
        icon={icon}
        isDisabled={disabled}
        isActive={Boolean(value)}
        iconClassName={iconClassName}
        closeHandler={clearInput}
      />
    </div>
  )
}

DatePicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  pickerValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  calendar: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  errorClass: PropTypes.string,
  icon: PropTypes.bool,
  iconClassName: PropTypes.string,
  clearInput: PropTypes.func.isRequired
}

export default enhance(DatePicker)
