import { decamelize } from 'humps'
import { capitalize } from '../lib/utils/string'

export default {
  password: {
    required: 'Please enter a password',
    requiredNew: 'Please enter a new password',
    min: 'Your password must be at least 8 characters long'
  },
  currentPassword: {
    required: 'Please enter current password'
  },
  passwordConfirmation: {
    sameAs: 'Password confirmation does not match',
    required: 'Please enter confirm password'
  },
  email: {
    required: 'Please enter an email',
    valid: 'Email is not valid',
    notAllowedOutEastDomain: 'Email with outeast.com domain is not allowed'
  },
  recipientEmail: {
    required: 'Please enter recipient email',
    valid: 'Email is not valid'
  },
  name: {
    required: 'Please enter name'
  },
  fullName: {
    required: 'Full name is required field',
    maxLength: 'Owner full name must have less than 50 characters'
  },
  phone: {
    required: 'Phone is required field',
    maxLength: 'Owner phone must have less than 50 characters'
  },
  attachments: {
    required: 'Proof ownership is required field'
  },
  selectionField: {
    required: 'Please select value'
  },
  propertyId: {
    required: 'You have to choose one of the options in the drop-down list'
  },
  field: {
    required: name => `${name} is required`,
    url: 'Should be a valid url',
    unacceptableWords: name => (
      `Your ${name} does not meet our Fair Housing Act. Please edit them in order to publish this listing.`
    )
  },
  titleizedField: {
    required: ({ path }) => `${capitalize(decamelize(path, { separator: ' ' }))} is required field`
  }
}
