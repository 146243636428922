import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import withMap from './withMap'
import { childrenPropType } from '../../../../../lib/propTypes'

const ClearSelectionTemplate = ({ children, openMapView, onClearAll }) => (
  <Fragment>
    {children && children}

    <div className='Multiselect-actions'>
      <div className='Multiselect-action'>
        <button onClick={onClearAll} className='Button Button--secondary Button--withoutBorder'>
          Clear
        </button>
      </div>
      <div className='Multiselect-action'>
        <button onClick={openMapView} className='Button Button--primary '>
          View map
        </button>
      </div>
    </div>
  </Fragment>
)

ClearSelectionTemplate.propTypes = {
  setOptionsRef: PropTypes.func.isRequired,
  setMapOpen: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  openMapView: PropTypes.func.isRequired,
  children: childrenPropType
}

export default withMap(ClearSelectionTemplate)
