import React, { Fragment, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../shared_components/Hooks'
import SearchField from './SearchField'
import SearchPanel from './SearchPanel'
import Modal from '../Shared/Modal/Modal'
import KEY from '../../lib/constants/key'

const HeaderSearch = () => {
  const { store: { uiStateStore } } = useStores()

  const activateSearch = () => {
    uiStateStore.toggleSearchActive(true)
  }

  const deactivateSearch = useCallback(() => {
    uiStateStore.toggleSearchActive(false)
  }, [])

  const handleKeydown = useCallback((event) => {
    switch (event.key) {
    case KEY.SPACE:
    case KEY.RETURN:
      activateSearch()
      event.stopPropagation()
      event.preventDefault()
      break
    default:
      break
    }
  }, [])

  const handleClick = useCallback((e) => {
    e.nativeEvent.stopImmediatePropagation()
    activateSearch()
  }, [])

  return (
    <Fragment>
      <SearchField
        onKeyDown={handleKeydown}
        onClick={handleClick}
      />
      <Modal
        isOpen={uiStateStore.isSearchActive}
        onRequestClose={deactivateSearch}
        ariaHideApp={false}
        shouldReturnFocusAfterClose={true}
        overlayClassName='Modal Modal--headerSearch'
        closeButtonTemplate={() => null}
        aria={{
          label: 'Search',
          modal: 'true'
        }}
      >
        <SearchPanel deactivateSearch={deactivateSearch}/>
      </Modal>
    </Fragment>
  )
}

export default observer(HeaderSearch)
