import React from 'react'
import PropTypes from 'prop-types'
import { isDefined } from '../../../../../lib/utils/common'

const RootAreaOptionTemplate = ({ labelText, option: { count } }) => (
  <span className='AreaMapSelector-townName'>
    { labelText }
    { isDefined(count) && <span className='Multiselect-locationCount'>
      {` (${count})`}
    </span> }
  </span>
)

RootAreaOptionTemplate.propTypes = {
  labelText: PropTypes.string.isRequired,
  option: PropTypes.shape({
    count: PropTypes.number
  })
}

export default RootAreaOptionTemplate
