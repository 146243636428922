import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import cn from 'classnames'
import messageStyles from './messageStyles'
import { useRegisterGlobalActions, useStores } from '../../shared_components/Hooks'

const FlashMessages = ({ className }) => {
  const { store: { flashMessageStore } } = useStores()

  const handleCloseButton = message => {
    flashMessageStore.removeFlashMessage(message)
  }

  const addFlashMessage = message => {
    flashMessageStore.addFlashMessage(message)
  }

  const removeFlashMessage = message => {
    flashMessageStore.removeFlashMessage(message)
  }

  useRegisterGlobalActions({
    context: { addFlashMessage, removeFlashMessage },
    key: 'FlashMessages',
    actions: ['addFlashMessage', 'removeFlashMessage']
  })

  return (
    flashMessageStore.messages.map((message, index) => (
      <div className={cn('InfoBar', className)} key={index}>
        <div className={cn('InfoBar-content InfoBar-content--defaultHeight', messageStyles[message.type].messageClass)}>
          <div className={cn('InfoBar-text InfoBar-text--withIcon', messageStyles[message.type].iconClass)}>
            <span className='Text Text--a1 Text--fontWeightMedium Text--lineHeight16px'>
              {message.text}
            </span>
          </div>

          {(message.linkUrl && message.linkText) &&
            <div className='InfoBar-link InfoBar-link--pullRight'>
              <span className='InfoBar-linkPosition'>
                <a className='Button Button--link' href={message.linkUrl} data-turbolinks='false'>{message.linkText}</a>
              </span>
            </div>
          }
          <div className='InfoBar-closeButton' onClick={handleCloseButton.bind(null, message)}/>
        </div>
      </div>
    ))
  )
}

FlashMessages.propTypes = {
  className: PropTypes.string
}

export default observer(FlashMessages)
