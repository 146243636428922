/* eslint no-unused-expressions: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import { compose, withStateHandlers, withHandlers, withProps, defaultProps, lifecycle } from 'recompose'
import cn from 'classnames'
import { Location } from '../../components/Svg'
import Autocomplete from '../../components/Shared/Form/Autocomplete'

const ZENDESK_REQUEST_URL = 'https://support.outeast.com/hc/en-us/requests/new?ticket_form_id=360000199211'

const resultString = string => {
  const resultHtmlString = string.replace(/<em>(.*?)<\/em>/g, (_, text) => (
    `<span class='AddressAutoCompleter-searchText'>${text}</span>`
  ))

  return(
    <span dangerouslySetInnerHTML={{__html: resultHtmlString}} />
  )
}

const enhance = compose(
  defaultProps({
    getItemValue: item => `${item.address}, ${item.townName}`
  }),
  withProps({
    addressFieldRef: React.createRef()
  }),
  withStateHandlers(
    { isReadyTyping: false },
    {
      activeReadyTyping: () => () => ({ isReadyTyping: true }),
      deActiveReadyTyping: () => () => ({ isReadyTyping: false })
    }
  ),
  withHandlers({
    onEnterAddress: ({ onChange, onChangeAvailableAddresses }) => value => {
      onChangeAvailableAddresses(value)
      onChange(value)
    }
  }),
  lifecycle({
    componentDidMount() {
      setTimeout(() => {
        const addressInput = this.props.addressFieldRef.current.querySelector('input')

        if (addressInput && !this.props.value) {
          addressInput.focus()
        }
      }, 0)
    }
  })
)

const AddressAutoCompleter = (
  { activeReadyTyping,
    deActiveReadyTyping,
    isReadyTyping,
    value,
    availableAddresses,
    onEnterAddress,
    onChangeSelectedAddressItem,
    getItemValue,
    addressFieldRef
  }) => (
  <div className='AddressAutoCompleter'>
    <Autocomplete
      inputProps={{ onFocus: activeReadyTyping, onBlur: deActiveReadyTyping }}
      value={value}
      items={availableAddresses}
      wrapperStyle={{ position: 'relative', height: 50}}
      getItemValue={getItemValue}
      onSelectedItem={onChangeSelectedAddressItem}
      onChange={onEnterAddress}
      renderInput={p => (
        <div className='AddressAutoCompleter-field'>
          <div className={cn('AddressAutoCompleter-locationIcon', { isActive: isReadyTyping })}>
            <Location />
          </div>
          <div className='AddressAutoCompleter-searchField' ref={addressFieldRef}>
            <input
              {...p}
              className='AddressAutoCompleter-text'
              placeholder='Start typing address or SCTM#…'
            />
          </div>
        </div>
      )}
      renderMenu={(children, currentValue) => (
        <div
          className={cn('AddressAutoCompleter-options',
            { isNotEmpty: currentValue.length > 1 || React.Children.count(children) > 0})}
        >
          {currentValue.length > 1 && React.Children.count(children) === 0 ?
            <div className='AddressAutoCompleter-noResults'>
              <div className='AddressAutoCompleter-message'>Don’t see the address you’re looking for?</div>
              <div className='AddressAutoCompleter-requestLink'>
                <a href={ZENDESK_REQUEST_URL} className='Link Link--inline Link--weightBook' target='_blank'>
                  Submit a Request
                </a>
              </div>
            </div>
            :
            children
          }
        </div>
      )}
      renderItem={(item, isHighlighted) => (
        <div className={cn('AddressAutoCompleter-option', { isHighlighted })} key={item.id}>
          <div className={'AddressAutoCompleter-optionTitle'}>
            {resultString(item.fullAddress)}
          </div>
          <div className={'AddressAutoCompleter-optionSubTitle'}>
            {resultString(item.formattedTaxMapId)}
          </div>
        </div>
      )}
    />
  </div>
)

AddressAutoCompleter.propTypes = {
  value: PropTypes.string,
  availableAddresses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    townName: PropTypes.string.isRequired,
    fullAddress: PropTypes.string.isRequired
  })).isRequired,
  activeReadyTyping: PropTypes.func.isRequired,
  deActiveReadyTyping: PropTypes.func.isRequired,
  isReadyTyping: PropTypes.bool.isRequired,
  onEnterAddress: PropTypes.func.isRequired,
  onChangeAvailableAddresses: PropTypes.func.isRequired,
  onChangeSelectedAddressItem: PropTypes.func.isRequired,
  getItemValue: PropTypes.func,
  addressFieldRef: PropTypes.object
}

export default enhance(AddressAutoCompleter)
