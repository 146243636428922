import PropTypes from 'prop-types'
import { withHandlers, lifecycle, setPropTypes, compose } from 'recompose'
import { scrollIntoView } from '../../lib/utils/dom'
import { isTouchScreen } from '../../lib/utils/viewport'

const withScrollToActiveOption = compose(
  setPropTypes({
    highlightedOption: PropTypes.any
  }),
  withHandlers(() => {
    let activeOption
    let options

    return {
      setActiveOptionRef: ({ highlightedOption }) => o => el => {
        if (highlightedOption === o) { activeOption = el }
      },
      setOptionsRef: () => el => {
        // Reusing options value allows options content wrappers to redefine inner options behavior.
        // This function may be called a couple of times, first time for content wrapper and then for options component.
        // So only first call ref must be used
        options = options || el
      },
      scrollIntoViewOnActive: () => () => {
        if (activeOption && options) {
          scrollIntoView(activeOption, options)
        }
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      if (!isTouchScreen()) {
        this.props.scrollIntoViewOnActive()
      }
    },
    componentDidUpdate() {
      if (!isTouchScreen()) {
        this.props.scrollIntoViewOnActive()
      }
    }
  })
)

export default withScrollToActiveOption
