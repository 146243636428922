import React from 'react'
import { Provider } from 'mobx-react'
import Flash from './FlashMessages'
import Toast from './ToastFlashMessages'
import FlashMessagesStore from './FlashMessagesStore'
import { withErrorBoundary } from '../Shared/ErrorBoundary'

const flashMessageStore = new FlashMessagesStore()
const toastFlashMessageStore = new FlashMessagesStore()

const StateFulFlashMessages = props => (
  <Provider store={{flashMessageStore}}>
    <Flash {...props} />
  </Provider>
)

const StateFulToastFlashMessages = () => (
  <Toast store={toastFlashMessageStore} />
)

export const FlashMessages = withErrorBoundary(StateFulFlashMessages)
export const ToastFlashMessages = withErrorBoundary(StateFulToastFlashMessages)
