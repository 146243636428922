import { action, observable, reaction, computed, makeObservable } from 'mobx'
import { lens } from 'lorgnette'
import BaseForm from '../../../lib/stores/BaseForm'
import { fromServerToClient } from '../../../lib/utils/collection'
import { debounce } from '../../../lib/utils/common'

class PropertyAddressFormStore extends BaseForm {
  @observable availableAddresses = []
  @observable selectedAddressItem = {}
  @observable subareas = []

  constructor({ transport, location }) {
    super({ transport, location })

    makeObservable(this)

    this.transport = transport
    this.location = location

    this.setObservers()
  }

  setObservers = () => {
    reaction(() => this.value.address, value => {
      if (value !== this.displayAddress(this.selectedAddressItem)) {
        this.resetSelectedAddressItem()
      }
    })
  }

  setInitialData = ({ options: { subareas } }) => {
    this.subareas = subareas
  }

  @action('[PropertyAddressFormStore] set selectedAddressItem')
  setSelectedAddressItem = value => {
    this.selectedAddressItem = value
    this.value = {
      ...this.value,
      propertyId: value.id
    }
  }

  @action('[PropertyAddressFormStore] reset selectedAddressItem')
  resetSelectedAddressItem = () => {
    this.selectedAddressItem = {}
    this.value.subarea = null
    this.value.propertyId = null
  }

  @action('[PropertyAddressFormStore] reset value')
  resetValue = () => {
    this.value = {}
    this.availableAddresses = []
    this.selectedAddressItem = {}
  }

  @computed get subareaOptions() {
    return lens
      .firstOf(v => v.townName === this.selectedAddressItem.townName)
      .prop('subareas')
      .get(this.subareas)
      .getOr([])
  }

  displayAddress = item => `${item.address}, ${item.townName}`

  fetchAvailableAddresses = value => {
    if (value.length > 1) {
      let request

      request = this.transport.Properties.getByText(value)

      return request.then(action('[PropertyAddressFormStore] fetchAvailableAddresses', response => {
        this.availableAddresses = fromServerToClient(response)
      }))
    }
  }

  getAvailableAddresses = debounce(this.fetchAvailableAddresses, 300)
}

export default PropertyAddressFormStore
