import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { compose, withHandlers } from 'recompose'
import { onKey } from '../../../lib/utils/dom'
import KEY from '../../../lib/constants/key'
import { iterator } from '../../../lib/utils/collection'

const enhance = compose(
  withHandlers({
    onClick: ({ tabAction }) => tab => () => {
      tabAction(tab)
      global.scrollTo(0, 0)
    },
    onKeyDown: ({ tabAction, tabs }) => index => e => {
      e.nativeEvent.stopImmediatePropagation()

      const activateTab = tab => {
        tabAction(tab)
        tab.ref.current.focus()
      }

      const tabsIterator = iterator(tabs, index)

      onKey(e, KEY.RIGHT, () => {
        activateTab(tabsIterator.getNext())
      })
      onKey(e, KEY.LEFT, () => {
        activateTab(tabsIterator.getPrevious())
      })
    }
  })
)

const Tabs = ({
  tabs,
  onClick,
  onKeyDown,
  tabsWrapperClassName,
  tabClassName,
  tabClassNameModifier,
  ariaLabel,
  withTabIndex = true,
  withAutoFocus
}) => (
  <div className={tabsWrapperClassName} aria-label={ariaLabel} role='tablist'>
    {tabs.map((item, index) => {
      return tabs.length > 1 ? (
        <button
          type='button'
          key={index}
          id={item.tabId}
          ref={item.ref}
          tabIndex={item.isActive ? 0 : -1}
          className={cn(tabClassName, tabClassNameModifier, {
            isActive: item.isActive,
            isError: item.hasError
          })}
          role='tab'
          aria-selected={item.isActive}
          aria-controls={item.panelId}
          onClick={onClick(item)}
          onKeyDown={onKeyDown(index)}
          autoFocus={withAutoFocus ? item.isActive : null}
        >
          <span className={`${tabClassName}Content`} tabIndex={withTabIndex ? '-1' : null}>
            {item.label}
          </span>
        </button>
      ) : (
        <div className={tabClassName} key={index}>
          <span className={`${tabClassName}Content`}>
            {item.label}
          </span>
        </div>
      )}
    )}
  </div>
)


Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  tabsWrapperClassName: PropTypes.string.isRequired,
  tabClassName: PropTypes.string.isRequired,
  tabClassNameModifier: PropTypes.string,
  withTabIndex: PropTypes.bool,
  withAutoFocus: PropTypes.bool
}

export default enhance(Tabs)
