import React from 'react'
import PropTypes from 'prop-types'
import SearchResultsCategory from './SearchResultsCategory'
import { searchResultPropType } from '../../../lib/propTypes'

const NoResultsMessage = () => <p className='SearchResults-message'>Your search returned no results.</p>

const SearchResults = ({ isResultsPresent, results, activeID, setActiveOptionRef }) => (
  <div className='SearchResults'>
    <div id='search-results' className='SearchResults-categories' role='listbox'>
      {!isResultsPresent && <NoResultsMessage/>}

      <SearchResultsCategory
        name='towns'
        results={results.towns}
        activeID={activeID}
        setActiveOptionRef={setActiveOptionRef}
      />
      <SearchResultsCategory
        name='sales'
        results={results.sales}
        activeID={activeID}
        setActiveOptionRef={setActiveOptionRef}
      />
      <SearchResultsCategory
        name='rentals'
        results={results.rentals}
        activeID={activeID}
        setActiveOptionRef={setActiveOptionRef}
      />
      <SearchResultsCategory
        name='areas'
        results={results.areas}
        activeID={activeID}
        setActiveOptionRef={setActiveOptionRef}
      />
      <SearchResultsCategory
        name='internet numbers'
        results={results.internetNumbers}
        activeID={activeID}
        setActiveOptionRef={setActiveOptionRef}
      />
    </div>
  </div>
)

SearchResults.propTypes = {
  isResultsPresent: PropTypes.bool.isRequired,
  results: PropTypes.shape({
    sales: searchResultPropType,
    rentals: searchResultPropType,
    towns: searchResultPropType,
    areas: searchResultPropType,
    internetNumbers: searchResultPropType
  }).isRequired,
  activeID: PropTypes.string,
  setActiveOptionRef: PropTypes.func.isRequired
}

export default SearchResults
