import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { pillEllipsis } from '../../../../../lib/utils/dom'

class PillEllipsis extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    height: PropTypes.number.isRequired
  }

  state = {
    childrenStyles: (new Array(this.props.children.length)).fill().map(() => ({
      display: 'block'
    })),
    hiddenCount: 0
  }

  componentDidMount() {
    this.updateEllipsis()
  }

  componentDidUpdate({ children }) {
    if (children.length !== this.props.children.length) {
      this.updateEllipsis()
    }
  }

  updateEllipsis = () => {
    const { children, height } = this.props

    pillEllipsis(this.container, height, pillsToHide => {
      const pillsToShow = children.length - pillsToHide

      const childStylesToShow = (new Array(pillsToShow)).fill().map(() => ({ display: 'block' }))
      const childStylesToHide = (new Array(pillsToHide)).fill().map(() => ({ display: 'none' }))

      if (this.container) {
        this.setState(() => ({
          childrenStyles: [...childStylesToShow, ...childStylesToHide],
          hiddenCount: pillsToHide
        }))
      }
    })
  }

  render() {
    const { children } = this.props
    const { hiddenCount } = this.state

    return (
      <div className='PillEllipsis'>
        <div className='PillEllipsis-content' ref={el => { this.container = el }}>
          {
            React.Children.map(children, (c, i) => (
              <div style={this.state.childrenStyles[i]}>
                { c }
              </div>
            ))
          }
        </div>


        {
          hiddenCount !== 0 &&
          <div className='PillEllipsis-hiddenCount'>
            +{ hiddenCount }
          </div>
        }
      </div>
    )
  }
}


export default PillEllipsis
