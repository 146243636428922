import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from '../../../../lib/propTypes'

const OptionsWrapper = ({quickFilters, children}) => (
  <Fragment>
    {quickFilters}
    {children}
  </Fragment>
)

OptionsWrapper.propTypes = {
  children: childrenPropType,
  quickFilters: PropTypes.node
}

export default OptionsWrapper
