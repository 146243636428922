/* eslint camelcase: 0 */

import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'

export const searchResultPropType = PropTypes.shape({
  total: PropTypes.number.isRequired,
  suggestions: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({
    string: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })).isRequired
})

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.node
  )
])

export const imageUrlPropType = PropTypes.shape({
  webp: PropTypes.string,
  jpeg: PropTypes.string
}).isRequired

export const listingPropType = PropTypes.shape({
  townName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  shortPrice: PropTypes.string.isRequired,
  beds: PropTypes.number,
  baths: PropTypes.number,
  halfBaths: PropTypes.number,
  acreage: PropTypes.number,
  featuredImageUrl: imageUrlPropType,
  keyAmenityLabel: PropTypes.string,
  sqft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  priceChange: PropTypes.string,
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
  saved: PropTypes.bool,
  statusInfo: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string
  }),
  land: PropTypes.bool,
  closestOpenHouse: PropTypes.object,
  displayAddress: PropTypes.bool,
  listingBy: PropTypes.string,
  officeAddress: PropTypes.string
})

export const pageType = PropTypes.shape({
  url: PropTypes.string,
  title: PropTypes.string
})

export const numberOrString = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
])

export const refProp = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any })
])
