/* eslint no-unused-expressions: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import CloseButtonTemplate from './CloseButtonTemplate'
import { defaultProps, compose, withState, withHandlers, lifecycle, withProps } from 'recompose'
import * as ariaAppHider from 'react-modal/lib/helpers/ariaAppHider'
import { withKeyBindings } from '../../Hocs'
import { childrenPropType } from '../../../lib/propTypes'

const nodesToHide = ['body > header', 'main', 'body > footer'].join(' ,')

const WithKeyBindings = ({ children }) => children
const EnhancedWithKeyBindings = withKeyBindings(({ mainAction }) => ({
  Enter: e => { mainAction && mainAction(e) }
}))(WithKeyBindings)

const enhance = compose(
  withState('nodes', 'setNodes', []),
  withProps({
    closeButtonRef: React.createRef()
  }),
  defaultProps({
    closeButtonTemplate: CloseButtonTemplate
  }),
  withHandlers({
    onAfterOpen: ({ nodes, closeButtonRef }) => () => {
      nodes.forEach(ariaAppHider.hide)

      if(closeButtonRef.current) {
        global.requestAnimationFrame(() => {
          closeButtonRef.current.focus()
        })
      }
    },
    onAfterClose: ({ nodes }) => () => {
      nodes.forEach(ariaAppHider.show)
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.setNodes([...document.querySelectorAll(nodesToHide)])
    }
  })
)

const Modal = props => (
  <ReactModal
    overlayClassName='Modal'
    className='Modal-content'
    onAfterOpen={props.onAfterOpen}
    onAfterClose={props.onAfterClose}
    {...props}
  >
    { props.closeButtonTemplate({ onClick: props.onRequestClose, closeButtonRef: props.closeButtonRef }) }
    <EnhancedWithKeyBindings {...props}>
      { props.children }
    </EnhancedWithKeyBindings>
  </ReactModal>
)

Modal.propTypes = {
  children: childrenPropType.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  closeButtonTemplate: PropTypes.func,
  onAfterOpen: PropTypes.func.isRequired,
  onAfterClose: PropTypes.func.isRequired,
  closeButtonRef: PropTypes.object.isRequired
}

export default enhance(Modal)
