import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent, renderNothing } from 'recompose'
import cn from 'classnames'
import Option from './Option'
import { withScrollToActiveOption } from '../../../Hocs'
import OptionScroller from '../Shared/OptionScroller'

const ActiveOptions = props => {
  const {
    selectedOptionsGroup, onSelect, onMouseEnter, onMouseLeave,
    optionTemplate, optionIdentity, highlightedOption,
    selectedOptions, options, matchText, setActiveOptionRef,
    setOptionsRef, rawOptions, selectedOptionSort, wrapperOptionsComponent, optionLabel
  } = props

  const primarySelectedOptions = rawOptions.filter(o => (
    selectedOptionsGroup.includes(optionIdentity(o))
  )).sort(selectedOptionSort)
  const unselectedOptions = options.filter(o => (
    !selectedOptionsGroup.includes(optionIdentity(o))
  ))
  const WrapperOptionsComponent = wrapperOptionsComponent
  const isSearchActive = !!matchText

  const primarySelectedOptionNodes = primarySelectedOptions.map(o => (
    <Option
      key={optionIdentity(o)}
      option={o}
      optionTemplate={optionTemplate}
      optionLabel={optionLabel}
      optionIdentity={optionIdentity}
      onSelect={onSelect}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isHighlighted={highlightedOption === o}
      isActive={selectedOptions.includes(optionIdentity(o))}
      isSelectedGroup={true}
      activeRef={setActiveOptionRef}
    />
  ))

  const unselectedOptionNodes = unselectedOptions.map(o => (
    <Option
      key={optionIdentity(o)}
      option={o}
      optionTemplate={optionTemplate}
      optionLabel={optionLabel}
      optionIdentity={optionIdentity}
      onSelect={onSelect}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isHighlighted={highlightedOption === o}
      isActive={selectedOptions.includes(optionIdentity(o))}
      isChild={!!o.parent}
      matchText={matchText}
      activeRef={setActiveOptionRef}
    />
  ))

  return (
    <div
      className='Multiselect-options'
      // Fix for Safari(blur event is called for input when clicked on div scrollbar)
      onMouseDown={e => { e.preventDefault() }}
    >
      <WrapperOptionsComponent {...props}>
        <OptionScroller setOptionsRef={setOptionsRef}>
          { !isSearchActive && primarySelectedOptionNodes }
          <div className={
            cn('Multiselect-borderOptionsGroup', {
              isPresent: (primarySelectedOptions.length > 0 && !isSearchActive)
            })}
          />
          { unselectedOptionNodes }
          <div className={
            cn('Multiselect-borderOptionsGroup', {
              isPresent: (primarySelectedOptions.length > 0 && unselectedOptions.length > 0 && isSearchActive)
            })}
          />
          { isSearchActive && primarySelectedOptionNodes }
        </OptionScroller>
      </WrapperOptionsComponent>
    </div>
  )
}

ActiveOptions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  rawOptions: PropTypes.array.isRequired,
  selectedOptionsGroup: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  highlightedOption: PropTypes.any,
  matchText: PropTypes.string,
  setActiveOptionRef: PropTypes.func.isRequired,
  setOptionsRef: PropTypes.func.isRequired,
  selectedOptionSort: PropTypes.func.isRequired,
  wrapperOptionsComponent: PropTypes.func.isRequired
}

ActiveOptions.defaultProps = {
  wrapperOptionsComponent: (({children}) => children)
}

const Options = branch(
  ({ isActive }) => isActive,
  renderComponent(withScrollToActiveOption(ActiveOptions)),
  renderNothing
)

export default Options()
