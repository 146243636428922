import React from 'react'
import PropTypes from 'prop-types'
import { noop } from '../../../../../lib/utils/common'
import { isTouchScreen } from '../../../../../lib/utils/viewport'
import cn from 'classnames'

const Option = ({
  id,
  onSelect,
  option,
  isChild,
  isActive,
  isHighlighted,
  optionTemplate,
  optionLabel,
  onMouseEnter,
  onMouseLeave,
  activeRef
}) => {
  const handleOnClick = () => {
    onSelect(option)
  }

  return (
    <div
      id={id}
      className={cn('AreaMapSelector-optionContent', { isHighlighted, isChild })}
      onMouseEnter={isTouchScreen() ? noop : () => onMouseEnter(option)}
      onMouseLeave={isTouchScreen() ? noop : () => onMouseLeave(option)}
      ref={activeRef(option)}
      role='option'
      aria-selected={isActive}
      tabIndex='-1'
      onKeyDown={noop}
      onClick={handleOnClick}
    >
      <div className={cn('MapOption', {isActive})}>
        <span className='MapOption-icon' role='presentation'/>
        {optionTemplate({ option, isActive, labelText: optionLabel(option) })}
      </div>
    </div>
  )
}

Option.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  option: PropTypes.any.isRequired,
  isChild: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  activeRef: PropTypes.func.isRequired
}

export default Option
