import React from 'react'
import { selectedOptionFilter, filterWithSearchText } from './utils'
import SearchableTreeCheckySelect from '../../SearchableTreeCheckySelect'
import TownWrapper from '../TownWrapper'
import OptionsTemplate from './OptionsTemplate'

const TreeTownSelect = props => (
  <SearchableTreeCheckySelect
    wrapperOptionsComponent={OptionsTemplate}
    wrapperComponent={TownWrapper}
    selectedOptionSort={(a, b) => (a.name > b.name)}
    selectedOptionFilter={selectedOptionFilter}
    filterWithSearchText={filterWithSearchText}
    {...props}
  />
)

export default TreeTownSelect
