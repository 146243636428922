import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent } from 'recompose'

const Placeholder = ({ placeholder }) => (
  <div className='CheckySelect-placeholder'>
    { placeholder }
  </div>
)

const SelectedOptionsText = ({ selectedOptionsTemplate, selectedOptions }) => (
  <div className='CheckySelect-selectedOptionText'>
    { selectedOptionsTemplate(selectedOptions) }
  </div>
)

const SelectedOptions = branch(
  ({ selectedOptions, placeholder }) => selectedOptions.length > 0 || !placeholder,
  renderComponent(SelectedOptionsText),
  renderComponent(Placeholder)
)

Placeholder.propTypes = {
  placeholder: PropTypes.string
}

SelectedOptionsText.propTypes = {
  selectedOptionsTemplate: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array
}

export default SelectedOptions()
