import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent, renderNothing } from 'recompose'

const HiddenInputWithValue = ({ name, value }) => (
  <input name={name} type='hidden' value={value} />
)

const HiddenInput = branch(
  ({value}) => value,
  renderComponent(HiddenInputWithValue),
  renderNothing
)

HiddenInputWithValue.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any
}

export default HiddenInput()
