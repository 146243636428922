import React from 'react'
import { compose, withHandlers, withProps, setPropTypes, withState, defaultProps } from 'recompose'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import ClickOutHandler from 'react-onclickout'
import cn from 'classnames'
import { debounce } from '../../../../lib/utils/common'
import { isPresent, isEmpty } from '../../../../lib/utils/collection'
import { EmailTokenField } from '../../../../components/Shared/Form'
import { getContactTitle } from './utils'

const enhance = compose(
  setPropTypes({
    setContacts: PropTypes.func.isRequired,
    getContacts: PropTypes.func.isRequired,
    contacts: MobxPropTypes.arrayOrObservableArray
  }),
  defaultProps({
    isOptionDisabled: v => isEmpty(v)
  }),
  withState('isActive', 'setActive', false),
  withHandlers(({ getContacts }) => {
    const debouncedGetUserContacts = debounce(getContacts, 400)

    return {
      onSearchTextChange: ({ shareTo }) => value => {
        debouncedGetUserContacts(value, shareTo)
      },
      setNewToken: ({ setField, value, setContacts, name, isOptionDisabled }) => email => {
        if (isOptionDisabled(email)) {
          return
        }

        setField(name, [...value, email])
        setContacts([])
      },
      onClickOut: ({ setActive }) => () => {
        setActive(false)
      },
      searchFieldFocus: ({ setActive }) => () => {
        setActive(true)
      }
    }
  }),
  withProps(({ contacts, value }) => ({
    contacts: R.differenceWith((obj, v) => obj.email === v, contacts, value)
  }))
)

const UserContactsField = ({
  contacts,
  onSearchTextChange,
  setNewToken,
  searchFieldFocus,
  onClickOut,
  isActive,
  isOptionDisabled,
  ...props
}) => (
  <ClickOutHandler onClickOut={onClickOut}>
    <EmailTokenField
      {...props}
      breakKeys={[',', 'Enter']}
      onTextChange={onSearchTextChange}
      setParentFocus={searchFieldFocus}
    />
    {isPresent(contacts) && isActive && (
      <div className='UserContactsField'>
        <div className='UserContactsField-container'>
          {contacts.map((contact, i) => (
            <div
              className={cn('UserContactsField-item', { isDisabled: isOptionDisabled(contact.email) })}
              key={`${contact.email}-${i}`}
              onClick={() => setNewToken(contact.email)}
            >
              {getContactTitle(contact) && (
                <div className='UserContactsField-agentName'>{getContactTitle(contact)}</div>
              )}
              <div className='UserContactsField-agentEmail'>{contact.email}</div>
            </div>
          ))}
        </div>
      </div>
    )}
  </ClickOutHandler>
)

UserContactsField.propTypes = {
  onSearchTextChange: PropTypes.func.isRequired,
  setNewToken: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
  searchFieldFocus: PropTypes.func.isRequired,
  onClickOut: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isOptionDisabled: PropTypes.func
}

export default enhance(UserContactsField)
