import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import ClickOutHandler from 'react-onclickout'
import classNames from 'classnames'
import * as R from 'ramda'
import { onKey, watchKey } from '../../../../lib/utils/dom'
import { isTouchScreen } from '../../../../lib/utils/viewport'
import { noop } from '../../../../lib/utils/common'
import { getId, getName, identity } from '../../../../lib/utils/selectors'
import KEY from '../../../../lib/constants/key'
import {
  addParentId,
  deepFindChildrenId,
  findParentsId,
  hasChildren,
  hasParent
} from '../../../../lib/dataMappings/treeUtils'
import Options from './Options'
import TextField from './TextField'

const sortByMatch = ({ searchText }, { filterIdentity }) => (a, b) => {
  const optionA = filterIdentity(a).toLocaleLowerCase().match(searchText.toLowerCase()).index
  const optionB = filterIdentity(b).toLocaleLowerCase().match(searchText.toLowerCase()).index

  return optionA - optionB
}

const SearchableTreeCheckySelect = props => {
  const {
    id,
    optionIdentity,
    optionTemplate,
    optionLabel,
    selectedOptionsGroup,
    selectedOptionTemplate,
    wrapperComponent,
    wrapperOptionsComponent,
    className,
    placeholder,
    options,
    selectedOptionFilter,
    selectedOptionsWithParents,
    applyResultsHandler,
    wrapperOptionsComponentClassName,
    countResults,
    selectedOptionSort,
    optionsById,
    onChange,
    onSearchTextChange,
    setSelectedOptionsGroup,
    filterWithSearchText
  } = props

  const [value, setValue] = useState(props.value)
  const [isActive, setIsActive] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [searchText, setSearchText] = useState('')
  const [focusableElements, setFocusableElements] = useState([])
  const [keyboardNavFlag, setKeyboardNavFlag] = useState(false)

  const isMountedRef = useRef(false)
  const controllerRef = useRef(null)
  const WrapperComponent = wrapperComponent

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  const getAvailableOptions = () => {
    let result = options

    if (searchText !== '') {
      result = filterWithSearchText({ searchText }, props)(result)
    }
    return result
  }

  const availableOptions = getAvailableOptions()

  const setActiveOption = (option, listType) => {
    const isSearchActive = !!searchText
    let isInSelectedGroup = true
    let index

    if (listType === 'selected') {
      index = selectedOptionsWithParents.findIndex(o => R.equals(o, option))
    } else {
      index = availableOptions.findIndex(o => o === option)
      isInSelectedGroup = false
    }

    if (isInSelectedGroup && isSearchActive) {
      index = index + availableOptions.length
    } else if (!isInSelectedGroup && !isSearchActive) {
      index = index + selectedOptionsWithParents.length
    }

    if (!keyboardNavFlag) {
      setSelectedIndex(index)
    }
  }

  const unSetActiveOption = () => {
    if (!keyboardNavFlag) {
      setSelectedIndex(-1)
    }
  }

  const handleMouseMove = () => {
    setKeyboardNavFlag(false)
  }

  const nextActiveOption = () => {
    const max = availableOptions.length + selectedOptionsWithParents.length - 1

    setSelectedIndex(Math.min(selectedIndex + 1, max))
  }

  const lastActiveOption = () => {
    const max = availableOptions.length + selectedOptionsWithParents.length - 1

    setSelectedIndex(Math.max(selectedIndex - 1, max))
  }

  const prevActiveOption = () => {
    const min = 0

    setSelectedIndex(Math.max(selectedIndex - 1, min))
  }

  const getOptionInAvailable = currentIndex => {
    if (currentIndex < availableOptions.length) {
      return availableOptions[currentIndex]
    }

    const indexOffset = availableOptions.length
    const index = currentIndex - indexOffset

    return selectedOptionsWithParents[index]
  }

  const getSelectedOptions = () => {
    const fromRegularOptions = options.filter(o => value.includes(optionIdentity(o)))

    return (
      R.uniqBy(optionIdentity, fromRegularOptions)
        .sort(selectedOptionSort)
    )
  }

  const getOptionInSelected = currentIndex => {
    if (currentIndex < selectedOptionsWithParents.length) {
      return selectedOptionsWithParents[currentIndex]
    }

    const indexOffset = selectedOptionsWithParents.length
    const index = currentIndex - indexOffset

    return availableOptions[index]
  }

  const highlightedOptionInAvailable = () => {
    return getOptionInAvailable(selectedIndex)
  }

  const highlightedOptionInSelected = () => {
    return getOptionInSelected(selectedIndex)
  }

  const getHighlightedOption = () => {
    const isSearchActive = !!searchText

    return isSearchActive ? highlightedOptionInAvailable() : highlightedOptionInSelected()
  }

  const highlightedOption = getHighlightedOption()

  const onActivateOptions = () => {
    setIsActive(true)
    setSelectedOptionsGroup(value)
  }

  const onDeactivateOptions = e => {
    if (!e.target || !isMountedRef.current) {
      return
    }

    // TODO: Investigate how to avoid a fragile dependency on the class name
    const isCalledFromMapViewModal = () => {
      if (e.composedPath) {
        return e.composedPath().some(n => n.classList && n.classList.contains('AreaMapSelector'))
      }
      return false
    }

    if (!isCalledFromMapViewModal()) {
      setIsActive(false)
      setSelectedIndex(-1)
      setSearchText('')
      setFocusableElements([])
    }
  }

  const style = { minHeight: props.height }
  const selectedOptions = getSelectedOptions()
  const hasSelected = selectedOptions.length > 0
  const hasOptions = availableOptions.length > 0 || selectedOptionsGroup.length > 0


  const onKeyDown = e => {
    setKeyboardNavFlag(true)

    onKey(e, KEY.DOWN, () => {
      if (isActive) {
        nextActiveOption()
      } else {
        onActivateOptions()
        nextActiveOption()
      }
    })
    onKey(e, KEY.UP, () => {
      if (isActive) {
        prevActiveOption()
      } else {
        onActivateOptions()
        lastActiveOption()
      }
    })
    onKey(e, KEY.ESC, onDeactivateOptions)
  }

  const onKeydownContent = e => {
    e.persist()

    const keyTabCallback = () => {
      global.requestAnimationFrame(() => {
        const { activeElement } = document

        if (!focusableElements.includes(activeElement)) {
          onDeactivateOptions(e)
          controllerRef.current.focus()
        }
      })
    }

    watchKey(e, KEY.TAB, keyTabCallback)
    onKey(e, KEY.ESC, () => {
      onDeactivateOptions(e)
      controllerRef.current.focus()
    })
  }

  const activate = option => {
    let updatedValue = [...value]
    const optionId = optionIdentity(option)
    const activateParent = addParentId(options)

    updatedValue.push(optionId)

    if (hasChildren(option)) {
      const childrenIds = deepFindChildrenId(optionsById[optionId].children)(options)
      updatedValue = R.uniq([...updatedValue, ...childrenIds])
    }

    if (hasParent(option)) {
      updatedValue = activateParent(option, updatedValue)
    }

    return updatedValue
  }

  const deactivate = option => {
    let updatedValue = [...value]
    const optionId = optionIdentity(option)

    const removeFromValue = (IDs = []) => updatedValue.filter(id => !IDs.includes(id))

    updatedValue = removeFromValue([optionId])

    if (hasChildren(option)) {
      const childrenIds = deepFindChildrenId(optionsById[optionId].children)(options)
      updatedValue = removeFromValue(childrenIds)
    }

    if (hasParent(option)) {
      const parentsId = findParentsId(option)(options)
      updatedValue = removeFromValue(parentsId)
    }

    return updatedValue
  }

  const onSelect = option => {
    const optionId = optionIdentity(option)
    const shouldDeactivate = value.includes(optionId)

    let updatedValue

    if (shouldDeactivate) {
      updatedValue = deactivate(option)
    } else {
      updatedValue = activate(option)
    }

    setValue(() => {
      onChange({ value: updatedValue })
      return updatedValue
    })
  }

  const applySelectedOption = () => {
    const option = getHighlightedOption()

    if (option) {
      setActiveOption(option)
      onSelect(option)
    }
  }

  const onEnterPress = e => {
    if (!isTouchScreen() || !hasSelected) {
      e.preventDefault()
      e.stopPropagation()
      applySelectedOption()
    }
  }

  const onKeyPress = e => {
    watchKey(e, 'Enter', onEnterPress)
  }

  const onClearAll = () => {
    setValue([])
    onChange({ ...props, value })
  }

  const onSearch = ({ target: { value } }) => {
    setSearchText(value)
    onSearchTextChange(value)
  }

  const placeholderBuilder = () => {
    if (placeholder && !hasSelected) {
      return placeholder
    }
    return selectedOptionFilter(selectedOptions).map(selectedOptionTemplate).join(', ')
  }

  return (
    <ClickOutHandler onClickOut={onDeactivateOptions}>
      <div className={classNames('Multiselect', className, { isActive, hasSelected })} style={style}>
        <WrapperComponent {...props}>
          <TextField
            id={id}
            isActive={isActive}
            placeholder={placeholderBuilder}
            onChange={onSearch}
            onBlur={onDeactivateOptions}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            text={searchText}
            onClick={onActivateOptions}
            activeOption={highlightedOption || { id: null }}
            setInputRef={controllerRef}
          />

          {isActive && (
            <div id='combo-listbox' role='listbox' aria-multiselectable='true' className='Multiselect-content'>
              <Options
                isActive={isActive && hasOptions}
                onClearAll={onClearAll}
                selectedOptions={value}
                selectedOptionsGroup={selectedOptionsGroup}
                highlightedOption={highlightedOption}
                options={availableOptions}
                rawOptions={options}
                selectedOptionsWithParents={selectedOptionsWithParents}
                optionTemplate={optionTemplate}
                optionLabel={optionLabel}
                optionIdentity={optionIdentity}
                onSelect={onSelect}
                onMouseEnter={setActiveOption}
                onMouseLeave={unSetActiveOption}
                matchText={searchText}
                wrapperOptionsComponent={wrapperOptionsComponent}
                wrapperOptionsComponentClassName={wrapperOptionsComponentClassName}
                cancelHandler={onDeactivateOptions}
                applyResultsHandler={applyResultsHandler}
                countResults={countResults}
                setFocusableElements={setFocusableElements}
                onKeyDown={onKeydownContent}
                onMouseMove={handleMouseMove}
              />
            </div>
          )}
        </WrapperComponent>
      </div>
    </ClickOutHandler>
  )
}

SearchableTreeCheckySelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  optionsById: PropTypes.object.isRequired,
  selectedOptionsWithParents: PropTypes.array,
  value: MobxPropTypes.arrayOrObservableArray,
  selectedOptionsGroup: PropTypes.array,
  setSelectedOptionsGroup: PropTypes.func,
  onChange: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  optionIdentity: PropTypes.func,
  filterIdentity: PropTypes.func,
  filterWithSearchText: PropTypes.func,
  selectedOptionTemplate: PropTypes.func,
  selectedOptionSort: PropTypes.func,
  selectedOptionFilter: PropTypes.func,
  optionTemplate: PropTypes.func,
  optionLabel: PropTypes.func,
  height: PropTypes.number,
  wrapperComponent: PropTypes.func,
  wrapperOptionsComponent: PropTypes.func,
  optionsComponent: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  applyResultsHandler: PropTypes.func,
  wrapperOptionsComponentClassName: PropTypes.string,
  countResults: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  scrollToTopOnActive: PropTypes.func,
  setFocusableRef: PropTypes.object
}

SearchableTreeCheckySelect.defaultProps = {
  options: [],
  value: [],
  selectedOptionsWithParents: [],
  selectedOptionsGroup: [],
  setSelectedOptionsGroup: noop,
  optionIdentity: getId,
  filterIdentity: getName,
  filterWithSearchText: (searchText, props) => options => (
    options.filter(o => (
      props.filterIdentity(o)
        .toLocaleLowerCase()
        .includes(searchText.toLowerCase())
    )).sort(sortByMatch({ searchText }, props))
  ),
  selectedOptionTemplate: getName,
  selectedOptionSort: noop,
  selectedOptionFilter: identity,
  optionTemplate: ({ labelText }) => labelText,
  optionLabel: getName,
  onChange: noop,
  onSearchTextChange: noop,
  height: 53,
  wrapperComponent: (({ children }) => children),
  applyResultsHandler: noop,
  wrapperOptionsComponentClassName: '',
  countResults: false,
  scrollToTopOnActive: noop
}

export default SearchableTreeCheckySelect
