import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { withProps } from 'recompose'
import Option from '../Option'
import { branch, renderComponent, renderNothing } from 'recompose'

const enhance = withProps(({ additionalOptionGroups, optionIdentity, selectedOptions }) => {
  const options = additionalOptionGroups && Object.keys(additionalOptionGroups).reduce((res, groupName) => {
    const groupContent = additionalOptionGroups[groupName].filter(o => !selectedOptions.includes(optionIdentity(o)))
    return groupContent.length > 0 ? {...res, [groupName]: groupContent} : res
  }, {})

  return { options }
})

const ActiveOptions = ({
  options, onSelect, onMouseEnter, onMouseLeave,
  optionTemplate, optionIdentity, highlightedOption,
  activeRef, selectedOptions
}) => (
  <div className='Multiselect-additionalOptionGroups'>
    {
      Object.keys(options).map(name => (
        <div key={name} className='Multiselect-additionalOptionGroup'>
          <div className='Multiselect-groupName'>
            { name }
          </div>

          <div className='Multiselect-groupContent'>
            {
              options[name].map(o => (
                <Option
                  key={optionIdentity(o)}
                  option={o}
                  optionTemplate={optionTemplate}
                  optionIdentity={optionIdentity}
                  onSelect={onSelect}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  isHighlighted={highlightedOption === o}
                  isActive={selectedOptions.includes(optionIdentity(o))}
                  activeRef={activeRef}
                />
              ))
            }
          </div>
        </div>
      ))
    }
  </div>
)

ActiveOptions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  additionalOptionGroups: PropTypes.object,
  selectedOptions: MobxPropTypes.arrayOrObservableArray.isRequired,
  options: PropTypes.object,
  highlightedOption: PropTypes.object,
  activeRef: PropTypes.func.isRequired,
  isActive: PropTypes.bool
}

const AdditionalOptionGroups = branch(
  ({ isActive }) => isActive,
  renderComponent(enhance(ActiveOptions)),
  renderNothing
)

export default AdditionalOptionGroups()
