import { lifecycle } from 'recompose'
import { registerActions } from '../../lib/globalActions'

const registerGlobalActions = (...args) => lifecycle({
  componentDidMount() {
    registerActions(this.props)(...args)
  }
})

export default registerGlobalActions
