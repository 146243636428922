import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { compose, defaultProps, withHandlers, withProps } from 'recompose'
import * as R from 'ramda'
import { isEmpty } from '../../../../lib/utils/collection'
import { getId, getName } from '../../../../lib/utils/selectors'
import { Select } from '../../../../components/Shared/Form'

const setSingleValue = (options, value, mainValue, optionIdentity) => {
  const optionsIds = options.map(optionIdentity)
  const selectedIds = optionsIds.filter(o => value && value.includes(o))

  if (selectedIds.length > 1) {
    return optionsIds.find(o => (o !== mainValue) && value && value.includes(o))
  }

  return optionsIds.find(o => value && value.includes(o))
}

const enhance = compose(
  defaultProps({
    optionIdentity: getId,
    selectedOptionTemplate: getName,
    optionTemplate: getName
  }),
  withProps(({ value, options, optionIdentity, mainValue }) => ({
    singleValue: setSingleValue(options, value, mainValue, optionIdentity),
    value: value || []
  })),
  withHandlers({
    onChange: ({ onChange, value, options, optionIdentity, mainValue }) => selectedValue => {
      const subValue = optionIdentity(selectedValue)

      if (isEmpty(subValue)) {
        onChange(R.difference(value, options.map(o => optionIdentity(o))))
      } else if(mainValue && mainValue !== subValue) {
        onChange([mainValue, ...R.difference(value, options.map(o => optionIdentity(o))), subValue])
      } else {
        onChange([...R.difference(value, options.map(o => optionIdentity(o))), subValue])
      }
    }
  })
)


const SelectForArray = ({ singleValue, ...otherProps }) => (
  <Select
    {...otherProps}
    value={singleValue}
  />
)

SelectForArray.propTypes = {
  singleValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  options: MobxPropTypes.arrayOrObservableArray.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  mainValue: PropTypes.string
}

export default enhance(SelectForArray)
