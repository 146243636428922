/* eslint-disable react/no-unknown-property */

import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-formal'
import * as R from 'ramda'
import { mapProps, compose } from 'recompose'
import { hideIfNoData } from '../../../../components/Hocs'

const enhance = compose(
  hideIfNoData(({ isVisible = true }) => !isVisible),
  mapProps(props => R.omit(['isVisible'], props))
)

const FormField = ({ wrapperClassName, labelText, errorComponent, type, name, withLabel, ...otherProps }) => {
  const ErrorComponent = errorComponent
  return (
    <div className={wrapperClassName}>
      {withLabel && <label htmlFor={`${name}_id`} className='Label'>{labelText || name}</label>}
      <Form.Field id={`${name}_id`} type={type} errorClass='isInvalid' name={name} {...otherProps} />
      <Form.Message for={name}>
        {errors => <ErrorComponent className='ErrorText'>{errors}</ErrorComponent>}
      </Form.Message>
    </div>
  )
}

FormField.defaultProps = {
  withLabel: true,
  type: 'text',
  errorComponent: 'p'
}

FormField.propTypes = {
  withLabel: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  labelText: PropTypes.string,
  errorComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  type: PropTypes.any,
  name: PropTypes.string.isRequired,
  isVisible: PropTypes.bool
}

export default enhance(FormField)
