import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { defaultProps, withHandlers, compose } from 'recompose'
import cn from 'classnames'

const enhance = compose(
  defaultProps({
    identity: ({ id }) => id,
    label: ({ title }) => title,
    description: ({ description }) => description,
    onChange: () => {}
  }),
  withHandlers({
    onRadioButtonChange: ({ onChange }) => e => {
      onChange(e.target.value)
    }
  })
)

const RadioDescriptionGroup = ({
  value, identity, label, description, options, onRadioButtonChange, className
}) => (
  <div className={cn('RadioButtonGroup', className)}>
    {
      options.map(option => (
        <div key={identity(option)} className='RadioButtonGroup-radio RadioButtonGroup-radio--withDescription'>
          <div className='Radio'>
            <label className='Radio-label'>
              <input
                className='Radio-input'
                type='radio'
                checked={value === identity(option)}
                value={identity(option)}
                onChange={onRadioButtonChange}
              />
              <span className='Radio-icon'/>
              <span className='Radio-labelTitle'>
                {label(option)}
              </span>
              <div className='Radio-labelDescription'>
                {description(option)}
              </div>
            </label>
          </div>
        </div>
      ))
    }
  </div>
)

RadioDescriptionGroup.propTypes = {
  value: PropTypes.string,
  options: MobxPropTypes.arrayOrObservableArray,
  onChange: PropTypes.func.isRequired,
  onRadioButtonChange: PropTypes.func.isRequired,
  identity: PropTypes.func,
  label: PropTypes.func,
  description: PropTypes.func,
  className: PropTypes.string
}

export default enhance(RadioDescriptionGroup)
