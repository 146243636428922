const FOCUSABLE_ELEMENT_SELECTORS = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([aria-checked="false"])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled]):not([tabindex="-1"])',
  'iframe',
  'object',
  '[tabindex="0"]',
  '[role="tab"]',
  '[contenteditable]'
].join(' ,')

export default FOCUSABLE_ELEMENT_SELECTORS
