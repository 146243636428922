import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import ClickOutHandler from 'react-onclickout'
import Options from './Options'
import { getId, getName, getChildren } from '../../../../lib/utils/selectors'
import { noop } from '../../../../lib/utils/common'
import { isPresent, removeFromArray } from '../../../../lib/utils/collection'
import { onKey, watchKey } from '../../../../lib/utils/dom'
import KEY from '../../../../lib/constants/key'
import { checkySelectPropTypes } from './CheckySelectPropTypes'
import { CheckySelectButton } from './CheckySelectButton'

const CheckySelect = props => {
  const {
    id,
    tabIndex,
    className,
    isDisabled,
    placeholder,
    selectedOptionsTemplate,
    optionIdentity,
    options,
    onChange
  } = props

  const controllerRef = useRef(null)
  const componentRef = useRef(null)

  const [isActive, setActive] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [value, setValue] = useState([])
  const [prevState, setPrevState] = useState({
    value: props.value
  })

  const setState = () => {
    setValue(props.value || [])
    setSelectedOptions(options.filter(o => props.value && props.value.includes(optionIdentity(o))))
  }

  useEffect(() => {
    setState()
  }, [])

  const highlightedOption = options.find((_, index) => index === selectedIndex) || {}

  const onActivateOptions = () => {
    setActive(true)
    setSelectedIndex(0)
  }

  const onDeactivateOptions = e => {
    if (!e.target) {
      return
    }

    setActive(false)
    setSelectedIndex(-1)
  }

  const onSelect = option => {
    let newSelectedOptions
    let newValues

    if (selectedOptions.includes(option)) {
      newSelectedOptions = removeFromArray(selectedOptions, option)
      newValues = removeFromArray(value, optionIdentity(option))
    } else {
      newSelectedOptions = [...selectedOptions, option]
      newValues = [...value, optionIdentity(option)]
    }

    onChange(newSelectedOptions)
    setSelectedOptions(newSelectedOptions)
    setValue(newValues)
  }

  if (props.value !== prevState.value) {
    setState()
    setPrevState({ value })
  }

  const nextActiveOption = () => {
    if (isActive) {
      const index = selectedIndex === options.length - 1 ? options.length - 1 : selectedIndex + 1
      setSelectedIndex(index)
      return
    }

    onActivateOptions()
  }

  const prevActiveOption = () => {
    if (isActive) {
      const index = selectedIndex <= 0 ? 0 : selectedIndex - 1
      setSelectedIndex(index)
      return
    }

    onActivateOptions()
  }

  const onToggleActiveOptions = e => {
    e.preventDefault()
    if (isActive) {
      onDeactivateOptions(e)
      return
    }

    onActivateOptions()
  }

  const applySelectedOption = () => {
    if (isPresent(highlightedOption)) {
      onSelect(highlightedOption)
    }
  }

  const deactivateOnBlur = e => {
    global.requestAnimationFrame(() => {
      const { activeElement } = document
      const component = componentRef.current

      if (isPresent(component) && !component.contains(activeElement)) {
        onDeactivateOptions(e)
      }
    })
  }

  const onKeyDown = e => {
    e.persist()

    onKey(e, KEY.DOWN, nextActiveOption)
    onKey(e, KEY.UP, prevActiveOption)
    onKey(e, KEY.ESC, onDeactivateOptions)
    onKey(e, KEY.RETURN, onDeactivateOptions)
    onKey(e, KEY.SPACE, applySelectedOption)
    watchKey(e, KEY.TAB, deactivateOnBlur)
  }

  const onKeyDownContent = e => {
    watchKey(e, KEY.TAB, deactivateOnBlur)
    onKey(e, KEY.ESC, () => {
      onDeactivateOptions(e)
      controllerRef.current.focus()
    })
  }

  const manageHighlightedOption = index => {
    setSelectedIndex(index)
  }

  const onClearAll = () => {
    onChange([])
    setSelectedOptions([])
    setValue([])
    controllerRef.current.focus()
  }

  const optionsWithWrapper = () => {
    const WrapperComponent = props.wrapperComponent
    const wrapperProps = { ...props, className: props.wrapperClassName }
    return (
      <WrapperComponent {...wrapperProps}>
        <Options
          options={props.options}
          name={props.name}
          selectedOptions={selectedOptions}
          optionIdentity={props.optionIdentity}
          optionTemplate={props.optionTemplate}
          highlightedOption={highlightedOption}
          onSelect={onSelect}
          isActive={isActive}
          withClearAll={props.withClearAll}
          onClearAll={onClearAll}
          wrapperOptionsComponent={props.wrapperOptionsComponent}
          selectedIndex={selectedIndex}
          onMouseEnter={manageHighlightedOption}
          onMouseLeave={manageHighlightedOption}
          onKeyDownContent={onKeyDownContent}
          id={`${props.id}-listOptions`}
          withScrollIntoView={props.withScrollIntoView}
          {...wrapperProps}
        />
      </WrapperComponent>
    )
  }

  return (
    <ClickOutHandler onClickOut={onDeactivateOptions}>
      <div
        id={id}
        tabIndex={tabIndex}
        className={classNames('CheckySelect', className, { isDisabled })}
        ref={componentRef}
      >
        <CheckySelectButton
          id={id}
          highlightedOptionId={highlightedOption.id || null}
          placeholder={placeholder}
          selectedOptionsTemplate={selectedOptionsTemplate}
          isActive={isActive}
          selectedOptions={selectedOptions}
          onToggleActiveOptions={onToggleActiveOptions}
          onKeyDown={onKeyDown}
          controllerRef={controllerRef}
          isDisabled={isDisabled}
        />
        {optionsWithWrapper()}
      </div>
    </ClickOutHandler>
  )
}

CheckySelect.defaultProps = {
  optionIdentity: getId,
  selectedOptionsTemplate: options => options.map(o => o.name).join(', '),
  optionTemplate: getName,
  onChange: noop,
  value: [],
  wrapperComponent: getChildren,
  wrapperOptionsComponent: getChildren,
  tabIndex: -1
}

CheckySelect.propTypes = checkySelectPropTypes

export default CheckySelect
