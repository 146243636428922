import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { sanitizeID } from '../../../../../lib/utils/string'
import { isPresent } from '../../../../../lib/utils/collection'
import { withScrollToActiveOption } from '../../../../Hocs'
import Option from './Option'
import RootAreaOptionTemplate from './RootAreaOptionTemplate'

const Options = ({
  onKeyDown,
  rootAreas,
  optionLabel,
  onSelect,
  selectedOptions,
  isHighlighted,
  handleMouseEnter,
  handleMouseLeave,
  setActiveOptionRef,
  setOptionsRef,
  rawOptions,
  optionIdentity,
  optionTemplate,
  hoveredOption,
  onMouseMove,
  setOptionsScrollerRef
}) => {
  const setRefs = el => {
    setOptionsScrollerRef(el)
    setOptionsRef(el)
  }

  const renderOption = o => {
    return (
      <Option
        id={`${sanitizeID(o.id)}-map`}
        key={optionIdentity(o)}
        option={o}
        optionTemplate={optionTemplate}
        optionLabel={optionLabel}
        optionIdentity={optionIdentity}
        onSelect={onSelect}
        isActive={selectedOptions.includes(optionIdentity(o))}
        isHighlighted={isHighlighted(o)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        activeRef={setActiveOptionRef}
      />
    )
  }

  const renderOptionsTree = node => {
    const hasChildren = isPresent(node.children)

    if (hasChildren) {
      const childNodes = R.filter(n => R.includes(n.id, node.children))(rawOptions)

      return (
        <li className='AreaMapSelector-option'
          key={node.id}>
          {renderOption(node)}
          <ul className='AreaMapSelector-nestedOptions'
            role='group'
            key={`${node.id}-nested`}
            aria-labelledby={`${sanitizeID(node.id)}-map`}
          >
            {childNodes.map(renderOptionsTree)}
          </ul>
        </li>
      )
    }

    return (<li className='AreaMapSelector-option' key={node.id}> {renderOption(node)} </li>)
  }

  return (
    <div className='AreaMapSelector-optionsWrapper'
      tabIndex='0'
      role='listbox'
      aria-labelledby='areaSelector'
      aria-multiselectable='true'
      aria-activedescendant={hoveredOption ? `${sanitizeID(hoveredOption.id)}-map` : null}
      onKeyDown={onKeyDown}>
      <span className='sr-only' id='areaSelector'>Area selector</span>
      <div
        className='AreaMapSelector-optionsScroller'
        ref={setRefs}
        onMouseMove={onMouseMove}
      >
        {rootAreas.map((rootArea, i) => (
          <Fragment key={i}>
            <div className='AreaMapSelector-rootArea'>
              <Option
                id={`${sanitizeID(rootArea.id)}-map`}
                key={optionIdentity(rootArea)}
                option={rootArea}
                optionTemplate={RootAreaOptionTemplate}
                optionLabel={optionLabel}
                optionIdentity={optionIdentity}
                onSelect={onSelect}
                isActive={selectedOptions.includes(optionIdentity(rootArea))}
                isChild={false}
                isHighlighted={isHighlighted(rootArea)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                activeRef={setActiveOptionRef}
              />
            </div>
            <ul className='AreaMapSelector-options' aria-labelledby={`${sanitizeID(rootArea.id)}-map`}
              role='group'>
              {R.filter(n => R.includes(n.id, rootArea.children))(rawOptions).map(el => renderOptionsTree(el))}
            </ul>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

Options.propTypes = {
  onKeyDown: PropTypes.func.isRequired,
  rootAreas: PropTypes.array.isRequired,
  optionLabel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  isHighlighted: PropTypes.func.isRequired,
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
  setActiveOptionRef: PropTypes.func.isRequired,
  setOptionsRef: PropTypes.func.isRequired,
  rawOptions: PropTypes.array.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  hoveredOption: PropTypes.object,
  onMouseMove: PropTypes.func.isRequired,
  setOptionsScrollerRef: PropTypes.func.isRequired
}

export default withScrollToActiveOption(Options)
