export const isSelectable = listing => (
  listing.statusInfo.type !== 'draft' &&
    listing.statusInfo.type !== 'pending-approval'
)

export const checkboxCartProps = isChecked => (
  isChecked
    ? { tooltip: 'Remove from cart' }
    : { tooltip: 'Add to cart' }
)

export const showRentalPricesWarning = ({ listingClass, visibility, listingType }) => (
  listingClass === 'Rental' &&
  (visibility === 'default' || visibility === 'orex_only') &&
  (listingType === 'open-listing' || listingType === 'co-exclusive' ||
    // TODO: Remove listing type labeling in listing serializers since we have partially options to
    //  convert open-listing into `Open listing`. It depends on which page - orex search or pending listings page
    listingType === 'Co-exclusive') || listingType === 'Open listing'
)
