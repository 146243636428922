import React from 'react'
import PropTypes from 'prop-types'
import { withHandlers, compose } from 'recompose'
import cn from 'classnames'

const enhance = compose(
  withHandlers({
    onTogglerClick: ({ onChange, value }) => newValue => () => {
      if(newValue !== value) {
        onChange(newValue)
      }
    }
  })
)

const TogglerWithText = ({ value, onTogglerClick, textForCheck, textForUnCheck }) => (
  <div className='TogglerWithText'>
    <label className='TogglerWithText-label'>
      <span
        className={cn('TogglerWithText-text', { 'TogglerWithText-text--active': !value })}
        onClick={onTogglerClick(false)}
        onDoubleClick={onTogglerClick(false)}
      >
        {textForUnCheck}
      </span>
      <span
        className={cn('TogglerWithText-text', { 'TogglerWithText-text--active': value })}
        onClick={onTogglerClick(true)}
        onDoubleClick={onTogglerClick(true)}
      >
        {textForCheck}
      </span>
    </label>
  </div>
)

TogglerWithText.defaultProps = {
  textForCheck: 'Yes',
  textForUnCheck: 'No'
}

TogglerWithText.propTypes = {
  onTogglerClick: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  textForCheck: PropTypes.string.isRequired,
  textForUnCheck: PropTypes.string.isRequired
}

export default enhance(TogglerWithText)
