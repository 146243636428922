import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { branch, renderComponent, renderNothing } from 'recompose'
import Option from '../Option'
import { withScrollToActiveOption } from '../../../../Hocs'
import { childrenPropType } from '../../../../../lib/propTypes'

const ActiveOptions = props => {
  const {
    options, onSelect, onMouseEnter, onMouseLeave,
    optionTemplate, optionIdentity, highlightedOption,
    selectedOptions, matchText, children, setActiveOptionRef, setOptionsRef
  } = props

  const childrenWithProps = React.Children.map(children, child => (
    React.cloneElement(child, { activeRef: setActiveOptionRef })
  ))

  return (
    <div className='Multiselect-options' ref={setOptionsRef}>
      {
        options.map(o => (
          <Option
            key={optionIdentity(o)}
            option={o}
            optionTemplate={optionTemplate}
            optionIdentity={optionIdentity}
            onSelect={onSelect}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isHighlighted={highlightedOption === o}
            isActive={selectedOptions.includes(optionIdentity(o))}
            matchText={matchText}
            activeRef={setActiveOptionRef}
          />
        ))
      }
      { childrenWithProps }
    </div>
  )
}

ActiveOptions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: MobxPropTypes.arrayOrObservableArray.isRequired,
  highlightedOption: PropTypes.object,
  matchText: PropTypes.string,
  children: childrenPropType,
  setActiveOptionRef: PropTypes.func.isRequired,
  setOptionsRef: PropTypes.func.isRequired
}

const Options = branch(
  ({ isActive }) => isActive,
  renderComponent(withScrollToActiveOption(ActiveOptions)),
  renderNothing
)

export default Options()
