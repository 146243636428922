const mapDataToServer = schema => args => {
  const { defaultValues, scope, values } = args
  const data = { utf8: '✓'}

  Object.keys(schema).forEach(key => {
    Object.assign(data, schema[key](key, values, defaultValues))
  })

  return { [scope]: data }
}

export default mapDataToServer
