import { lens, just } from 'lorgnette'
import * as R from 'ramda'
import { isEmpty, flatten } from './collection'
import { isArray } from './common'

export const groupRequiredMessageTypes = errors => {
  let msgArray = []
  let requiredErrors = 0
  Object.keys(errors).forEach(key => {
    if (Array.isArray(errors[key])) {
      const errorLens = lens.prop(key).first()
      const errorType = errorLens.prop('type').get(errors).getOr(null)
      const errorMessage = errorLens.prop('message').get(errors).getOr(null)
      if (!errorType && !errorMessage) {
        msgArray = [...msgArray, ...errors[key]]
      } else if (errorType === 'required') {
        requiredErrors = requiredErrors + 1
      } else {
        msgArray.push(errorMessage)
      }
    } else {
      msgArray.push(errors[key])
    }
  })

  if (requiredErrors >= 1) {
    msgArray.unshift(`Please complete ${requiredErrors} required field(s) to publish this listing`)
  }

  return msgArray
}

export const groupAllErrorMessages = errors => (
  flatten(Object.keys(errors).map(key => (
    lens
      .prop(key)
      .get(errors)
      .then(errs => just(isArray(errs) ? errs.map(err => err.message || err) : [errs]))
      .getOr([])
  )))
)

export const mapToValueFromCompareOptions = (options, getFieldValue) => value => {
  const fieldValue = getFieldValue(value)

  if (fieldValue) {
    const findValue = options.find(option => R.equals(new Set(option.id.split('/')), new Set(fieldValue)))
    return findValue && findValue.id
  }
  return null
}

export const mapFromSocialValue = url => value => {
  const cleanHandle = isEmpty(value) || value.trim() === '@' ? null : value.replace(/[@]/gi, '').trim()
  return cleanHandle ? `${url}${cleanHandle}` : null
}

export const mapToSocialValue = name => value => {
  const inputValue = isEmpty(value[name]) ? '' : value[name]
  const handle = inputValue ? R.last(inputValue.split('/').filter(v=> v)) : ''
  return `@ ${handle}`
}

export const selectedTreeOptions = ({ values, options, optionIdentity }) => {
  if (!values) {
    return []
  }

  let selectedOptions = []

  options.forEach(option => {
    const optionIncluded = values.includes(optionIdentity(option))

    if (option.children && option.children.length > 0) {
      if (option.children.every(o => values.includes(optionIdentity(o))) && optionIncluded) {
        selectedOptions = [...selectedOptions, option]
      } else if (option.children.some(o => values.includes(optionIdentity(o)))) {
        selectedOptions = [...selectedOptions, ...option.children.filter(o => values.includes(optionIdentity(o)))]
      } else if (optionIncluded) {
        selectedOptions = [...selectedOptions, option]
      }
      selectedTreeOptions({ values, options: option.children, optionIdentity })
    } else if (optionIncluded) {
      selectedOptions = [...selectedOptions, option]
    }
  })

  return selectedOptions
}
