import React from 'react'
import PropTypes from 'prop-types'
import Option from './Option'
import { withScrollToActiveOption } from '../../../Hocs'
import { branch, renderComponent, renderNothing } from 'recompose'
import OptionScroller from '../Shared/OptionScroller'

const ActiveOptions = props => {
  const {
    onSelect, onMouseEnter, onMouseLeave,
    optionTemplate, optionIdentity, skipMatchWrapping,
    selectedOption, matchText, setActiveOptionRef, highlightedOption,
    setOptionsRef, options, wrapperOptionsComponent
  } = props

  const WrapperOptionsComponent = wrapperOptionsComponent
  return (
    <div
      className='Multiselect-options'
      ref={setOptionsRef}
      // Fix for Safari(blur event is called for input when clicked on div scrollbar)
      onMouseDown={e => { e.preventDefault() }}
    >
      <WrapperOptionsComponent {...props}>
        <OptionScroller setOptionsRef={setOptionsRef}>
          {
            options.map(o => (
              <Option
                key={optionIdentity(o)}
                option={o}
                optionTemplate={optionTemplate}
                optionIdentity={optionIdentity}
                onSelect={onSelect}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                skipMatchWrapping={skipMatchWrapping}
                isHighlighted={optionIdentity(highlightedOption) === optionIdentity(o)}
                isActive={optionIdentity(selectedOption) === optionIdentity(o)}
                matchText={matchText}
                activeRef={setActiveOptionRef}
              />
            ))
          }
        </OptionScroller>
      </WrapperOptionsComponent>
    </div>
  )
}

ActiveOptions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  rawOptions: PropTypes.array.isRequired,
  selectedOption: PropTypes.object,
  highlightedOption: PropTypes.object,
  skipMatchWrapping: PropTypes.bool,
  matchText: PropTypes.string,
  setActiveOptionRef: PropTypes.func.isRequired,
  setOptionsRef: PropTypes.func.isRequired,
  wrapperOptionsComponent: PropTypes.func
}

ActiveOptions.defaultProps = {
  wrapperOptionsComponent: (({children}) => children)
}

const Options = branch(
  ({ isActive }) => isActive,
  renderComponent(withScrollToActiveOption(ActiveOptions)),
  renderNothing
)

export default Options(ActiveOptions)
