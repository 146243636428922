import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { defaultProps, withHandlers, compose } from 'recompose'
import { removeFromArray } from '../../../../lib/utils/collection'
import cn from 'classnames'

const enhance = compose(
  defaultProps({
    identity: v => v,
    label: v => v,
    onChange: () => {}
  }),
  withHandlers({
    onCheckboxChange: ({ onChange, value, identity }) => option => e => {
      const newValue = value || []

      if (e.target.checked) {
        onChange([...newValue, identity(option)])
      } else {
        onChange(removeFromArray(newValue, identity(option)))
      }
    }
  })
)

const CheckboxGroup = ({ value, identity, label, options, onCheckboxChange, className }) => (
  <div className={cn('CheckboxGroup', className)}>
    {
      options.map(option => (
        <div key={identity(option)} className='CheckboxGroup-checkbox'>
          <div className='Checkbox'>
            <label className='Checkbox-label'>
              <input
                className='Checkbox-input'
                type='checkbox'
                checked={value && value.includes(identity(option)) || false}
                onChange={onCheckboxChange(option)}
              />
              <span className='Checkbox-icon'/>
              {label(option)}
            </label>
          </div>
        </div>
      ))
    }
  </div>
)

CheckboxGroup.propTypes = {
  value: MobxPropTypes.arrayOrObservableArray,
  options: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  identity: PropTypes.func,
  label: PropTypes.func,
  className: PropTypes.string
}

export default enhance(CheckboxGroup)
