import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent, renderNothing, setPropTypes, compose } from 'recompose'

const InfoMessageActive = ({ children }) => (
  <div className='Form-row Form-row--mediumSpace'>
    <div className='Text Text--a1 Text--lineHeight19px Text--alignCenter Text--sidePadding'>
      { children }
    </div>
  </div>
)

const InfoMessage = compose(
  setPropTypes({
    isActive: PropTypes.bool.isRequired
  }),
  branch(
    ({isActive}) => isActive,
    renderComponent(InfoMessageActive),
    renderNothing
  )
)

InfoMessageActive.propTypes = {
  children: PropTypes.string.isRequired
}

export default InfoMessage()
