import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, Polygon } from '@react-google-maps/api'
import { toPolygonName } from '../../../../../lib/dataMappings/search'
import { isViewport } from '../../../../../lib/utils/viewport'
import { useGoogleMap } from '../../../../../shared_components/Hooks/useGoogleMap'
import polygons from '../../../../../lib/config/polygons.json'
import { defaultZoom, commonGoogleMapOptions, DEFAULT_CENTER } from '../../../../../shared_components/Map/constants'
import mapStyles from '../../../../../shared_components/Map/mapStyles'

const SELECTED_COLOR = 'rgba(124, 215, 96, .65)'
const HIGHLIGHTED_COLOR = 'rgba(248, 153, 17, .5)'
const DEFAULT_FILL_COLOR = 'transparent'
const DEFAULT_STROKE_COLOR = '#96989b'

const polygonBaseStyle = {
  fillColor: DEFAULT_FILL_COLOR,
  strokeColor: DEFAULT_STROKE_COLOR,
  strokeOpacity: 1,
  strokeWeight: 1,
  zIndex: 10
}

const polygonHighlightedStyle = {
  fillColor: HIGHLIGHTED_COLOR,
  strokeColor: HIGHLIGHTED_COLOR,
  strokeOpacity: 1,
  strokeWeight: 1
}

const polygonSelectedStyle = {
  fillColor: SELECTED_COLOR,
  strokeColor: SELECTED_COLOR,
  strokeOpacity: 1,
  strokeWeight: 1
}

const Map = ({
  onMouseEnter,
  onMouseLeave,
  onSelect,
  registerRef,
  optionIdentity,
  selectedOptions,
  rawOptions,
  optionLabel,
  highlightedOption
}) => {
  const containerStyle = isViewport('mobile')
    ? { height: '100vh', width: '100vw' }
    : { height: '680px', width: '450px' }

  const { map, isLoaded, onMapLoad, onMapUnmount } = useGoogleMap()

  const handleClick = option => () => {
    onSelect(option)
  }

  useEffect(() => {
    registerRef(map)

    return () => {
      registerRef(null)
    }
  }, [map])

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={DEFAULT_CENTER}
        tilt={0}
        zoom={defaultZoom}
        panTo={DEFAULT_CENTER}
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        options={{
          ...commonGoogleMapOptions,
          styles: mapStyles,
          scrollwheel: false,
          streetViewControl: false,
          zoomControlOptions: {
            position: isViewport('mobile')
              ? global.google.maps.ControlPosition.LEFT_BOTTOM
              : global.google.maps.ControlPosition.RIGHT_BOTTOM
          }
        }}
      >
        {rawOptions.map(o => [o, toPolygonName(optionLabel(o))])
          .filter(([_, polygon]) => Object.keys(polygons).includes(polygon))
          .map(([option, polygon], i) => (
            <Polygon
              key={i}
              options={polygonBaseStyle}
              path={polygons[polygon].points}
              onClick={handleClick(option)}
              onMouseOver={() => {
                onMouseEnter(option, false)
              }}
              onMouseOut={() => {
                onMouseLeave(option, false)
              }}
            />
          ))
        }
        {highlightedOption &&
          polygons[toPolygonName(optionLabel(highlightedOption))] && (
          <Polygon
            options={polygonHighlightedStyle}
            path={polygons[toPolygonName(optionLabel(highlightedOption))].points}
          />
        )}
        {rawOptions.filter(o => selectedOptions.includes(optionIdentity(o)))
          .map(o => toPolygonName(optionLabel(o)))
          .filter(area => Object.keys(polygons).includes(area)).map((polygon, i) => (
            <Polygon
              key={i}
              options={polygonSelectedStyle}
              path={polygons[polygon].points}
            />
          ))
        }
      </GoogleMap>
    )
  )
}

Map.propTypes = {
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  registerRef: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  highlightedOption: PropTypes.object,
  selectedOptions: PropTypes.array.isRequired,
  optionLabel: PropTypes.func.isRequired,
  rawOptions: PropTypes.array.isRequired
}

export default Map
