import { withProps, withHandlers, lifecycle, compose, mapProps } from 'recompose'
import { inject, observer } from 'mobx-react'
import { CONTACT_FORM_STATE } from '../../lib/utils/stores'
import withPortal from './withPortal'

const withContactFormHandlers = (additionalHandlers = {}) => compose(
  inject('store'),
  withProps(({ store }) => ({
    uiState: store.uiStateStore,
    contactAgentFormStore: store.contactAgentFormStore,
    contactOwnerFormStore: store.contactOwnerFormStore,
    contactCaptcha: store.contactCaptchaStore,
    userStore: store.userStore
  })),
  observer,
  mapProps(props => ({
    ...props,
    isDisabled: !props.contactCaptcha.isCaptchaValid || props.uiState.isSubmitDisable
  })),
  withHandlers({
    deactivateContactForm: ({ uiState }) => () => {
      uiState.setContactFormState(CONTACT_FORM_STATE.inactive)
    },
    disableSubmit: ({ uiState }) => () => {
      uiState.toggleSubmitDisable(true)
    },
    enableSubmit: ({ uiState }) => () => {
      uiState.toggleSubmitDisable(false)
    }
  }),
  withHandlers(additionalHandlers),
  lifecycle({
    componentDidMount() {
      document.getElementById('site-scroller').classList.add('isModalActive')
      this.props.contactCaptcha.checkCaptchaActive()
    },
    componentWillUnmount() {
      document.getElementById('site-scroller').classList.remove('isModalActive')
    }
  }),
  withPortal('[data-contact-form=container]'),
  observer
)

export default withContactFormHandlers
