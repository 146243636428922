import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SelectedOptions from './SelectedOptions'
import { numberOrString } from '../../../../lib/propTypes'

export const CheckySelectButton = ({
  id,
  placeholder,
  selectedOptionsTemplate,
  highlightedOptionId,
  isActive,
  isDisabled,
  selectedOptions,
  onToggleActiveOptions,
  onKeyDown,
  controllerRef
}) => {
  return (
    <button
      role='combobox'
      type='button'
      aria-expanded={isActive}
      aria-owns={`${id}-listOptions`}
      aria-labelledby={`${id}-label`}
      aria-controls={`${id}-listOptions`}
      aria-activedescendant={highlightedOptionId}
      className={classNames('CheckySelect-selectedOptions', { isActive })}
      onClick={onToggleActiveOptions}
      onKeyDown={onKeyDown}
      ref={controllerRef}
      disabled={isDisabled || null}
    >
      <SelectedOptions
        placeholder={placeholder}
        selectedOptionsTemplate={selectedOptionsTemplate}
        selectedOptions={selectedOptions}
      />
    </button>
  )
}

CheckySelectButton.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  selectedOptionsTemplate: PropTypes.func,
  highlightedOptionId: numberOrString,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectedOptions: PropTypes.array,
  onToggleActiveOptions: PropTypes.func,
  onKeyDown: PropTypes.func,
  controllerRef: PropTypes.object
}
