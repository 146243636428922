import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { childrenPropType } from '../../../../lib/propTypes'

const ErrorMessage = ({ children, errorClassName, containerClassName, id = null }) => (
  <div className={cn('Form-row', containerClassName)}>
    <div className={errorClassName} id={id}>
      { children }
    </div>
  </div>
)

ErrorMessage.defaultProps = {
  errorClassName: 'Text Text--errorText Text--alignCenter',
  containerClassName: 'Form-row--mediumSpace'
}

ErrorMessage.propTypes = {
  children: childrenPropType,
  errorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  id: PropTypes.string
}

export default ErrorMessage
