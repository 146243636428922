import { computed, action, observable, toJS, makeObservable } from 'mobx'
import * as R from 'ramda'
import { toFormErrors } from 'react-formal'
import { fromServerToClient, errorsFromServerToClient, lensFor } from '../utils/collection'

const formatErrors = R.compose(fromServerToClient, errorsFromServerToClient)

class BaseForm {
  @observable value = {}
  @observable errors = {}
  @observable hasChanges = false
  @observable changedFields = []

  constructor() {
    makeObservable(this)
  }

  @action('[Form] setInitialData')
  setInitialData({ value }) {
    this.value = fromServerToClient(value)
  }

  @computed
  get toForm() {
    return toJS(this.value)
  }

  @computed
  get formErrors() {
    return toJS(this.errors)
  }

  isFieldInvalid(fieldName) {
    return fieldName in this.formErrors
  }

  onFormChange = (formState, [name]) => {
    this.setHasChanges(true)
    this.updateChangedFields(name)
    this.setField(name, lensFor(name).get(formState).getOr(null))
  }

  @action('[Form] Set hasChanges')
  setHasChanges = value => {
    this.hasChanges = value
  }

  @action('[Form] Update changed')
  updateChangedFields = name => {
    this.changedFields = [...this.changedFields, name]
  }

  @action('[Form] set field')
  setField(name, value) {
    this.value = lensFor(name).set(this.value, value)
    this.setErrors(R.omit([name], this.errors))
  }

  @action('[Form] update error')
  setError = (name, error) => {
    this.errors = { ...this.errors, [name]: error }
  }

  @action('[Form] set value')
  setValue = value => {
    this.value = value
  }

  @action('[Form] setErrors')
  setErrors = v => {
    this.errors = v
  }

  @action('[Form] reset errors')
  resetErrors = () => {
    this.errors = {}
  }

  @action('[Form] setServerErrors')
  setServerErrors = errors => {
    this.errors = { ...this.errors, ...formatErrors(errors)}
  }

  validate = schema => {
    this.resetErrors()
    try {
      schema.validateSync(this.toForm, { abortEarly: false, context: this})
    } catch (e) {
      this.setErrors(toFormErrors(e))
    }
  }

  validateAt = (schema, fieldName) => {
    try {
      schema.validateSyncAt(fieldName, this.toForm, { abortEarly: false, context: this})
    } catch (e) {
      this.setErrors({
        ...this.errors,
        ...toFormErrors(e)
      })
    }
  }

  clearCurrentFieldError = fieldName => {
    this.setErrors(R.omit([fieldName], this.errors))
  }

  setDefaultFromOptions = (...fields) => {
    fields.forEach(field => {
      if (typeof field === 'string') {
        const optionsField = this.options[field] || []

        this.value = {...this.value, [field]: this.value[field] || optionsField[0] || [] }
      } else {
        this.value = {...this.value,
          [field.name]: this.value[field.name] || field.select(this.options[field.name][0]) }
      }
    })
  }
}

export default BaseForm
