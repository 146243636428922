import { lifecycle } from 'recompose'

const resetStore = store => {
  store.reset()
}

const withTurbolinksReload = ({ store, action = resetStore, event = 'turbolinks:before-cache' }) => {
  const bindAction = () => {
    action(store)
  }

  return lifecycle({
    componentDidMount() {
      document.addEventListener(event, bindAction)
    },

    componentWillUnmount() {
      document.removeEventListener(event, bindAction)
    }
  })
}

export default withTurbolinksReload
