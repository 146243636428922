/* eslint-disable jsx-a11y/no-autofocus */

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Close } from '../../../components/Svg'
import { noop } from '../../../lib/utils/common'

const RoundCloseButton = ({
  onClick,
  className,
  autoFocus = null,
  onKeyDown = noop,
  closeButtonRef = null,
  Icon = Close
}) => (
  <button
    type='button'
    autoFocus={autoFocus}
    className={cn('RoundCloseButton', className)}
    onClick={onClick}
    onKeyDown={onKeyDown}
    ref={closeButtonRef}
  >
    <Icon className='RoundCloseButton-closeIcon'/>
  </button>
)

RoundCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.func,
  className: PropTypes.string,
  closeButtonRef: PropTypes.object,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool
}

export default RoundCloseButton
