import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent, renderNothing } from 'recompose'
import cn from 'classnames'
import * as R from 'ramda'
import { isPresent } from '../../../../lib/utils/collection'
import Option from './Option'
import { withScrollToActiveOption } from '../../../Hocs'
import OptionScroller from '../Shared/OptionScroller'

const ActiveOptions = props => {
  const {
    selectedOptionsGroup, onSelect, onMouseEnter, onMouseLeave,
    optionTemplate, optionIdentity, highlightedOption,
    selectedOptions, options, matchText, setActiveOptionRef,
    setOptionsRef, wrapperOptionsComponent, optionLabel, selectedOptionsWithParents, onMouseMove
  } = props

  const unselectedOptions = options.filter(o => (
    !selectedOptionsGroup.includes(optionIdentity(o))
  ))

  const WrapperOptionsComponent = wrapperOptionsComponent
  const isSearchActive = !!matchText
  const rootAreas = R.filter(R.pathEq(['parent', 'id'], null), options)
  const selectedRootAreas = R.filter(o => !o.parent.id, selectedOptionsWithParents)

  const renderOption = (o, listType) => (
    <Option
      id={optionIdentity(o)}
      key={optionIdentity(o)}
      option={o}
      optionTemplate={optionTemplate}
      optionLabel={optionLabel}
      optionIdentity={optionIdentity}
      onSelect={onSelect}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isHighlighted={highlightedOption === o}
      isActive={selectedOptions.includes(optionIdentity(o))}
      isSelectedGroup={true}
      activeRef={setActiveOptionRef}
      matchText={matchText}
      listType={listType}
    />
  )

  const renderOptionsTree = (collection, isCheckNode, listType) => node => {
    const hasChildren = isPresent(node.children)

    if (hasChildren) {
      const children = R.filter(n => R.includes(n.id, node.children))(collection)

      return (
        <div key={node.id} className='Multiselect-treeOption'>
          {renderOption(node, listType)}
          <div
            key={`${node.id}-nested`}
            aria-labelledby={node.id}
            role='group'
            className='Multiselect-treeOptions Multiselect-treeOptions--nested'
          >
            {children.map(renderOptionsTree(collection, isCheckNode, listType))}
          </div>
        </div>
      )
    }

    return (
      <div className='Multiselect-treeOption' key={node.id}>
        {renderOption(node, listType)}
      </div>
    )
  }

  const unselectedOptionNodes = (
    <div className='Multiselect-treeOptions'>
      {rootAreas.map(renderOptionsTree(options, true, 'general'))}
    </div>
  )

  const selectedOptionNodes = (
    <div id='Selected items' aria-label='Selected items' role='group'>
      {selectedRootAreas.map(renderOptionsTree(selectedOptionsWithParents, false, 'selected'))}
    </div>
  )

  return (
    <div
      className='Multiselect-options'
      role='presentation'
      // Fix for Safari(blur event is called for input when clicked on div scrollbar)
      onMouseDown={e => {
        e.preventDefault()
      }}
    >
      <WrapperOptionsComponent {...props}>
        <OptionScroller setOptionsRef={setOptionsRef} onMouseMove={onMouseMove}>

          {(!isSearchActive && isPresent(selectedOptionsWithParents)) && selectedOptionNodes}

          <div className={
            cn('Multiselect-borderOptionsGroup', {
              isPresent: (selectedOptionsWithParents.length > 0 && !isSearchActive)
            })}
          />

          {unselectedOptionNodes}

          <div className={
            cn('Multiselect-borderOptionsGroup', {
              isPresent: (selectedOptionsWithParents.length > 0 && unselectedOptions.length > 0 && isSearchActive)
            })}
          />

          {isSearchActive && selectedOptionNodes}
        </OptionScroller>
      </WrapperOptionsComponent>
    </div>
  )
}

ActiveOptions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptionsGroup: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  selectedOptionsWithParents: PropTypes.array.isRequired,
  highlightedOption: PropTypes.any,
  matchText: PropTypes.string,
  setActiveOptionRef: PropTypes.func.isRequired,
  setOptionsRef: PropTypes.func.isRequired,
  wrapperOptionsComponent: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired
}

ActiveOptions.defaultProps = {
  wrapperOptionsComponent: (({ children }) => children)
}

const Options = branch(
  ({ isActive }) => isActive,
  renderComponent(withScrollToActiveOption(ActiveOptions)),
  renderNothing
)

export default Options()
