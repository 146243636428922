import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({ clearRange, selectRange, goBack }) => (
  <div className='DateRangePicker-footer'>
    <div className='DateRangePicker-calendarAction'>
      <div className='Link Link--primary' onClick={clearRange}>
        Clear
      </div>
    </div>

    <div className='DateRangePicker-calendarAction'>
      <div className='Button Button--height35 Button--secondary' onClick={goBack}>
        Back
      </div>
    </div>

    <div className='DateRangePicker-calendarAction'>
      <div className='Button Button--height35  Button--primary' onClick={selectRange}>
        Select
      </div>
    </div>
  </div>
)

Footer.propTypes = {
  clearRange: PropTypes.func.isRequired,
  selectRange: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired
}

export default Footer
