/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */

import { observable, action, observe, makeObservable } from 'mobx'

class UIStateStore {
  @observable isSearchActive = false
  @observable isSearchStarted = false
  @observable isRecentViewsPresent = false
  @observable isRecentSearchesPresent = false

  constructor({ gaEvents }) {
    makeObservable(this)

    this.gaEvents = gaEvents
    this.setObservers()
  }

  @action('[UIStateStore] Toggle isSearchActive')
  toggleSearchActive = v => {
    this.isSearchActive = v
  }

  @action('[UIStateStore] Toggle isSearchStarted')
  toggleSearchStarted = v => {
    this.isSearchStarted = v
  }

  @action('[UIStateStore] Toggle isRecentViewsPresent')
  toggleRecentViewsPresent = v => {
    this.isRecentViewsPresent = v
  }

  @action('[UIStateStore] Toggle isRecentSearchesPresent')
  toggleRecentSearchesPresent = v => {
    this.isRecentSearchesPresent = v
  }

  setObservers = () => {
    observe(this, 'isSearchActive', ({ newValue }) => {
      if (newValue) {
        this.gaEvents.Common.searchActive()
      }
    })
  }
}

export default UIStateStore
