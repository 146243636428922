import React from 'react'
import PropTypes from 'prop-types'
import MomentPropTypes from 'react-moment-proptypes'
import { withProps } from 'recompose'
import moment from 'moment'
import { Select } from '../../../../components/Shared/Form'

const TIME_FORMAT = 'HH:mm'
const TIME_FORMAT_UI = 'h:mmA'

const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
  const timeValueIsEarlier = timeValue.diff(endLimit) < 0
  const timeValueIsLaterThanLastValue = lastValue
    ? lastValue.diff(timeValue) < 0
    : true

  return timeValueIsEarlier && timeValueIsLaterThanLastValue
}

const optionTemplate = format => value => (
  value.format(format)
)

const enhance = withProps(({ beginLimit, endLimit, step }) => {
  let timeValue = beginLimit
  let lastValue

  const options = []
  options.push(timeValue)

  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue
    timeValue = timeValue.clone().add(step, 'minutes')
    options.push(timeValue)
  }

  return { options }
})

const TimePicker = ({ id, name, value, defaultValue, onChange, options, format }) => (
  <Select
    id={id}
    options={options}
    optionIdentity={optionTemplate(format)}
    selectedOptionTemplate={optionTemplate(format)}
    optionTemplate={optionTemplate(format)}
    value={optionTemplate(format)(value || defaultValue)}
    onChange={onChange}
    name={name}
  />
)

TimePicker.propTypes = {
  id: PropTypes.string,
  value: MomentPropTypes.momentObj,
  defaultValue: MomentPropTypes.momentObj,
  onChange: PropTypes.func,
  name: PropTypes.string,
  beginLimit: MomentPropTypes.momentObj,
  endLimit: MomentPropTypes.momentObj,
  step: PropTypes.number,
  format: PropTypes.string,
  options: PropTypes.arrayOf(MomentPropTypes.momentObj).isRequired
}

TimePicker.defaultProps = {
  beginLimit: moment('12:00', TIME_FORMAT),
  endLimit: moment('23:59', TIME_FORMAT),
  step: 15,
  format: TIME_FORMAT_UI
}

export default enhance(TimePicker)
