/* eslint no-unused-expressions: 0 */

import React, { useRef, useEffect } from 'react'
import { watchKey } from '../../lib/utils/dom'
import KEY from '../../lib/constants/key'

const withKeyBindings = getKeyBindings => BaseComponent => {
  const WithKeyBindingsComponent = props => {
    const outerElRef = useRef(null)
    const focusOnOuterEl = () => {
      outerElRef.current && outerElRef.current.focus()
    }

    const handleKeyInput = e => {
      const keyBindings = getKeyBindings(props)

      if (e.key === KEY.TAB) {
        e.stopPropagation()
      }

      Object.keys(keyBindings).forEach(key => {
        watchKey(e, key, keyBindings[key])
      })
    }

    useEffect(() => {
      focusOnOuterEl()
    }, [])

    return (
      <div
        role='none'
        style={{ outline: 0, width: '100%', height: '100%' }}
        tabIndex='-1'
        onKeyDown={handleKeyInput}
        ref={outerElRef}
      >
        <BaseComponent {...props} />
      </div>
    )
  }

  return WithKeyBindingsComponent
}

export default withKeyBindings
