import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const OptionsWrapper = ({ children, actionName, actionHandler }) => (
  <Fragment>
    {children}

    <div className='Multiselect-actions' role='presentation'>
      <div className='Multiselect-action'>
        <button type='button'
          onClick={actionHandler}
          className='Button Button--secondary'
        >
          {actionName}
        </button>
      </div>
    </div>
  </Fragment>
)

OptionsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  actionName: PropTypes.string.isRequired,
  actionHandler: PropTypes.func.isRequired
}

export default OptionsWrapper
