import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer, PropTypes as MobxPropTypes } from 'mobx-react'
import cn from 'classnames'
import { useRegisterGlobalActions } from '../../shared_components/Hooks'

const ToastFlashMessages = ({ store }) => {
  const closeHandler = message => () => {
    store.removeFlashMessage(message)
  }

  const addFlashMessage = message => {
    store.addFlashMessage(message)
  }

  const removeFlashMessage = message => {
    store.removeFlashMessage(message)
  }

  useRegisterGlobalActions({
    context: { addFlashMessage, removeFlashMessage },
    key: 'FlashMessages',
    actions: ['addFlashMessage', 'removeFlashMessage']
  })

  useEffect(() => {
    global.dispatchEvent(new CustomEvent('toast-flash-messages:loaded'))
  }, [])

  return (
    <div className='ToastFlashMessages'>
      {store.messages.map((message, index) => (
        <div key={index} className='ToastFlashMessages-item' onClick={closeHandler(message)}>
          <div className='ToastFlashMessages-content'>
            <div
              className={cn('ToastFlashMessages-icon',
                {
                  'ToastFlashMessages-icon--success': message.type === 'success',
                  'ToastFlashMessages-icon--error': message.type === 'error'
                })}
            />
            <div className='ToastFlashMessages-message'>
              <span dangerouslySetInnerHTML={{ __html: message.text }}/>
            </div>
            {(message.linkUrl && message.linkText) &&
              <a className='Button Button--link' href={message.linkUrl}>{message.linkText}</a>
            }
          </div>
        </div>
      ))}
    </div>
  )
}

ToastFlashMessages.propTypes = {
  store: PropTypes.shape({
    messages: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      linkUrl: PropTypes.string,
      linkText: PropTypes.string
    })),
    removeFlashMessage: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired
  }).isRequired
}

export default observer(ToastFlashMessages)
