import React from 'react'
import PropTypes from 'prop-types'
import Option from './Option'

const OptionsWithoutEllipsis = ({
  options, optionIdentity, selectedOptionTemplate,
  onDestroy, markForDestroyIndex
}) => (
  options.map((option, i) => (
    <Option
      key={optionIdentity(option)}
      option={option}
      onDestroy={onDestroy}
      optionIdentity={optionIdentity}
      selectedOptionTemplate={selectedOptionTemplate}
      isMarked={markForDestroyIndex === i}
    />
  ))
)

OptionsWithoutEllipsis.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionIdentity: PropTypes.func.isRequired,
  selectedOptionTemplate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  markForDestroyIndex: PropTypes.number
}

export default OptionsWithoutEllipsis
