import { isEmpty } from '../utils/collection'
import mapDataToServer from './mapDataToServer'

const arrayValue = ({key}) =>(schemaKey, values) => {
  if(values[key] && !isEmpty(values[key])) {
    return { [schemaKey]: values[key] }
  }
  return {}
}

const singleValue = ({key}) => (schemaKey, values) => {
  if(values[key]) {
    return { [schemaKey]: values[key] }
  }
  return {}
}

const priceValue = ({key, index}) => (schemaKey, values, defaultValues) => {
  if(values[key]) {
    if(values[key][index] !== defaultValues[schemaKey]) {
      return { [schemaKey]: values[key][index] }
    }
  }
  return {}
}

const simpleSearchValuesSchema = {
  priceFrom: priceValue({ key: 'prices', index: 0 }),
  priceTo: priceValue({ key: 'prices', index: 1 }),
  townNames: arrayValue({ key: 'towns' }),
  beds: singleValue({ key: 'beds' }),
  pricePeriod: singleValue({ key: 'pricePeriod'}),
  year: singleValue({ key: 'year'})
}

export const mapSimpleSearchDataToServer = mapDataToServer(simpleSearchValuesSchema)

export const toPolygonName = value => (
  value.replace(/\s/g, '').toLowerCase()
)
