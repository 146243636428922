import React from 'react'
import PropTypes from 'prop-types'
import { getId } from '../../../../lib/utils/selectors'

const TextField = ({
  id,
  onChange,
  onKeyDown,
  onKeyPress,
  text,
  placeholder,
  setInputRef,
  isActive,
  activeOption,
  onClick
}) => (
  <div className='Multiselect-searchField'>
    <input
      id={id}
      className='Multiselect-editable'
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      onClick={onClick}
      placeholder={placeholder()}
      autoComplete='off'
      ref={setInputRef}
      value={text}
      role='combobox'
      aria-controls='combo-listbox'
      aria-owns='combo-listbox'
      aria-expanded={isActive}
      aria-activedescendant={getId(activeOption)}
      aria-autocomplete='list'
    />
  </div>
)

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.any,
  setInputRef: PropTypes.object.isRequired,
  activeOption: PropTypes.object.isRequired
}

export default TextField
