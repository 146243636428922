import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import ClickOutHandler from 'react-onclickout'
import classNames from 'classnames'
import * as R from 'ramda'
import { removeFromArray } from '../../../../lib/utils/collection'
import { onKey, watchKey } from '../../../../lib/utils/dom'
import { isTouchScreen } from '../../../../lib/utils/viewport'
import { noop } from '../../../../lib/utils/common'
import { getId, getName, identity } from '../../../../lib/utils/selectors'
import Options from './Options'
import SearchField from './SearchField'
import SelectedOptions from './SelectedOptions'

const sortByMatch = ({ searchText }, { filterIdentity }) => (a, b) => {
  const optionA = filterIdentity(a).toLocaleLowerCase().match(searchText.toLowerCase()).index
  const optionB = filterIdentity(b).toLocaleLowerCase().match(searchText.toLowerCase()).index

  return optionA - optionB
}

class SearchableCheckySelect extends Component {
  _isMounted = false

  static propTypes = {
    options: PropTypes.array,
    value: MobxPropTypes.arrayOrObservableArray,
    onChange: PropTypes.func,
    onSearchTextChange: PropTypes.func,
    onActiveChanged: PropTypes.func,
    optionIdentity: PropTypes.func,
    filterIdentity: PropTypes.func,
    filterWithSearchText: PropTypes.func,
    selectedOptionTemplate: PropTypes.func,
    selectedOptionSort: PropTypes.func,
    selectedOptionFilter: PropTypes.func,
    optionTemplate: PropTypes.func,
    optionLabel: PropTypes.func,
    height: PropTypes.number,
    tabindex: PropTypes.number,
    wrapperComponent: PropTypes.func,
    wrapperOptionsComponent: PropTypes.func,
    optionsComponent: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    applyResultsHandler: PropTypes.func,
    wrapperOptionsComponentClassName: PropTypes.string,
    countResults: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    scrollToTopOnActive: PropTypes.func,
    setFocusableRef: PropTypes.object
  }

  static defaultProps = {
    options: [],
    value: [],
    optionIdentity: getId,
    filterIdentity: getName,
    filterWithSearchText: (state, props) => options => (
      options.filter(o => (
        props.filterIdentity(o)
          .toLocaleLowerCase()
          .includes(state.searchText.toLowerCase())
      )).sort(sortByMatch(state, props))
    ),
    selectedOptionTemplate: getName,
    selectedOptionSort: noop,
    selectedOptionFilter: identity,
    optionTemplate: ({ labelText }) => labelText,
    optionLabel: getName,
    onChange: noop,
    onSearchTextChange: noop,
    onActiveChanged: noop,
    height: 53,
    tabindex: 0,
    wrapperComponent: (({ children }) => children),
    applyResultsHandler: noop,
    wrapperOptionsComponentClassName: '',
    countResults: false,
    scrollToTopOnActive: noop
  }

  state = {
    ...this.getInitialState(this.props),
    isActive: false,
    selectedIndex: 0,
    searchText: '',
    selectedOptionsGroup: this.props.value
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(_, prevState) {
    if (prevState.isActive !== this.state.isActive) {
      this.props.onActiveChanged(this.state.isActive)
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState(() => this.getInitialState(props))
  }

  getInitialState({ value }) {
    return {
      value
    }
  }

  setActiveOption = option => {
    const { selectedOptionsGroup, searchText } = this.state
    const { options, optionIdentity, selectedOptionSort } = this.props
    const isSearchActive = !!searchText

    let isInSelectedGroup = true

    let index = -1

    const primarySelectedOptions = options.filter(o => (
      selectedOptionsGroup.includes(optionIdentity(o))
    )).sort(selectedOptionSort)

    index = primarySelectedOptions.findIndex(o => o === option)

    if (index === -1) {
      index = this.availableOptions.findIndex(o => o === option)
      isInSelectedGroup = false
    }

    if (isInSelectedGroup && isSearchActive) {
      index = index + this.availableOptions.length
    } else if (!isInSelectedGroup && !isSearchActive) {
      index = index + selectedOptionsGroup.length
    }

    this.setState(() => ({
      selectedIndex: index
    }))
  }

  unSetActiveOption = () => {
    this.setState(() => ({
      selectedIndex: -1
    }))
  }

  nextActiveOption = () => {
    const { selectedOptionsGroup } = this.state

    const max = this.availableOptions.length + selectedOptionsGroup.length - 1

    this.setState(state => ({
      selectedIndex: Math.min(state.selectedIndex + 1, max)
    }))
  }

  prevActiveOption = () => {
    const min = 0

    this.setState(state => ({
      selectedIndex: Math.max(state.selectedIndex - 1, min)
    }))
  }

  applySelectedOption = () => {
    const option = this.highlightedOption

    if (option) {
      this.onSelect(option)
    }
  }

  get selectedOptions() {
    const { options, optionIdentity, selectedOptionSort } = this.props
    const { value } = this.state
    const fromRegularOptions = options.filter(o => value.includes(optionIdentity(o)))

    return (
      R.uniqBy(optionIdentity, fromRegularOptions)
        .sort(selectedOptionSort)
    )
  }

  get availableOptions() {
    const { options, filterWithSearchText, optionIdentity } = this.props
    const { searchText, selectedOptionsGroup } = this.state

    let result = options.filter(o => !selectedOptionsGroup.includes(optionIdentity(o)))

    if (searchText !== '') {
      result = filterWithSearchText(this.state, this.props)(result)
    }

    return result
  }

  get highlightedOption() {
    const { searchText } = this.state
    const isSearchActive = !!searchText

    return isSearchActive ? this.optionInAvailableFirst : this.optionInSelectedFirst
  }

  get optionInSelectedFirst() {
    const { selectedIndex, selectedOptionsGroup } = this.state
    const { options, optionIdentity, selectedOptionSort } = this.props

    if (selectedIndex < selectedOptionsGroup.length) {
      const primarySelectedOptions = options.filter(o => (
        selectedOptionsGroup.includes(optionIdentity(o))
      )).sort(selectedOptionSort)
      return primarySelectedOptions[selectedIndex]
    }

    const indexOffset = selectedOptionsGroup.length
    const index = selectedIndex - indexOffset

    return this.availableOptions[index]
  }

  get optionInAvailableFirst() {
    const { selectedIndex, selectedOptionsGroup } = this.state
    const { options, optionIdentity, selectedOptionSort } = this.props

    if (selectedIndex < this.availableOptions.length) {
      return this.availableOptions[selectedIndex]
    }

    const indexOffset = this.availableOptions.length
    const index = selectedIndex - indexOffset
    const primarySelectedOptions = options.filter(o => (
      selectedOptionsGroup.includes(optionIdentity(o))
    )).sort(selectedOptionSort)

    return primarySelectedOptions[index]
  }

  get style() {
    const { height } = this.props

    return { minHeight: height }
  }

  get hasSelected() {
    return this.selectedOptions.length > 0
  }

  get hasOptions() {
    return this.availableOptions.length > 0 || this.state.selectedOptionsGroup.length > 0
  }

  onKeyDown = e => {
    onKey(e, 'ArrowDown', this.nextActiveOption)
    onKey(e, 'ArrowUp', this.prevActiveOption)
    onKey(e, 'Escape', this.onDeactivateOptions)
  }

  onKeyPress = e => {
    watchKey(e, 'Enter', this.onEnterPress)
  }

  onEnterPress = e => {
    if (!isTouchScreen() || !this.hasSelected) {
      e.preventDefault()
      e.stopPropagation()
      this.applySelectedOption()
    }
  }

  onSelect = option => {
    const { onChange, optionIdentity } = this.props

    this.setActiveOption(option)

    this.setState(
      state => {
        const id = optionIdentity(option)
        let value

        if (state.value.includes(id)) {
          value = removeFromArray(state.value, id)

          if (option.parent) {
            value = removeFromArray(value, optionIdentity(option.parent))
          }
        } else {
          value = [...state.value, id]

          if (option.children) {
            const childIds = option.children.map(optionIdentity)
            value = R.uniq([...value, ...childIds])
          }
        }

        return {
          value
        }
      },
      () => {
        onChange(this.state)
      }
    )
  }

  onClearAll = () => {
    this.setState({ value: [] }, () => { this.props.onChange(this.state) })
  }

  onActivateOptions = () => {
    this.setState(() => ({ isActive: true }), () => {
      this.props.onActiveChanged(this.state.isActive)
      this.props.scrollToTopOnActive()
    })
  }

  onDeactivateOptions = e => {
    if (!e.target || !this._isMounted) {
      return
    }

    if (!(e.path &&
          e.path.some(n => n.classList && n.classList.contains('Modal')))) {
      this.setState(state => ({
        isActive: false,
        selectedOptionsGroup: state.value,
        selectedIndex: 0,
        searchText: ''
      }))
    }
  }

  onSearch = ({ target: { value } }) => {
    this.setState(() => ({ searchText: value }), () => {
      this.props.onSearchTextChange(value)
    })
  }

  render() {
    const {
      optionIdentity, selectedOptionTemplate,
      optionTemplate, optionLabel, tabindex, wrapperComponent, wrapperOptionsComponent,
      className, placeholder, options, selectedOptionSort, selectedOptionFilter,
      applyResultsHandler, wrapperOptionsComponentClassName, countResults, setFocusableRef
    } = this.props
    const { isActive, searchText } = this.state
    const hasSelected = this.hasSelected
    const WrapperComponent = wrapperComponent
    const cn = classNames('Multiselect', className, { isActive, hasSelected })

    return (
      <ClickOutHandler onClickOut={this.onDeactivateOptions}>
        <div
          className={cn}
          style={this.style}
          onClick={this.onActivateOptions}
        >
          <WrapperComponent {...this.props}>
            <div className='Multiselect-content'>
              <div className='Multiselect-selectedOptions'>
                <SelectedOptions
                  placeholder={placeholder}
                  isActive={isActive}
                  hasSelected={this.hasSelected}
                  options={selectedOptionFilter(this.selectedOptions)}
                  selectedOptionTemplate={selectedOptionTemplate}
                />
                <SearchField
                  isActive={isActive}
                  placeholder={placeholder}
                  onChange={this.onSearch}
                  onBlur={this.onDeactivateOptions}
                  onKeyDown={this.onKeyDown}
                  onKeyPress={this.onKeyPress}
                  text={searchText}
                />
              </div>

              <div
                className='Multiselect-focusDetector'
                tabIndex={isActive ? null : tabindex}
                onFocus={this.onActivateOptions}
                ref={setFocusableRef}
              />

              <Options
                isActive={isActive && this.hasOptions}
                onClearAll={this.onClearAll}
                selectedOptions={this.state.value}
                selectedOptionsGroup={this.state.selectedOptionsGroup}
                selectedOptionSort={selectedOptionSort}
                highlightedOption={this.highlightedOption}
                options={this.availableOptions}
                rawOptions={options}
                optionTemplate={optionTemplate}
                optionLabel={optionLabel}
                optionIdentity={optionIdentity}
                onSelect={this.onSelect}
                onMouseEnter={this.setActiveOption}
                onMouseLeave={this.unSetActiveOption}
                matchText={searchText}
                wrapperOptionsComponent={wrapperOptionsComponent}
                wrapperOptionsComponentClassName={wrapperOptionsComponentClassName}
                cancelHandler={this.onDeactivateOptions}
                applyResultsHandler={applyResultsHandler}
                countResults={countResults}
              />
            </div>
          </WrapperComponent>
        </div>
      </ClickOutHandler>
    )
  }
}

export default SearchableCheckySelect
