/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */
/* eslint no-console: 0 */

import { observable, action, makeObservable, toJS } from 'mobx'
import { lens } from 'lorgnette'
import { fromServerToClient } from '../../../lib/utils/collection'
import { listingCaptchaKey } from '../../../lib/config/recaptcha'

const activeItemLens = listing => lens.firstOf(l => l.id === listing.id)

class RecentViewsStore {
  @observable collection = []
  isAllowedRequest = true

  constructor({ transport, gaEvents, eventBus }) {
    makeObservable(this)

    this.transport = transport
    this.gaEvents = gaEvents

    eventBus.addEventListener('transport.Listing.updateSavedStatusTask', this.updateListingSaveStatus)
    eventBus.addEventListener('transport.Listing.updateSavedStatus', this.updateListingSaveStatus)
  }

  @action('[RecentViewsStore] update listing save status')
  updateListingSaveStatus = ({ detail: { data } }) => {
    const listing = fromServerToClient(data)

    const activeItem = toJS(this.collection.find(item => item.id === listing.id))
    const links = activeItem ? activeItem.links : listing.links

    this.activeItem = {
      ...activeItem,
      links: { ...links, saveAction: listing.links.saveAction },
      saved: listing.saved
    }

    this.collection = activeItemLens(data).set(this.collection, this.activeItem)

    const eventLabel = listing.saved ? 'save' : 'unsave'
    this.gaEvents.Hdp.saveListing(eventLabel)
  }

  fetchInitialData() {
    this.transport.RecentViews.get().then(
      action('[RecentViewsStore] fetch initial data', response => {
        this.collection = fromServerToClient(response)
      })
    )
  }

  updateSaveStatus(listing) {
    const { links: { saveAction: { url, method } } } = listing

    if (this.isAllowedRequest) {
      this.isAllowedRequest = false
      this.transport.Listing.updateSavedStatus({ url, method, captchaKey: listingCaptchaKey(listing) })
        .then(() => { this.isAllowedRequest = true })
        .catch(error => { console.log(error) })
    }
  }
}

export default RecentViewsStore
