import { withHandlers } from 'recompose'

export default withHandlers(() => {
  let captchaRef

  return {
    setCaptchaRef: () => el => {
      captchaRef = el
    },
    resetCaptcha: () => () => {
      if (captchaRef) {
        captchaRef.reset()
      }
    }
  }
})
