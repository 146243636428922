import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { toPolygonName } from '../../../../../lib/dataMappings/search'
import polygons from '../../../../../lib/config/polygons.json'
import Map from './Map'
import { isPresent } from '../../../../../lib/utils/collection'
import { onKey } from '../../../../../lib/utils/dom'
import KEY from '../../../../../lib/constants/key'
import Options from './Options'

const ZOOM_HOVER_TIME = 500

const AreaMapSelector = ({
  rawOptions,
  highlightedOption,
  onMouseEnter,
  onMouseLeave,
  selectedOptions,
  optionIdentity,
  optionTemplate,
  optionLabel,
  onSelect,
  areaMapSelectorActions
}) => {
  const [hoveredOption, setHoveredOption] = useState(null)
  const [localHighlightedOption, setLocalHighlightedOption] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const locationMap = useRef(null)
  const timerRef = useRef(null)
  const keyboardNavFlagRef = useRef(false)
  const optionScrollerRef = useRef(null)

  const registerRef = mapInstancee => {
    locationMap.current = mapInstancee
  }

  // We need to change `highlightedOption` to make sure that SearchField gets updated
  // and restores focus and as a result modal won't close
  useEffect(() => onMouseEnter(rawOptions[0]), [])

  const setOptionsScrollerRef = el => {
    optionScrollerRef.current = el
  }

  const setKeyNavFlag = value => {
    keyboardNavFlagRef.current = value
  }

  const isKeyNavFlag = () => keyboardNavFlagRef.current

  const mapFitBounds = bounds => {
    locationMap.current.fitBounds(bounds)
    locationMap.current.panToBounds(bounds)
  }

  const mapFitLocation = option => {
    const polygon = polygons[toPolygonName(optionLabel(option))]

    if (polygon) {
      const bounds = new global.google.maps.LatLngBounds()

      polygon.points.forEach(({ lat, lng }) => {
        bounds.extend(new global.google.maps.LatLng(lat, lng))
      })

      mapFitBounds(bounds)
    }
  }

  const rootAreas = R.filter(R.pathEq(['parent', 'id'], null), rawOptions)

  const delayedMapFitLocation = option => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      mapFitLocation(option)
    }, ZOOM_HOVER_TIME)
  }

  const handleMouseEnter = (option, zoomToLocation = true) => {
    if (zoomToLocation) {
      delayedMapFitLocation(option)
    }
    setHoveredOption(option)
    setLocalHighlightedOption(null)
    onMouseEnter(option)
    if (!isKeyNavFlag()) {
      const index = rawOptions.indexOf(option)
      setSelectedIndex(index)
    }
  }

  const handleMouseMove = () => {
    setKeyNavFlag(false)
  }

  const handleMouseLeave = option => {
    onMouseLeave(option)
  }

  const isHighlighted = option => {
    return [rawOptions[selectedIndex], highlightedOption ].includes(option)
  }

  const nextActiveOption = () => {
    const index = selectedIndex === rawOptions.length - 1 ? rawOptions.length - 1 : selectedIndex + 1
    const option = rawOptions[index]
    delayedMapFitLocation(option)

    setSelectedIndex(index)
    setHoveredOption(rawOptions[index])
    setLocalHighlightedOption(rawOptions[index])
  }

  const prevActiveOption = () => {
    const index = selectedIndex <= 0 ? 0 : selectedIndex - 1
    const option = rawOptions[index]
    delayedMapFitLocation(option)

    setSelectedIndex(index)
    setHoveredOption(rawOptions[index])
    setLocalHighlightedOption(rawOptions[index])
  }

  const onDeactivateOptions = e => {
    if (!e.target) {
      return
    }
    setSelectedIndex(-1)
    setHoveredOption(null)
    setLocalHighlightedOption(null)
  }

  const applySelectedOption = () => {
    if (isPresent(hoveredOption)) {
      onSelect(hoveredOption)
    }
  }

  const scrollToTop = () => {
    optionScrollerRef.current.scrollTo(0, 0)
    setLocalHighlightedOption(null)
    setSelectedIndex(0)
  }

  const scrollToBottom = () => {
    optionScrollerRef.current.scrollTop = optionScrollerRef.current.scrollHeight
    setLocalHighlightedOption(null)
    setSelectedIndex(rawOptions.length - 1)
  }

  const handleKeyDown = e => {
    setKeyNavFlag(true)
    e.persist()

    onKey(e, KEY.DOWN, nextActiveOption)
    onKey(e, KEY.UP, prevActiveOption)
    onKey(e, KEY.RETURN, onDeactivateOptions)
    onKey(e, KEY.SPACE, applySelectedOption)
    onKey(e, KEY.HOME, scrollToTop)
    onKey(e, KEY.END, scrollToBottom)
  }

  return (
    <div className='AreaMapSelector'>
      <div className='AreaMapSelector-content'>
        <div className='AreaMapSelector-optionsContent'>
          <Options
            rawOptions={rawOptions}
            onKeyDown={handleKeyDown}
            rootAreas={rootAreas}
            optionLabel={optionLabel}
            onSelect={onSelect}
            selectedOptions={selectedOptions}
            isHighlighted={isHighlighted}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            optionIdentity={optionIdentity}
            optionTemplate={optionTemplate}
            highlightedOption={localHighlightedOption}
            hoveredOption={hoveredOption}
            onMouseMove={handleMouseMove}
            setOptionsScrollerRef={setOptionsScrollerRef}
          />
          { areaMapSelectorActions &&
          <div className='AreaMapSelector-actionsContent'>
            { areaMapSelectorActions() }
          </div>
          }
        </div>
        <div className='AreaMapSelector-map'>
          <Map
            registerRef={registerRef}
            selectedOptions={selectedOptions}
            onSelect={onSelect}
            rawOptions={rawOptions}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            optionLabel={optionLabel}
            optionIdentity={optionIdentity}
            highlightedOption={highlightedOption || hoveredOption}
          />
        </div>
      </div>
    </div>
  )}

AreaMapSelector.propTypes = {
  rawOptions: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  highlightedOption: PropTypes.object,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  areaMapSelectorActions: PropTypes.func
}

export default AreaMapSelector
