import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent, renderNothing, withHandlers, lifecycle, compose } from 'recompose'

const enhance = compose(
  withHandlers(() => {
    let inputRef

    return {
      setInputRef: () => el => {
        inputRef = el
      },
      setInputFocus: () => () => {
        inputRef.focus()
      },
      onRealBlur: ({ onBlur }) => e => {
        e.persist()
        setTimeout(() => {
          if (global.document.activeElement !== inputRef) {
            onBlur(e)
          }
        }, 200)
      }
    }
  }),
  lifecycle({
    componentDidUpdate() {
      this.props.setInputFocus()
    }
  })
)

const TextField = ({onChange, onKeyDown, onKeyPress, text, placeholder, setInputRef, onRealBlur}) => (
  <div className='Multiselect-searchField'>
    <input
      className='Multiselect-editable'
      onChange={onChange}
      onBlur={onRealBlur}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      autoFocus={true}
      ref={setInputRef}
      value={text}
    />
  </div>
)

const SearchField = branch(
  ({ isActive }) => isActive,
  renderComponent(enhance(TextField)),
  renderNothing
)

TextField.propTypes = {
  isActive: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onRealBlur: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  setInputRef: PropTypes.func.isRequired
}

export default SearchField()
