import React from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from '../../../../lib/propTypes'
import { noop } from '../../../../lib/utils/common'

const OptionScroller = ({ setOptionsRef, children, onMouseMove = noop }) => (
  <div style={{ overflowY: 'auto', transform: 'translate(0, 0)' }}
    ref={setOptionsRef}
    role='presentation'
    onClick={e => { e.preventDefault() }}
    onKeyDown={noop}
    onMouseMove={onMouseMove}
  >
    {children && children}
  </div>
)

OptionScroller.propTypes = {
  setOptionsRef: PropTypes.func.isRequired,
  children: childrenPropType,
  onMouseMove: PropTypes.func
}

export default OptionScroller
