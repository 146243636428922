import React from 'react'
import PropTypes from 'prop-types'
import Placeholder from './Placeholder'
import SelectedOptions from './SelectedOptions'

const WrapperSelectedOptions = props => {
  const { placeholder, isActive, hasSelected } = props

  if (placeholder && !isActive && !hasSelected) {
    return (
      <Placeholder>{ placeholder }</Placeholder>
    )
  }

  return <SelectedOptions {...props} />
}

WrapperSelectedOptions.propTypes = {
  placeholder: PropTypes.string,
  isActive: PropTypes.bool,
  hasSelected: PropTypes.bool
}

export default WrapperSelectedOptions
