import React from 'react'
import PropTypes from 'prop-types'
import Placeholder from './Placeholder'

const SelectedOption = ({ placeholder, isActive, hasSelected, value, optionTemplate }) => {
  if (placeholder && !isActive && !hasSelected) {
    return (
      <Placeholder>{ placeholder }</Placeholder>
    )
  } else if (hasSelected && !isActive) {
    return (
      <div className='Multiselect-truncatedSelectedOptions'>
        { optionTemplate(value) }
      </div>
    )
  }

  return null
}

SelectedOption.propTypes = {
  placeholder: PropTypes.string,
  isActive: PropTypes.bool,
  hasSelected: PropTypes.bool,
  value: PropTypes.object,
  optionTemplate: PropTypes.func.isRequired
}

export default SelectedOption
