import { observable, action, computed, toJS, makeObservable } from 'mobx'
import { debounce, memoize, clearCache, stripHTMLTags } from '../../../lib/utils/common'
import { isEmptyObject } from '../../../lib/utils/common'
import { fromServerToClient, isEmpty, iterator } from '../../../lib/utils/collection'

export const SERVER_SYNC_DELAY = 300
export const PLACEHOLDER_REGEX = /<em>(.+)<\/em>(.+)/
export const PLACEHOLDER_FULL_MATCH_REGEX = /<em>(.+)<\/em>/

class SearchResultsStore {
  @observable currentSearch = ''
  @observable suggestion = ''
  @observable suggestionUrl = null
  @observable results = {}

  constructor({ transport, location }) {
    makeObservable(this)

    this.fetchSearch = memoize(transport.QuickSearch.get)
    this.redirectTo = location.redirect
  }

  get isFollowingSuggestion() {
    return !!this.suggestion &&
      !!this.currentSearch &&
      this.currentSearch.slice(-1).toLowerCase() === this.suggestion[0].toLowerCase()
  }

  isReversingSuggestion(previousSearch) {
    return !!this.suggestion &&
      !!this.currentSearch &&
      !!previousSearch &&
      this.currentSearch.length < previousSearch.length
  }

  @action('[SearchResultsStore] Sync with server')
  syncWithServer = debounce(() => {
    if (this.currentSearch.trim().length > 1) {
      this.fetchSearch(this.currentSearch)
        .then(fromServerToClient)
        .then(res => {
          this.updateSuggestion(res)
          this.updateResults(res)
        })
    }
  }, SERVER_SYNC_DELAY)

  predictSuggestion(previousSearch) {
    if (this.isReversingSuggestion(previousSearch)) {
      this.suggestion = previousSearch.slice(-1) + this.suggestion
    } else if (this.isFollowingSuggestion) {
      this.suggestion = this.suggestion.slice(1)
    } else {
      this.suggestion = ''
    }
  }

  @computed get isResultsPresent() {
    return !isEmptyObject(this.results) && (
      this.results.sales.total !== 0 ||
      this.results.rentals.total !== 0 ||
      this.results.towns.total !== 0 ||
      this.results.areas.total !== 0 ||
      this.results.internetNumbers.total !== 0
    )
  }

  @action('[SearchResultsStore] Update suggestion')
  updateSuggestion = ({ placeholder }) => {
    if (placeholder) {
      const match = placeholder.string.match(PLACEHOLDER_REGEX)
      const fullMatch = placeholder.string.match(PLACEHOLDER_FULL_MATCH_REGEX)

      if (match) {
        const [_, serverSearchString, serverSuggestionString] = match

        if (serverSearchString.toLowerCase() === this.currentSearch.toLowerCase()) {
          this.suggestion = stripHTMLTags(serverSuggestionString)
          this.suggestionUrl = placeholder.url
        }
      } else if (fullMatch) {
        this.suggestion = ''
        this.suggestionUrl = placeholder.url
      } else {
        this.suggestion = ''
        this.suggestionUrl = null
      }
    } else {
      this.suggestion = ''
      this.suggestionUrl = null
    }
  }

  @action('[SearchResultsStore] Update results')
  updateResults = res => { this.results = res }

  @action('[SearchResultsStore] Update current search')
  updateCurrentSearch = value => {
    const previousSearch = this.currentSearch
    this.currentSearch = value

    if (this.currentSearch.length > 1) {
      this.predictSuggestion(previousSearch)
    } else {
      this.suggestion = ''
      this.results = {}
    }
  }

  fetchSearchResults = value => {
    this.updateCurrentSearch(value)
    this.syncWithServer()
  }

  @action('[SearchResultsStore] Reset store')
  reset = () => {
    this.currentSearch = ''
    this.suggestion = ''
    this.results = {}
  }

  clearRequestCache = () => {
    clearCache(this.fetchSearch)
  }

  @action('[SearchResultsStore] Apply suggestion')
  applySuggestion = () => {
    if (this.suggestion.length > 0) {
      this.updateCurrentSearch(this.currentSearch + this.suggestion)
      this.suggestion = ''
    }
    if (this.suggestionUrl) {
      this.redirectTo(this.suggestionUrl)
    }
  }

  @computed
  get activeItem() {
    const hasActiveItem = (this.currentSearch && !this.suggestion) && this.isResultsPresent

    if (!hasActiveItem) {
      return ''
    }
    return this.currentSearch
  }

  @computed
  get suggestions() {
    const collection = toJS(this.results)
    const isEmptyResults = isEmpty(collection)

    if (isEmptyResults) {
      return []
    }

    const results = [
      ...collection.towns.suggestions,
      ...collection.sales.suggestions,
      ...collection.rentals.suggestions,
      ...collection.areas.suggestions,
      ...collection.internetNumbers.suggestions
    ]

    return iterator(results, -1)
  }
}

export default SearchResultsStore
