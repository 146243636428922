import React from 'react'
import { withProps, compose } from 'recompose'
import PropTypes from 'prop-types'

const MIN_HEIGHT = 10
const MAX_HEIGHT = 70
const MAX_BAR_HEIGHT = MAX_HEIGHT - MIN_HEIGHT

const intervalsCount = priceExtremum => Object.keys(priceExtremum.counts).length - 1
const maxValue = priceExtremum => Math.max(...Object.values(priceExtremum.counts))
const normalHeight = (priceExtremum, current) => (
  priceExtremum.counts[current] ?
    Math.round((priceExtremum.counts[current] * MAX_BAR_HEIGHT) / maxValue(priceExtremum) + MIN_HEIGHT) :
    MIN_HEIGHT
)

const enhance = compose(
  withProps(({priceExtremum, children, style: { left }}) => ({
    width: left === '100%' ? '0' : `${100 / intervalsCount(priceExtremum)}%`,
    height: normalHeight(priceExtremum, children)
  }))
)

const PitComponent = ({ style, width, height }) => (
  <div
    style={{
      ...style,
      background: 'rgba(239,174,94,0.4)',
      width: width,
      height: height,
      bottom: 0
    }}
  />
)

PitComponent.propTypes = {
  style: PropTypes.object,
  children: PropTypes.number,
  priceExtremum: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired
}

PitComponent.defaultProps = {
  style: null,
  children: null
}

export default enhance(PitComponent)
