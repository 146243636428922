import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import * as R from 'ramda'
import classNames from 'classnames'
import { noop } from '../../../../lib/utils/common'
import { getName, getId } from '../../../../lib/utils/selectors'
import { isPresent, iterator } from '../../../../lib/utils/collection'
import { onKey, watchKey } from '../../../../lib/utils/dom'
import { sanitizeID } from '../../../../lib/utils/string'
import { numberOrString } from '../../../../lib/propTypes'
import KEY from '../../../../lib/constants/key'
import ClickOutHandler from 'react-onclickout'
import { Viewport } from '../../Viewport'
import Options from './Options'
import SelectedOption from './SelectedOption'
import HiddenInput from '../HiddenInput'

const Select = props => {
  const {
    // HTML attrs
    id,
    name,
    className,
    label,
    placeholder,

    // Values
    value,
    forcedSelectedOption,
    options,

    // Selectors/templates
    optionIdentity,
    selectedOptionTemplate,
    optionTemplate,

    // Handlers
    onChange,

    // Callback for passing options state to a parent
    onActiveChanged,

    // Flags
    isDisabled,
    forceDesktopBehavior,
    withScrollIntoView
  } = props

  const controllerRef = useRef(null)

  const [isActive, setActive] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [highlightedOption, setHighlightedOption] = useState(null)
  const [valueStored, setValueStored] = useState(null)

  const [focusableElements, setFocusableElements] = useState([])
  const [prevState, setPrevState] = useState({
    value,
    options,
    forcedSelectedOption
  })

  const setState = () => {
    const currentOption = forcedSelectedOption || options.find(o => optionIdentity(o) === value)

    setSelectedOption(currentOption)
    setHighlightedOption(currentOption)
    setValueStored(value)
    setActive(isActive || false)
  }

  useEffect(() => {
    setState()
  }, [])

  const shouldUpdateState = (
    prevState.value !== value ||
    prevState.options !== options ||
    prevState.forcedSelectedOption !== forcedSelectedOption
  )

  if (shouldUpdateState) {
    setState()
    setPrevState({
      value,
      options,
      forcedSelectedOption
    })
  }

  const setHighlighted = option => {
    setHighlightedOption(option)
    setValueStored(option)
  }

  const setSelected = option => {
    setSelectedOption(option)
    setHighlightedOption(option)
    setValueStored(optionIdentity(option))
  }

  const setFocusToController = () => {
    controllerRef.current.focus()
  }

  // Handlers
  const handleSelect = option => () => {
    setSelected(option)
    onChange(option)
    setActive(false)
    onActiveChanged(false)
  }

  const handleToggleActive = e => {
    e.preventDefault()

    if (!isDisabled) {
      setActive(!isActive)
      onActiveChanged(!isActive)
    }
  }

  const handleClickOut = () => {
    setActive(false)
    onActiveChanged(false)
  }

  const handleGhostSelect = selectedIndex => {
    const option = options[Number(selectedIndex)]
    handleSelect(option)()
  }

  const findIndex = item => options.findIndex(o => o === item)
  const getOrNull = R.tryCatch(R.pipe(optionIdentity, sanitizeID), () => null)
  const selectedOptionIndex = findIndex(selectedOption)
  const highlightedOptionIndex = findIndex(highlightedOption)
  const activeDescendant = getOrNull(highlightedOption) || getOrNull(selectedOption)
  const optionsIterator = iterator(options, highlightedOptionIndex)

  const handleNextOption = () => {
    setHighlighted(optionsIterator.getNext())
  }

  const handlePrevOption = () => {
    setHighlighted(optionsIterator.getPrevious())
  }

  const handleKeyDown = e => {
    const selectOption = option => () => {
      setSelected(option)
      setActive(false)
      onChange(option)
      setFocusToController()
    }

    const keyTabCallback = () => {
      global.requestAnimationFrame(() => {
        if (!focusableElements.includes(global.activeElement)) {
          handleClickOut()
        }
      })
    }

    if (isActive) {
      onKey(e, KEY.DOWN, handleNextOption)
      onKey(e, KEY.UP, handlePrevOption)
      onKey(e, KEY.RETURN, selectOption(highlightedOption))
      onKey(e, KEY.SPACE, selectOption(highlightedOption))
      onKey(e, KEY.ESC, handleClickOut)
      watchKey(e, KEY.TAB, keyTabCallback)
      return
    }

    const activatorKeys = [KEY.UP, KEY.DOWN, KEY.RETURN, KEY.SPACE]

    if (activatorKeys.includes(e.key)) {
      e.stopPropagation()
      e.preventDefault()
      setActive(true)
    }
  }

  return (
    <ClickOutHandler onClickOut={handleClickOut}>
      <div className={classNames('Select', className, { isDisabled })}>
        <HiddenInput name={name} value={valueStored}/>
        <div className='Select-content'>
          <button
            id={`${id}-button`}
            className='Select-combobox'
            role='combobox'
            type='button'
            onClick={handleToggleActive}
            onKeyDown={handleKeyDown}
            ref={controllerRef}
            aria-labelledby={`${id}-label ${id}-button`}
            aria-expanded={isActive}
            aria-haspopup='true'
            aria-activedescendant={activeDescendant}
            aria-controls={`${id}-listOptions`}
            aria-owns={`${id}-listOptions`}
            disabled={isDisabled || null}
          >
            <div className={classNames('Select-selectedOption', { isActive })}>
              {isPresent(label) && <div id={`${id}-label`} className='Select-label'>{label}</div>}
              <SelectedOption
                placeholder={placeholder}
                selectedOptionTemplate={selectedOptionTemplate}
                selectedOption={selectedOption}
                optionIdentity={optionIdentity}
              />
            </div>
          </button>

          <Options
            options={options}
            selectedOption={selectedOption}
            optionIdentity={optionIdentity}
            optionTemplate={optionTemplate}
            highlightedOption={highlightedOption}
            handleSelect={handleSelect}
            isActive={isActive}
            setFocusableElements={setFocusableElements}
            onKeyDownContent={handleKeyDown}
            withScrollIntoView={withScrollIntoView}
            controllerRef={controllerRef}
            ariaLabelledBy={`${id}-label`}
            {...props}
            id={`${id}-listOptions`}
          />

          {!forceDesktopBehavior && (
            <Viewport only={['tablet', 'mobile']}>
              <select
                id={id}
                className='Select-ghostSelect'
                value={selectedOptionIndex}
                onChange={e => {
                  handleGhostSelect(e.target.value)
                }}
                disabled={isDisabled}
              >
                {
                  options.map((option, index) => (
                    <option key={index} value={index}>
                      {optionTemplate(option)}
                    </option>
                  ))
                }
              </select>
            </Viewport>
          )}
        </div>
      </div>
    </ClickOutHandler>
  )
}

Select.defaultProps = {
  forceDesktopBehavior: false,
  optionIdentity: getId,
  selectedOptionTemplate: getName,
  optionTemplate: getName,
  placeholder: '',
  label: '',
  onChange: noop,
  onActiveChanged: noop
}

Select.propTypes = {
  name: PropTypes.string,
  id: numberOrString.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  forcedSelectedOption: PropTypes.object,
  options: PropTypes.oneOfType([
    PropTypes.array,
    MobxPropTypes.arrayOrObservableArray
  ]).isRequired,
  optionIdentity: PropTypes.func,
  selectedOptionTemplate: PropTypes.func,
  optionTemplate: PropTypes.func,
  onChange: PropTypes.func,
  onActiveChanged: PropTypes.func,
  isDisabled: PropTypes.bool,
  withScrollIntoView: PropTypes.bool,
  forceDesktopBehavior: PropTypes.bool
}

export default Select
