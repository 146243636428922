import React from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { isTouchScreen } from '../../../../lib/utils/viewport'
import { noop } from '../../../../lib/utils/common'

const htmlLabel = (name, matchText) => {
  if (matchText) {
    const regexp = new RegExp(matchText, 'gi')
    const resultNameHtml = name.replace(regexp, text => (
      `<span class='Multiselect-matchText'>${text}</span>`
    ))

    return(
      <span dangerouslySetInnerHTML={{__html: resultNameHtml}} />
    )
  }

  return name
}

const enhance = withProps(({ option, matchText, optionTemplate, skipMatchWrapping }) => ({
  labelText: skipMatchWrapping
    ? optionTemplate(option)
    : htmlLabel(optionTemplate(option), matchText)
}))

const Option = ({
  option, onSelect, isHighlighted, isActive,
  onMouseEnter, onMouseLeave, activeRef, labelText
}) => (
  <div
    className={className('Multiselect-option', { isActive: isActive, isHighlighted: isHighlighted })}
    onClick={() => { onSelect(option) }}
    onMouseEnter={isTouchScreen() ? noop : () => onMouseEnter(option)}
    onMouseLeave={isTouchScreen() ? noop : () => onMouseLeave(option)}
    ref={activeRef(option)}
  >
    {labelText && (
      <div>
        { labelText }
      </div>
    )}
  </div>
)

Option.defaultProps = {
  matchText: ''
}

Option.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  matchText: PropTypes.string,
  activeRef: PropTypes.func.isRequired,
  labelText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]).isRequired
}

export default enhance(Option)
