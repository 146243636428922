import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from '../../../../../lib/propTypes'
import ViewMapOptions from './ViewMapOptions'
import Viewport from '../../../Viewport'


const MapOptionsTemplate = props => {
  return (
    <Fragment>
      <Viewport only={['desktop', 'tablet']}>
        <ViewMapOptions
          {...props}
        />
      </Viewport>
      <Viewport only='mobile'>
        {props.children && props.children}
        <div className='Multiselect-actions' role='presentation'>
          <div className='Multiselect-action mobile-viewport'>
            <button
              onClick={props.cancelHandler}
              className='Button Button--secondary Button--withoutBorder'
            >
              Close
            </button>
          </div>
        </div>
      </Viewport>
    </Fragment>
  )
}

MapOptionsTemplate.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  children: childrenPropType
}

export default MapOptionsTemplate
