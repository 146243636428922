import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = ({ ariaDescribedby, errors = [] }) => (
  errors.map(({ message }, id) => (
    <div key={`priceTo${id}`} className='ErrorText' aria-describedby={ariaDescribedby}>{message}</div>
  ))
)

ErrorMessage.propTypes = {
  ariaDescribedby: PropTypes.string.isRequired,
  errors: PropTypes.array
}

export default ErrorMessage
