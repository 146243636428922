import React from 'react'
import { SearchableSelect } from '../../../../components/Shared/Form'
import OptionsWrapper from './OptionsWrapper'

const SearchableSelectWithAction = props => (
  <SearchableSelect
    {...props}
    wrapperOptionsComponent={OptionsWrapper}
  />
)

export default SearchableSelectWithAction
