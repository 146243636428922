import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { numberSchema } from '../../../../schemas/numbers'
import KEY from '../../../../lib/constants/key'
import NumberField from './NumberField'

class NumberInput extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    keydownHandler: PropTypes.func,
    options: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      step: PropTypes.number
    }).isRequired
  }

  state = this.getInitialState(this.props)

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState(() => this.getInitialState(props))
  }

  get isPlusEnabled() {
    const { values, currentIndex } = this.state
    return (currentIndex < values.length - 1)
  }

  get isMinusEnabled() {
    const { currentIndex } = this.state
    return (currentIndex > 0)
  }

  get step() {
    return this.props.options.step || 1
  }

  getInitialState(props) {
    const { value, options } = props
    const { max, min } = options
    const values = (new Array(max - min + 1)).fill().map((_, i) => min + i)
    const checkedValue = (value || min) > max ? max : (value || min)
    const currentIndex = values.indexOf(checkedValue)

    return {
      values,
      transientValue: checkedValue,
      currentIndex
    }
  }

  normalizeValue = value => {
    const { options: { min, max } } = this.props

    return (value || min) > max ? max : (value || min)
  }

  onChangeHandler = currentIndex => {
    this.setState({ currentIndex }, this.notifyChange)
  }

  onInputChangeHandler = event => {
    // Very special case
    // Number('') === 0, so handle this beforehand
    if (event.target.value === '') {
      this.setState(() => ({ transientValue: '' }))
    } else {
      const eventValue = Number(event.target.value)
      const isValid = numberSchema.isValidSync(eventValue)

      if (isValid) {
        const { values } = this.state
        const value = this.normalizeValue(eventValue)

        this.setState(() => ({ transientValue: value, currentIndex: values.indexOf(value) }), this.notifyChange)
      }
    }
  }

  plusHandler = () => {
    if (this.isPlusEnabled) {
      this.setState(state => ({
        currentIndex: Math.min(state.currentIndex + this.step, state.values.length - 1)
      }),
      this.notifyChange)
    }
  }

  minusHandler = () => {
    if (this.isMinusEnabled) {
      this.setState(state => ({
        currentIndex: Math.max(state.currentIndex - this.step, 0)
      }),
      this.notifyChange)
    }
  }

  notifyChange = () => {
    const { onChange } = this.props

    if (onChange) {
      const { values, currentIndex } = this.state
      onChange(this.props.name, values[currentIndex])
    }
  }

  keydownHandler = event => {
    let isEventStopped = false

    switch (event.key) {
    case KEY.UP:
      this.plusHandler()
      isEventStopped = true
      break
    case KEY.DOWN:
      this.minusHandler()
      isEventStopped = true
      break
    default:
      break
    }

    if (isEventStopped) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  render() {
    const { id, name, className, options: { min, max } } = this.props
    const { values, currentIndex, transientValue } = this.state
    const buttonClass = 'Button Button--link Button--formControl'
    const plusClassNames = classNames(buttonClass, 'Button--iconPlus', { isDisabled: !this.isPlusEnabled })
    const minusClassNames = classNames(buttonClass, 'Button--iconMinus', { isDisabled: !this.isMinusEnabled })
    const numberInputClassName = classNames('NumberInput', className)

    return (
      <div className={numberInputClassName}>
        <div className='NumberInput-action NumberInput-action--first'>
          <button
            type='button'
            className={minusClassNames}
            onClick={this.minusHandler}
            aria-hidden='true'
            disabled={!this.isMinusEnabled || null}
            tabIndex='-1'
          />
        </div>
        <NumberField
          id={id}
          name={name}
          values={values}
          transientValue={transientValue}
          currentIndex={currentIndex}
          onChange={this.onChangeHandler}
          onInputChange={this.onInputChangeHandler}
          step={this.step}
          onKeyDown={this.keydownHandler}
          min={min}
          max={max}
        />
        <div className='NumberInput-action NumberInput-action--last'>
          <button
            type='button'
            className={plusClassNames}
            onClick={this.plusHandler}
            aria-hidden='true'
            disabled={!this.isPlusEnabled || null}
            tabIndex='-1'
          />
        </div>
      </div>
    )
  }
}

export default NumberInput
