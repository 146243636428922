import { useEffect } from 'react'
import { registerActions } from '../../lib/globalActions'

/**
 *
 * Register global action in \_\_global_actions_\_ namespace of a window by the key name
 * @param {object} params params
 * @param {object} params.context context to pick actions.
 * @param {string} params.key key in global scope.
 * @param {string[]} params.actions array of actions names.
 * @return {undefined}
 */

const useRegisterGlobalActions = ({ context = {}, key = '', actions = [] }) => {
  useEffect(() => {
    registerActions(context)(key, ...actions)
  }, [])
}

export default useRegisterGlobalActions
