import React from 'react'
import PropTypes from 'prop-types'
import { CloseUrl } from '../../Svg/Close'

const HeaderSearchCloseButton = ({ onClick }) => (
  <button className='HeaderSearchCloseIcon' onClick={onClick}>
    <img src={CloseUrl} alt='Close search' className='HeaderSearchCloseIcon-image' />
  </button>
)

HeaderSearchCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default HeaderSearchCloseButton
