import React from 'react'
import PropTypes from 'prop-types'
import { withHandlers, compose, withState, defaultProps } from 'recompose'
import cn from 'classnames'
import { noop } from '../../../../lib/utils/common'
import { dynamicDateOptions, isCustomDateValue } from '../../../../lib/utils/date'
import { getName } from '../../../../lib/utils/selectors'
import Select from '../Select'
import SimpleOptions from './SimpleOptions'
import CalendarOptions from './CalendarOptions'

const chooseCalendarMode = ({ value }) => (
  isCustomDateValue(value) ? 'calendar' : 'simple'
)

const enhance = compose(
  defaultProps({
    presetDateOptions: dynamicDateOptions,
    onChange: noop,
    selectedOptionTemplate: op => op.name,
    optionIdentity: getName
  }),
  withState('calendarMode', 'setCalendarMode', ({ value }) => (
    chooseCalendarMode(value)
  )),
  withHandlers({
    onActiveChanged: ({ setCalendarMode, value }) => () => {
      setCalendarMode(chooseCalendarMode(value))
    },
    onValueChanged: ({ onChange }) => option => {
      onChange(option.value)
    }
  })
)

const DynamicDates = ({
  id,
  label,
  presetDateOptions,
  optionIdentity,
  selectedOptionTemplate,
  calendarMode,
  setCalendarMode,
  onActiveChanged,
  onValueChanged,
  value,
  className,
  placeholder
}) => (
  <div className='DynamicDates'>
    <Select
      id={id}
      label={label}
      placeholder={placeholder}
      forceDesktopBehavior={true}
      onChange={onValueChanged}
      forcedSelectedOption={value}
      className={cn(
        'Select--noHeightLimit, Select--withLabel',
        {'Select--withCalendar': calendarMode === 'calendar' },
        className
      )}
      options={presetDateOptions}
      optionIdentity={optionIdentity}
      selectedOptionTemplate={selectedOptionTemplate}
      setCalendarMode={setCalendarMode}
      onActiveChanged={onActiveChanged}
      goBack={() => { setCalendarMode('simple') }}
      WrapperOptionsComponent={calendarMode === 'simple'
        ? SimpleOptions
        : CalendarOptions}
    />
  </div>
)

DynamicDates.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  presetDateOptions: PropTypes.array.isRequired,
  selectedOptionTemplate: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  calendarMode: PropTypes.string.isRequired,
  setCalendarMode: PropTypes.func.isRequired,
  onActiveChanged: PropTypes.func.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string
    })
  }),
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string
}

export default enhance(DynamicDates)
