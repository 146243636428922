import React from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from '../../../../lib/propTypes'
import { Calendar } from '../../../../shared_components/Svg'

const SimpleOptions = ({ children, setCalendarMode }) => (
  <div className='DynamicDates-options'>
    { children }
    <div className='DynamicDates-actions'>
      <button type='button' onClick={() => { setCalendarMode('calendar') }} className='Button Button--link'>
        <Calendar className='DynamicDates-calendarIcon' />
        Custom Range
      </button>
    </div>
  </div>
)

SimpleOptions.propTypes = {
  children: childrenPropType.isRequired,
  setCalendarMode: PropTypes.func.isRequired
}

export default SimpleOptions
