import React from 'react'
import PropTypes from 'prop-types'
import { searchResultPropType } from '../../../lib/propTypes'
import * as R from 'ramda'
import { stripHTMLTags } from '../../../lib/utils/common'
import { sanitizeID } from '../../../lib/utils/string'

const buildID = string => R.pipe(stripHTMLTags, sanitizeID)(string)

const resultString = string => {
  const resultHtmlString = string.replace(/<em>(.*?)<\/em>/g, (_, text) => (
    `<span class='SearchResults-searchText'>${text}</span>`
  ))

  return (
    <span dangerouslySetInnerHTML={{ __html: resultHtmlString }}/>
  )
}

const SearchResultsCategory = ({ results, name, activeID, setActiveOptionRef }) => (
  <ul className='SearchResults-category' aria-labelledby={name} role='group'>
    <li className='SearchResults-title' id={name}>
      {name} ({results.total})
    </li>
    {
      results.suggestions.map(result => {
        const optionID = buildID(result.string)

        return (
          <li id={optionID}
            key={result.url}
            className='SearchResults-item'
            role='option'
            aria-selected={activeID === optionID}
            ref={setActiveOptionRef(optionID)}
          >
            <a href={result.url} className='SearchResults-link'> {resultString(result.string)} </a>
          </li>
        )
      })
    }
  </ul>
)

SearchResultsCategory.propTypes = {
  results: searchResultPropType.isRequired,
  name: PropTypes.string.isRequired,
  activeID: PropTypes.string,
  setActiveOptionRef: PropTypes.func.isRequired
}

export default SearchResultsCategory
