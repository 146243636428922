import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStateHandlers, defaultProps, compose, lifecycle } from 'recompose'
import ClickOutHandler from 'react-onclickout'
import { removeFromArray } from '../../../../lib/utils/collection'
import { selectedTreeOptions } from '../../../../lib/utils/form'
import Options from './Options'
import SelectedOptions from './SelectedOptions'
import * as R from 'ramda'

const getInitialState = ({ options, optionIdentity, value }) => ({
  selectedOptions: selectedTreeOptions({ values: value, options, optionIdentity }),
  values: value || []
})

const enhance = compose(
  defaultProps({
    optionIdentity: ({ id }) => id,
    selectedOptionsTemplate: options => options.map(o => o.name).join(', '),
    optionTemplate: ({ name }) => name,
    tabIndex: -1,
    onChange: () => {},
    onActiveChanged: () => {},
    value: [],
    wrapperComponent: ({ children }) => children,
    onBlur: () => {},
    clearAllFilter: () => []
  }),
  withStateHandlers(
    props => getInitialState(props),
    {
      getInitialState: () => getInitialState,
      toggleActive: ({ isActive }) => () => ({ isActive: !isActive }),
      onClickOut: ({ isActive }, { onBlur }) => () => {
        if (isActive) {
          onBlur()
        }
        return ({ isActive: false })
      },
      onSelect: ({ values }, { onChange, optionIdentity, options }) => option => {
        let newValues = values

        if (values.includes(optionIdentity(option))) {
          const parentOptions = options.find(o => o.children && o.children.includes(option))
          if (parentOptions) {
            newValues = removeFromArray(newValues, optionIdentity(parentOptions))
          }
          newValues = removeFromArray(newValues, optionIdentity(option))
        } else {
          const childrenOptions = (option.children || []).map(optionIdentity)
          newValues = R.uniq([...newValues, optionIdentity(option), ...childrenOptions])
        }

        onChange(newValues)

        return {
          values: newValues
        }
      },

      onClearAll: (state, { onChange, clearAllFilter }) => () => {
        onChange(clearAllFilter(state))

        return {
          selectedOptions: [],
          values: []
        }
      }
    }
  ),
  lifecycle({
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(props) {
      if (props.value !== this.props.value) {
        this.props.getInitialState(props)
      }
    },

    componentDidUpdate(prevProps) {
      if (prevProps.isActive !== this.props.isActive) {
        this.props.onActiveChanged(this.props.isActive)
      }
    }
  })
)

const CheckyTreeSelect = props => {
  const {
    options, optionIdentity, selectedOptionsTemplate,
    optionTemplate, placeholder, selectedOptions, withClearAll,
    onClickOut, name, onSelect, onClearAll, isActive, toggleActive,
    tabIndex, id, className, wrapperComponent, wrapperClassName, values
  } = props
  const WrapperComponent = wrapperComponent
  const wrapperProps = { ...props, className: wrapperClassName }

  return (
    <ClickOutHandler onClickOut={onClickOut}>
      <div className={classNames('CheckyTreeSelect', className)} tabIndex={tabIndex} id={id}>
        <div className={classNames('CheckyTreeSelect-selectedOptions', { isActive })} onClick={toggleActive}>
          <SelectedOptions
            placeholder={placeholder}
            selectedOptionsTemplate={selectedOptionsTemplate}
            selectedOptions={selectedOptions}
          />
        </div>
        <WrapperComponent {...wrapperProps}>
          <Options
            options={options}
            name={name}
            values={values}
            optionIdentity={optionIdentity}
            optionTemplate={optionTemplate}
            onSelect={onSelect}
            isActive={isActive}
            withClearAll={withClearAll}
            onClearAll={onClearAll}
          />
        </WrapperComponent>
      </div>
    </ClickOutHandler>
  )
}

CheckyTreeSelect.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  values: PropTypes.any,
  onChange: PropTypes.func,
  onClearAll: PropTypes.func,
  selectedOptions: PropTypes.array,
  optionIdentity: PropTypes.func,
  selectedOptionsTemplate: PropTypes.func,
  optionTemplate: PropTypes.func,
  onClickOut: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleActive: PropTypes.func.isRequired,
  onActiveChanged: PropTypes.func,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  wrapperComponent: PropTypes.func,
  wrapperClassName: PropTypes.string,
  withClearAll: PropTypes.bool
}

export default enhance(CheckyTreeSelect)
