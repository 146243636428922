import React from 'react'
import PropTypes from 'prop-types'
import { withState, withHandlers, lifecycle, compose, defaultProps } from 'recompose'
import ReactAutocomplete from 'react-autocomplete'

const enhance = compose(
  defaultProps({
    onSelectedItem: () => {},
    setResetAutoCompleteHandler: () => {}
  }),
  withState('value', 'setValue', ({ value }) => value || ''),
  withHandlers({
    onValueChange: ({ setValue }) => e => {
      setValue(e.target.value.trimLeft())
    },
    onSelect: ({ setValue, onSelectedItem }) => (v, item) => {
      onSelectedItem(item)
      setValue(v)
    },
    onResetValue: ({ setValue }) => () => {
      setValue('')
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.setResetAutoCompleteHandler(this.props.onResetValue)
    },
    componentDidUpdate({ value: prevValue, onChange }) {
      if (prevValue !== this.props.value) {
        onChange(this.props.value)
      }
    }
  })
)

const Autocomplete = props => (
  <ReactAutocomplete
    {...props}
    onChange={props.onValueChange}
  />
)

Autocomplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  onSelectedItem: PropTypes.func,
  onResetValue: PropTypes.func,
  setResetAutoCompleteHandler: PropTypes.func
}

export default enhance(Autocomplete)
