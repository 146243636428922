import * as yup from 'yup'
import { isEmpty } from '../lib/utils/collection'
import { containsWord } from '../lib/utils/string'

yup.addMethod(yup.mixed, 'requiredIf', function (conditionValue, message) {
  return this.when(conditionValue, (isShow, schema) => isShow ? schema.required(message) : schema)
})

yup.addMethod(yup.string, 'unacceptableWordsCheck', function (unacceptableWordsRef, message) {
  return this.test('unacceptableWordsCheck', message, function (value) {
    if (isEmpty(value)) {
      return true
    }
    const unacceptableWords = this.resolve(unacceptableWordsRef)

    return !unacceptableWords.some(w => containsWord(value, w))
  })
})
