import React from 'react'
import { compose, withProps, withHandlers } from 'recompose'
import registerGlobalActions from './registerGlobalActions'

const withSearchFocusAction = compose(
  withProps({
    inputRef: React.createRef()
  }),
  withHandlers({
    focusSearchField: ({ inputRef }) => () => {
      inputRef.current.focus()
    }
  }),
  registerGlobalActions('ListingsCollection', 'focusSearchField'),
)

export default withSearchFocusAction
