import React from 'react'
import { branch, renderComponent, renderNothing } from 'recompose'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { isPresent } from '../../../../lib/utils/collection'

const KeyAmenityLabel = ({ item, className }) => (
  <div className={cn('Card-label', className)}>
    {item}
  </div>
)

KeyAmenityLabel.propTypes = {
  item: PropTypes.string,
  className: PropTypes.string
}

export default branch(
  ({ item }) => isPresent(item),
  renderComponent(KeyAmenityLabel),
  renderNothing
)()
