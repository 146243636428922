/* eslint no-process-env: 0 */
import createElement from './createElement'
import setDisplayName from './setDisplayName'

export const renderComponent = Component => () => {
  const RenderComponent = props => createElement(Component)(props)

  if (process.env.NODE_ENV !== 'production') {
    const name = Component && Component.name + '(renderComponent)' || 'renderComponent'
    return setDisplayName(name)(Component)
  }

  return RenderComponent
}

export default renderComponent
