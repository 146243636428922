import PropTypes from 'prop-types'
import { branch, renderComponent } from 'recompose'
import OptionsWithEllipsis from './OptionsWithEllipsis.js'
import OptionsWithoutEllipsis from './OptionsWithoutEllipsis.js'

const SelectedOptions = branch(
  ({ isActive }) => isActive,
  renderComponent(OptionsWithoutEllipsis),
  renderComponent(OptionsWithEllipsis)
)

SelectedOptions.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default SelectedOptions()
