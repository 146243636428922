import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../../../agent_components/Shared/Avatar'

const OptionTemplate = ({ name, photo }) => (
  <div className='AgentOption'>
    <div className='AgentOption-content'>
      <div className='AgentOption-avatar'>
        <Avatar companyName={name} photo={photo} />
      </div>
      <span className='AgentOption-title'>{name}</span>
    </div>
  </div>
)

OptionTemplate.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.string
}

export default OptionTemplate
