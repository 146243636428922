import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers } from 'recompose'
import className from 'classnames'
import { CloseUrl } from '../../../Svg'

const enhance = compose(
  withHandlers({
    onDestroyClick: props => event => {
      event.preventDefault()
      event.stopPropagation()
      props.onDestroy()
    }
  })
)

const FilterPill = ({ name, value, content, onDestroyClick, isMarked, isInvalid, hasWarning }) => (
  <div className={className('FilterPill', { isMarked, isInvalid, hasWarning })}>
    <input type='hidden' name={name} value={value} />
    <div className='FilterPill-content'>
      <div className='FilterPill-label'>
        { content }
      </div>
      <button className='FilterPill-destroy' onClick={onDestroyClick}>
        <span className='sr-only'>close</span>
        <img src={CloseUrl} className='FilterPill-destroyIcon' alt='' />
      </button>
    </div>
  </div>
)

FilterPill.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  content: PropTypes.string.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onDestroyClick: PropTypes.func.isRequired,
  isMarked: PropTypes.bool,
  isInvalid: PropTypes.bool,
  hasWarning: PropTypes.bool
}

FilterPill.defaultProps = {
  name: ''
}

export default enhance(FilterPill)
