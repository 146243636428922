import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const OptionsTree = ({ className, options, optionIdentity, optionTemplate, onSelect, values }) => (
  options.map(option => (
    <Fragment key={optionIdentity(option)}>
      <div className={className} onClick={() => onSelect(option)}>
        <div className='Checkbox'>
          <input type='checkbox'
            checked={values.includes(optionIdentity(option))}
            className='Checkbox-input'
            readOnly
          />
          <span className='Checkbox-icon'/>
        </div>
        <div className='CheckyTreeSelect-optionText'>
          {optionTemplate(option)}
        </div>
      </div>
      { option.children && option.children.length > 0 &&
        <div className='CheckyTreeSelect-childrenOptions'>
          <OptionsTree
            className='CheckyTreeSelect-option CheckyTreeSelect-option--tree'
            options={option.children}
            optionIdentity={optionIdentity}
            optionTemplate={optionTemplate}
            onSelect={onSelect}
            values={values}
          />
        </div>
      }
    </Fragment>
  ))
)

OptionsTree.propTypes = {
  options: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired
}

export default OptionsTree
