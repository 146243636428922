import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent } from 'recompose'
import ActiveOptions from './ActiveOptions'

const SearchableSelectEmptyState = ({ emptyStateComponent, ...props }) => {
  const EmptyStateComponent = emptyStateComponent

  return (
    emptyStateComponent
      ? (
        <div className='Multiselect-options'>
          <EmptyStateComponent {...props}/>
        </div>
      )
      : null
  )
}

SearchableSelectEmptyState.propTypes = {
  emptyStateComponent: PropTypes.func
}

SearchableSelectEmptyState.defaultProps = {
  emptyStateComponent: null
}

export default branch(
  ({ hasOptions }) => hasOptions,
  renderComponent(ActiveOptions),
  renderComponent(SearchableSelectEmptyState)
)()

