import React from 'react'
import PropTypes from 'prop-types'

const AgentWrapper = ({ children }) => (
  <div className='CheckySelect-optionsWrapper'>
    {children}
  </div>
)

AgentWrapper.propTypes = {
  children: PropTypes.element.isRequired
}

export default AgentWrapper
