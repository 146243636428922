import { action, observable, computed, toJS, makeObservable } from 'mobx'
import * as R from 'ramda'
import { lens } from 'lorgnette'
import { pascalize, decamelizeKeys } from 'humps'
import { singular } from 'pluralize'
import { fromServerToClient, isPresent, isSuperset, setIntersection } from './collection'
import { isSelectable } from './listings'
import { getId } from './selectors'

export const SELECTED_KEYS = {
  Sale: 'saleIds',
  Rental: 'rentalIds',
  Land: 'landIds',
  Search: 'searchIds'
}

export const withSelectableCollection = Category => class extends Category {
  constructor(settings) {
    super(settings)

    makeObservable(this)
  }

  @computed
  get selectableCollection() {
    return this.collection.filter(isSelectable)
  }

  @computed
  get selectableCollectionSize() {
    return this.selectableCollection.length
  }

  @computed
  get selectedIdsSet() {
    return new Set(this.root.selectedBarStore.selectedIds)
  }

  @computed
  get allIdsSet() {
    return new Set(this.selectableCollection.map(getId))
  }

  @computed
  get isAllChecked() {
    return isSuperset(this.selectedIdsSet, this.allIdsSet)
  }

  @computed
  get exportUrlExtraCharacter() {
    return this.url.includes('?') ? '&' : '?'
  }

  @computed
  get exportUrl() {
    return this.url + this.exportUrlExtraCharacter + 'format=xlsx'
  }

  @computed
  get isHalfChecked() {
    return !this.isAllChecked && setIntersection(this.selectedIdsSet, this.allIdsSet).size > 0
  }

  checkAllOnPage = e => {
    this.root.selectedBarStore.toggleSelectedItemsCollection({
      isItemsChecked: e.target.checked,
      items: this.selectableCollection,
      itemType: this.listingClass
    })
  }
}

export const withSelectablePricePeriod = Category => class extends Category {
  @observable selectedPricePeriods = {}

  constructor(settings) {
    super(settings)

    makeObservable(this)
  }

  @action
  changePricePeriod = (id, period) => {
    this.selectedPricePeriods = { ...this.selectedPricePeriods, [id]: period }
  }

  @action
  resetCurrentPricePeriods = () => {
    this.selectedPricePeriods = {}
  }

  @action
  initSelectedPricePeriods = selectedItems => {
    this.selectedPricePeriods = selectedItems
      .reduce((acc, { item }) => (
        { ...acc, [item.id]: item.currentPricePeriod }
      ), {})
  }
}

export const areFiltersEqual = (filters, store) => (
  Object.keys(filters).every(name => R.equals(toJS(store[name]), filters[name]))
)

export const getStorageKey = (prefix, suffix) => `${prefix}_${suffix}`
export const getFiltersStorageKey = suffix => getStorageKey('Listing Filters', suffix)

export const withFolderServerMappings = Category => class extends Category {
  mapItemToServerValue = selectedItem => ({ [SELECTED_KEYS[selectedItem.itemType]]: [selectedItem.itemId] })

  groupIdByItemType = selectedItems => {
    const data = {
      [SELECTED_KEYS.Sale]: [],
      [SELECTED_KEYS.Rental]: [],
      [SELECTED_KEYS.Land]: [],
      [SELECTED_KEYS.Search]: []
    }

    selectedItems.forEach(({ itemType, itemId }) => {
      const key = SELECTED_KEYS[itemType]
      data[key].push(itemId)
    })

    return data
  }

  mapItemCollectionToServerValue = selectedItems => {
    const data = this.groupIdByItemType(selectedItems)

    const preparedPricePeriods = Object.keys(this.selectedPricePeriods)
      .reduce((acc, curr) => ({
        ...acc,
        [curr]: this.selectedPricePeriods[curr] && decamelizeKeys(this.selectedPricePeriods[curr])
      }), {})

    return { ...data, pricePeriods: preparedPricePeriods }
  }

  mapBasketItemsToServerValues = selectedItems => {
    const data = this.groupIdByItemType(selectedItems)
    const pricePeriods = selectedItems.reduce((acc, { item, savedPricePeriod }) => {
      const period = isPresent(savedPricePeriod)
        ? { ...decamelizeKeys(savedPricePeriod) }
        : { ...decamelizeKeys(item.currentPricePeriod) }

      if (isPresent(period)) {
        acc[item.id] = period
      }

      return acc
    }, {})

    return { ...data, pricePeriods }
  }

  mapFromServerToSelectedItems = data => {
    return ['sales', 'rentals', 'lands'].reduce((acc, listingType) => {
      const itemsToAdd = data[listingType].map(item => ({
        ...fromServerToClient(item),
        itemId: item.item.id,
        itemType: pascalize(singular(listingType))
      }))

      return [...acc, ...itemsToAdd]
    }, [])
  }
}

export const CONTACT_FORM_STATE = {
  inactive: 'inactive',
  makeContact: 'makeContact',
  createUser: 'createUser'
}

export const CONTACT_FORM_TYPE = {
  agent: 'agent',
  owner: 'owner'
}

export const withCopyPrices = Category => class extends Category {
  constructor(settings) {
    super(settings)

    makeObservable(this)
  }

  disableAvailability = price => ({ ...price, availability: false })

  copyPricesValue = fromYear => (price, pricePeriod) => ({
    ...price, value: fromYear[pricePeriod].value
  })

  @action('[RentalForm] copy prices')
  copyPrices = (yearFrom, yearTo) => {
    const fromPricePeriods = lens
      .prop('prices')
      .firstOf(({ year }) => year === yearFrom)
      .prop('value')
      .get(this.value)
      .getOr(null)

    const toPricePeriods = lens
      .prop('prices')
      .firstOf(({ year }) => year === yearTo)
      .prop('value')
      .get(this.value)
      .getOr(null)

    const toPricePeriodsLens = lens
      .prop('prices')
      .firstOf(({ year }) => year === yearTo)
      .prop('value')

    this.value = toPricePeriodsLens
      .set(this.value, R.mapObjIndexed(this.copyPricesValue(fromPricePeriods), toPricePeriods))

    // Because of prices observer we need to assign value two times.
    // After first assignment availability will be set to true, and we need to reset it

    const toPricePeriodsAfterCopyPriceValue = lens
      .prop('prices')
      .firstOf(({ year }) => year === yearTo)
      .prop('value')
      .get(this.value)
      .getOr(null)

    this.value = toPricePeriodsLens.set(this.value, R.map(this.disableAvailability, toPricePeriodsAfterCopyPriceValue))

    this.flashMessage.show({ text: 'Prices were successfully copied!', type: 'success' })

    setTimeout(() => {
      this.revalidateRentalPrice()
    }, 0)
  }
}

export const toSelectedItem = ({ item, itemType, position, folderId, folderName }) => ({
  folderId,
  folderName,
  position,
  itemType,
  item,
  itemId: item.id
})
