/* eslint-disable no-process-env */

import { serialize, isServer } from '../utils/common'

const gmaKeyMetaElement = isServer() ? null : document.querySelector('meta[name="gma-key"]')
const gsmaKeyMetaElement = isServer() ? null : document.querySelector('meta[name="gsma-key"]')

export const GOOGLE_MAP_API_KEY = gmaKeyMetaElement && gmaKeyMetaElement.content
const GOOGLE_STATIC_MAP_API_KEY = gsmaKeyMetaElement && gsmaKeyMetaElement.content
const googleMapApiDomain = 'https://maps.googleapis.com/maps/api/js'
const googleStaticMapApiDomain = 'https://maps.googleapis.com/maps/api/staticmap'

const googleMapApiProps = {
  key: GOOGLE_MAP_API_KEY,
  v: 'quarterly',
  libraries: 'geometry,drawing,places'
}

const googleStaticMapApiProps = {
  key: GOOGLE_STATIC_MAP_API_KEY,
  v: 'quarterly',
  libraries: 'geometry,drawing,places'
}

export const googleMapURL = `${googleMapApiDomain}?${serialize(googleMapApiProps)}`
export const googleStaticMapUrl = `${googleStaticMapApiDomain}?${serialize(googleStaticMapApiProps)}`
