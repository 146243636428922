import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { noop } from '../../../../lib/utils/common'

const Option = ({
  id,
  className,
  option,
  optionTemplate,
  onSelect,
  isActive,
  isHighlighted,
  activeRef
}) => (
  <li
    id={id}
    className={cn(className, {
      isActive: isHighlighted,
      isDisabled: option.isDisabled
    })}
    onClick={onSelect(option)}
    ref={activeRef(option)}
    role='option'
    aria-selected={isActive}
    onKeyDown={noop}
  >
    {optionTemplate(option)}
  </li>
)

Option.defaultProps = {
  className: 'Option',
  isSelectedGroup: false
}

Option.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  option: PropTypes.any.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  activeRef: PropTypes.func.isRequired
}

export default Option
