import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import TreeTownSelect from '../Tree'
import MapOptionsTemplate from './MapOptionsTemplate'

const MapTownSelect = props => (
  <TreeTownSelect
    {...props}
    wrapperOptionsComponent={MapOptionsTemplate}
    className={cn(props.className, 'Multiselect--checky')}
  />
)

MapTownSelect.propTypes = {
  className: PropTypes.string
}

export default MapTownSelect
