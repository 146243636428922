import React, { Component } from 'react'
import Rheostat from 'rheostat'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import priceRangeSchema from './schema'
import { withCurrency, numberShortFormat, priceLabelWithCutOff } from '../../../../lib/utils/money'
import {debounce, inRange, isNumeric, noop} from '../../../../lib/utils/common'
import { toFormErrors } from 'react-formal'
import PitComponent from './PitComponent'
import Handle from './Handle'
import ErrorMessage from './ErrorMessage'

class PriceInput extends Component {
  static propTypes = {
    priceExtremum: PropTypes.shape({
      priceMin: PropTypes.number.isRequired,
      priceMax: PropTypes.number.isRequired,
      histogramMin: PropTypes.number.isRequired,
      histogramMax: PropTypes.number.isRequired,
      counts: PropTypes.object.isRequired,
      cutOff: PropTypes.number.isRequired
    }).isRequired,
    values: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onReset: PropTypes.func,
    className: PropTypes.string,
    handleTemplate: PropTypes.func,
    view: PropTypes.string
  }

  static defaultProps = {
    handleTemplate: v => withCurrency(numberShortFormat(v)),
    onChange: noop,
    onReset: noop
  }

  constructor(props) {
    super(props)

    this.state = {
      ...this.getInitialState(this.props)(),
      activeInput: null
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const [newMin, newMax] = nextProps.values
    const [oldMin, oldMax] = this.state.values

    if (newMin !== oldMin || newMax !== oldMax) {
      this.setState(this.getInitialState(nextProps))
    }
  }

  getInitialState = ({ values }) => () => ({
    values,
    priceFrom: values[0],
    priceTo: values[1],
    errors: {}
  })

  updateValues = props => {
    this.setState(this.getInitialState(props), this.notifyChange)
  }

  debouncedUpdateValues = debounce(this.updateValues, 200)

  notifyChange = () => {
    const { onChange, onReset, priceExtremum: { histogramMax, histogramMin } } = this.props
    const { values } = this.state

    onChange('priceFrom', values[0])
    onChange('priceTo', values[1])

    if (values[0] === histogramMin) { onReset(['priceFrom']) }
    if (values[1] === histogramMax) { onReset(['priceTo']) }
  }

  validateAndChangePrice = name => event => {
    const value = isNumeric(Number(event.target.value)) ? Number(event.target.value) : 0

    this.setState({ [name]: value }, () => {
      const { priceFrom, priceTo } = this.state

      priceRangeSchema(this.allowedPriceMin, this.allowedPriceMax)
        .validate({ priceFrom, priceTo }, { abortEarly: false })
        .then(this.updateValues({ values: [priceFrom, priceTo] }))
        .catch(err => {
          this.setState(state => {
            return { ...state, errors: { ...toFormErrors(err) } }
          })
        })
    })
  }

  setActiveInput = activeInput => e => {
    e.persist()
    this.setState(() => ({ activeInput }), () => { e.target.select() })
  }

  unsetActiveInput = () => {
    this.setState(() => ({ activeInput: null }))
  }

  isInputActive = name => (
    this.state.activeInput === name
  )

  get allowedPriceMin() {
    const { priceExtremum: { priceMin, histogramMin } } = this.props
    return Math.min(priceMin, histogramMin)
  }

  get allowedPriceMax() {
    const { priceExtremum: { priceMax, histogramMax } } = this.props
    return Math.max(priceMax, histogramMax)
  }

  get pitPoints() {
    return Object.keys(this.props.priceExtremum.counts).map(a => parseInt(a, 10))
  }

  render() {
    const { priceExtremum, className, handleTemplate, view } = this.props
    const { priceFrom, priceTo, values, errors } = this.state
    const priceInputClass = classNames('PriceInput', className)
    const { cutOff, histogramMin, histogramMax } = priceExtremum
    const normalizedValues = [
      inRange(values[0], { from: histogramMin, to: histogramMax }),
      inRange(values[1], { from: histogramMin, to: histogramMax })
    ]
    const labelValues = normalizedValues.map(val => handleTemplate(val, cutOff))
    const priceFromId = view ? `priceFrom-${view}` : 'priceFrom'
    const priceToId = view ? `priceTo-${view}` : 'priceTo'

    return (
      <div className={priceInputClass}>
        <div className='PriceInput-diagram'>
          <Rheostat
            className='PriceInput-slider'
            min={histogramMin}
            max={histogramMax}
            values={normalizedValues}
            pitComponent={props => (
              <PitComponent {...props} priceExtremum={priceExtremum}/>
            )}
            handle={Handle}
            pitPoints={this.pitPoints}
            snap
            snapPoints={this.pitPoints}
            onValuesUpdated={this.debouncedUpdateValues}
          />
          <div className='PriceInput-labelsContainer' role='presentation'>
            <span className='PriceInput-label'>{labelValues[0]}</span>
            <span className='PriceInput-label'>{labelValues[1]}</span>
          </div>
        </div>
        <div className='PriceInput-fields'>
          <div className='PriceInput-field'>
            <label className='Label Label--larger' htmlFor={priceFromId}>
              Min Price
            </label>
            <input
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              id={priceFromId}
              name='priceFrom'
              className={classNames('TextField TextField--lightBorders', { isInvalid: errors.priceFrom })}
              value={this.isInputActive('priceFrom') ? priceFrom : priceLabelWithCutOff(priceFrom)}
              onFocus={this.setActiveInput('priceFrom')}
              onBlur={this.unsetActiveInput}
              onChange={this.validateAndChangePrice('priceFrom')}
              aria-invalid={Boolean(errors.priceFrom) || null}
            />

            {errors.priceFrom && (<ErrorMessage ariaDescribedby={priceFromId} errors={errors.priceFrom}/>)}
          </div>

          <div className='PriceInput-field'>
            <label className='Label Label--larger' htmlFor={priceToId}>
              Max Price
            </label>
            <input
              type='text'
              inputMode='numeric'
              pattern='[0-9]*'
              id={priceToId}
              name='priceTo'
              className={classNames('TextField TextField--lightBorders', { isInvalid: errors.priceTo })}
              value={this.isInputActive('priceTo') ? priceTo : priceLabelWithCutOff(priceTo)}
              onFocus={this.setActiveInput('priceTo')}
              onBlur={this.unsetActiveInput}
              onChange={this.validateAndChangePrice('priceTo')}
              aria-invalid={Boolean(errors.priceTo) || null}
            />

            {errors.priceTo && (<ErrorMessage ariaDescribedby={priceToId} errors={errors.priceTo}/>)}
          </div>
        </div>
      </div>
    )
  }
}

export default PriceInput
