import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'
import SearchLink from '../../Shared/SearchLink'

const RecentSearches = () => {
  const { store: { recentSearchesStore } } = useStores()
  const collection = recentSearchesStore.collection

  return (
    <div className='ListWithIcon'>
      <div className='ListWithIcon-title'>
        RECENT SEARCHES
      </div>
      <div className='ListWithIcon-items'>
        {collection.map(item => (
          <div key={item.id} className='ListWithIcon-item ListWithIcon-item--recent'>
            <SearchLink url={item.url} className='ListWithIcon-link' title={item.title}/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(RecentSearches)
