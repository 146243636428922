import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withHandlers, compose } from 'recompose'
import { fromContentEditable } from '../../../lib/utils/dom'
import ContentEditable from '../ContentEditable'

const enhance = compose(
  withHandlers(() => {
    let contentEditableRef

    return {
      setContentEditableRef: ({ onRef }) => el => {
        contentEditableRef = el

        if (onRef) {
          onRef(el)
        }
      },
      setCaretAtEnd: () => () => {
        contentEditableRef.setCaretAtEnd()
      },
      searchChange: ({ updateCurrentSearch }) => e => {
        updateCurrentSearch(fromContentEditable(e.target.value))
      },
      onKeyDown: ({ applySuggestion }) => e => {
        if (e.keyCode === 13) {
          e.preventDefault()

          applySuggestion()
        }
      }
    }
  })
)

const FieldWithSuggestion = ({
  blissModuleName = 'FieldWithSuggestion',
  searchChange,
  onKeyDown,
  setContentEditableRef,
  setCaretAtEnd,
  currentSearch,
  suggestion,
  withoutHideOnMobileKeyboard,
  isSearchStarted
}) => {
  return (
    <Fragment>
      <span className='Text--black' id='searchFieldLabel'>Search</span>
      <div className={blissModuleName} onClick={setCaretAtEnd}>
        <ContentEditable
          placeholder='Search'
          className={`${blissModuleName}-editable`}
          onChange={searchChange}
          onKeyDown={onKeyDown}
          onRef={setContentEditableRef}
          withoutHideOnMobileKeyboard={withoutHideOnMobileKeyboard}
          isSearchStarted={isSearchStarted}
        >
          { currentSearch }
        </ContentEditable>
        <div className={`${blissModuleName}-suggestion`}>
          { suggestion }
        </div>
      </div>
    </Fragment>
  )
}

FieldWithSuggestion.propTypes = {
  suggestion: PropTypes.string.isRequired,
  currentSearch: PropTypes.string.isRequired,
  updateCurrentSearch: PropTypes.func.isRequired,
  applySuggestion: PropTypes.func.isRequired,
  blissModuleName: PropTypes.string,
  onRef: PropTypes.func,
  searchChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  setContentEditableRef: PropTypes.func.isRequired,
  setCaretAtEnd: PropTypes.func.isRequired,
  withoutHideOnMobileKeyboard: PropTypes.bool,
  isSearchStarted: PropTypes.bool
}

FieldWithSuggestion.defaultProps = {
  withoutHideOnMobileKeyboard: false
}

export default enhance(FieldWithSuggestion)
