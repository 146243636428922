import React from 'react'
import PropTypes from 'prop-types'

const OptionTemplate = ({ name, count }) => (
  <span>
    { name }
    <span className='Multiselect-locationCount'>
      {' '}
      ({ count })
    </span>
  </span>
)

OptionTemplate.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
}

export default OptionTemplate
