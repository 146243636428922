import React from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { isTouchScreen } from '../../../../lib/utils/viewport'
import { noop } from '../../../../lib/utils/common'

const htmlLabel = (name, matchText) => {
  if (matchText) {
    const regexp = new RegExp(matchText, 'gi')
    const resultNameHtml = name.replace(regexp, text => (
      `<span class='Multiselect-matchText'>${text}</span>`
    ))

    return(
      <span dangerouslySetInnerHTML={{__html: resultNameHtml}} />
    )
  }

  return name
}

const enhance = withProps(({option, matchText, optionLabel}) => ({
  labelText: htmlLabel(optionLabel(option), matchText)
}))

const Option = ({
  id, option, optionTemplate, onSelect, isActive, isHighlighted,
  onMouseEnter, onMouseLeave, labelText, activeRef, isSelectedGroup, listType
}) => (
  <div
    id={id}
    className={className('Multiselect-option', { isActive: isHighlighted, isSelectedGroup })}
    onClick={() => { onSelect(option) }}
    onMouseEnter={isTouchScreen() ? noop : () => onMouseEnter(option, listType)}
    onMouseLeave={isTouchScreen() ? noop : () => onMouseLeave(option)}
    ref={activeRef(option)}
    role='option'
    aria-selected={isActive}
    tabIndex='-1'
    onKeyDown={noop}
  >
    <div className='Checkbox'>
      <label className='Checkbox-label'>
        <input
          type='checkbox'
          checked={isActive}
          onChange={noop}
          onClick={e => { e.stopPropagation() }}
          className='Checkbox-input'
          aria-hidden='true'
          tabIndex='-1'
        />
        <span className='Checkbox-icon' role='presentation'/>

        { optionTemplate({option, labelText, isActive}) }
      </label>
    </div>
  </div>
)

Option.defaultProps = {
  matchText: '',
  isSelectedGroup: false
}

Option.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  option: PropTypes.any.isRequired,
  isActive: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  labelText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  matchText: PropTypes.string,
  activeRef: PropTypes.func.isRequired,
  isSelectedGroup: PropTypes.bool,
  id: PropTypes.string.isRequired,
  listType: PropTypes.string.isRequired
}

export default enhance(Option)
