/* eslint no-process-env: 0 */

import createElement from './createElement'
import setDisplayName from './setDisplayName'

const cond = (test, testOptions) => BaseComponent => {
  const factoryCache = {}
  const base = BaseComponent || null

  const Cond = props => {
    const testOption = test(props)

    if (testOption in testOptions) {
      const component = createElement(testOptions[testOption](base))
      const factory = factoryCache[testOption] || component

      factoryCache[testOption] = factory

      return factory(props)
    }

    return createElement(base)(props)
  }

  if (process.env.NODE_ENV !== 'production') {
    const displayName = BaseComponent && BaseComponent.name + '(Cond)' || 'Cond'
    return setDisplayName(displayName)(Cond)
  }

  return Cond
}

export default cond
