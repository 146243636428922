import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent } from 'recompose'

const Placeholder = ({ placeholder }) => (
  <div className='Select-placeholder'>
    { placeholder }
  </div>
)

const SelectedOptionText = ({ selectedOptionTemplate, selectedOption }) => (
  <div className='Select-selectedOptionText'>
    { selectedOptionTemplate(selectedOption) }
  </div>
)

const SelectedOption = branch(
  ({ selectedOption, optionIdentity }) => selectedOption && optionIdentity(selectedOption),
  renderComponent(SelectedOptionText),
  renderComponent(Placeholder)
)

Placeholder.propTypes = {
  placeholder: PropTypes.string
}

SelectedOptionText.propTypes = {
  selectedOptionTemplate: PropTypes.func.isRequired,
  selectedOption: PropTypes.any
}

export default SelectedOption()
