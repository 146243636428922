import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import { RootStore } from './stores'
import { gaEvents, location, transport } from '../../lib/services'
import { eventBus } from '../../lib/utils/dom'
import HeaderSearch from './HeaderSearch'
import { withErrorBoundary } from '../Shared/ErrorBoundary'
import { useTurbolinksReload } from '../../shared_components/Hooks'

const rootStore = new RootStore({ transport, location, gaEvents, eventBus })

const StateFulHeaderSearch = () => {
  useEffect(() => {
    rootStore.fetchInitialData()
  }, [])

  useTurbolinksReload({
    store: rootStore,
    action: store => {
      store.resetSearch()
    }
  })

  return (
    <Provider store={rootStore}>
      <HeaderSearch/>
    </Provider>
  )
}

export default withErrorBoundary(StateFulHeaderSearch)
