import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from '../../../../../lib/propTypes'
import { compose, setPropTypes, withProps, lifecycle } from 'recompose'
import FOCUSABLE_ELEMENT_SELECTORS from '../../../../../lib/constants/focusable'

const enhance = compose(
  setPropTypes({
    setFocusableElements: PropTypes.func.isRequired
  }),
  withProps(() => ({
    contentRef: React.createRef()
  })),
  lifecycle({
    componentDidMount() {
      const contentNode = this.props.contentRef.current
      const focusableElements = contentNode.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)
      this.props.setFocusableElements(Array.from(focusableElements))
    }
  })
)

const OptionsTemplate = ({ children, onClearAll }) => (
  <Fragment>
    {children && children}

    <div className='Multiselect-actions'>
      <div className='Multiselect-action'>
        <button type='button' onClick={onClearAll} className='Button Button--secondary Button--withoutBorder'>
          Clear Selection
        </button>
      </div>
    </div>
  </Fragment>
)

OptionsTemplate.propTypes = {
  onClearAll: PropTypes.func.isRequired,
  children: childrenPropType
}

export default enhance(OptionsTemplate)
