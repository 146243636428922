import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { stripHTMLTags } from '../../../lib/utils/common'
import { sanitizeID } from '../../../lib/utils/string'
import { onKey } from '../../../lib/utils/dom'
import KEY from '../../../lib/constants/key'
import { isPresent } from '../../../lib/utils/collection'
import { useStores } from '../../../shared_components/Hooks'

const FieldWithSuggestion = ({
  blissModuleName = 'FieldWithSuggestion',
  placeholder
}) => {
  const { store: { searchResultsStore, uiStateStore } } = useStores()
  const inputRef = useRef(null)
  const [currentSearch, setCurrentSearch] = useState('')

  useEffect(() => {
    global.requestAnimationFrame(() => inputRef.current.focus())
  }, [])

  const {
    suggestion,
    suggestions,
    updateCurrentSearch,
    fetchSearchResults,
    applySuggestion,
    updateSuggestion,
    activeItem
  } = searchResultsStore

  const { isSearchStarted } = uiStateStore
  const withoutHideOnMobileKeyboard = true

  const dataWithoutHideOnMobileKeyboard =
    withoutHideOnMobileKeyboard ? { 'data-without-hide-on-mobile-keyboard': true } : {}

  const onSearchChange = e => {
    const { value } = e.target

    fetchSearchResults(value)
    setCurrentSearch(value)
  }

  const onKeyDown = e => {
    const autocomplete = item => {
      if (!item) {
        return
      }

      const string = stripHTMLTags(item.string)
      const placeholder = { ...item, string: `<em>${string}</em>` }

      setCurrentSearch(string)
      updateCurrentSearch(string)
      updateSuggestion({ placeholder })
    }

    if (isPresent(suggestions)) {
      onKey(e, KEY.DOWN, () => {
        const nextSuggestion = suggestions.getNext()
        autocomplete(nextSuggestion)
      })
      onKey(e, KEY.UP, () => {
        const prevSuggestion = suggestions.getPrevious()
        autocomplete(prevSuggestion)
      })
      onKey(e, KEY.RETURN, () => {
        setCurrentSearch(currentSearch + suggestion)
        applySuggestion()
      })
    }
  }

  return (
    <Fragment>
      <label className='Label Label--larger' htmlFor={sanitizeID(blissModuleName)}>Search</label>
      <div className={blissModuleName}>
        <div className={`${blissModuleName}-editable`}>
          <input id={sanitizeID(blissModuleName)}
            ref={inputRef}
            className={`${blissModuleName}-input`}
            type='text'
            role='combobox'
            aria-autocomplete='both'
            aria-expanded={isSearchStarted}
            aria-owns='search-results'
            aria-controls='search-results'
            autoComplete='off'
            aria-haspopup='true'
            aria-activedescendant={sanitizeID(activeItem)}
            onChange={onSearchChange}
            onKeyDown={onKeyDown}
            value={currentSearch}
            placeholder={placeholder}
            {...dataWithoutHideOnMobileKeyboard}
          />
        </div>
        <div className={`${blissModuleName}-suggestion`} data-search={currentSearch || placeholder}>
          {suggestion}
        </div>
      </div>
    </Fragment>
  )
}

FieldWithSuggestion.propTypes = {
  blissModuleName: PropTypes.string,
  placeholder: PropTypes.string
}

export default observer(FieldWithSuggestion)
