import humanFormat from 'human-format'
import { isNumeric } from './common'
import { isEmpty, lensFor } from './collection'

export const priceToNumber = price => (
  Number(price.replace(/[^\d\.]/g, ''))
)

export const withCurrency = (value, currencySign = '$') => {
  let displayValue

  if (isNumeric(value)) {
    displayValue = parseFloat(value).toLocaleString('en-US', {
      currency: 'USD',
      minimumFractionDigits: 0
    })
  } else {
    displayValue = value
  }

  return `${currencySign}${displayValue}`
}

export const numberShortFormat = number => (
  humanFormat(number, { separator: '' })
)

export const priceLabelWithCutOff = (v, l) => (
  v >= l ? `${withCurrency(numberShortFormat(l))}+` : withCurrency(numberShortFormat(v))
)

export const mapFromPriceValue = value => isEmpty(value) || value.trim() === '$' ? null : priceToNumber(value)

export const mapToPriceValue = name => form => {
  const value = lensFor(name).get(form).getOr(null)
  const priceValue = isEmpty(value) ? '' : value
  return withCurrency(priceValue, '$ ')
}
