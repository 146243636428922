import * as yup from 'yup'

const emailSchema = yup.string().email().required()

export const isEmailValid = value => {
  let result = true

  try {
    emailSchema.validateSync(value)
  } catch(_) {
    result = false
  }

  return result
}
