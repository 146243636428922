import React from 'react'
import PropTypes from 'prop-types'
import { withStateHandlers, withState, withHandlers, withProps, defaultProps, lifecycle, compose } from 'recompose'
import cn from 'classnames'
import * as R from 'ramda'
import { removeByIndex, isPresent, isEmpty } from '../../../../lib/utils/collection'
import { noop } from '../../../../lib/utils/common'
import { fromContentEditable, onKey, getClipboardText } from '../../../../lib/utils/dom'
import SearchField from '../SearchField'
import FilterPill from '../FilterPill'

const PASTE_SPLIT_REGEX = /,[\s]*|[\s]+/

const enhance = compose(
  defaultProps({
    breakKeys: ['Enter'],
    validateText: isPresent,
    onChange: noop,
    onTextChange: noop,
    setParentFocus: noop
  }),

  withHandlers(() => {
    let editableRef

    return {
      setEditableRef: () => el => {
        editableRef = el
      },
      editableFocus: () => () => {
        editableRef.editable.focus()
      }
    }
  }),

  withState('isActive', 'setActive', false),

  withState('tokens', 'setTokens', ({ value = [] }) => value),
  withHandlers({
    addToken: ({ setTokens }) => name => {
      setTokens(tokens => {
        if (tokens) {
          if (tokens.includes(name)) {
            return tokens
          }
          return [...tokens, name]
        }
        return [name]
      })
    },
    removeToken: ({ setTokens, editableFocus }) => index => () => {
      setTokens(tokens => removeByIndex(tokens, index))
      editableFocus()
    }
  }),

  withHandlers(() => {
    let tokenFieldContentRef

    return {
      setTokenFieldContentRef: () => el => {
        tokenFieldContentRef = el
      },
      scrollTokenFieldContent: () => () => {
        tokenFieldContentRef.scrollTo(0, tokenFieldContentRef.scrollHeight)
      }
    }
  }),

  withStateHandlers(
    { text: '' },
    {
      resetText: () => () => ({ text: '' }),
      setText: () => value => ({ text: fromContentEditable(value) })
    }
  ),

  withHandlers({
    validateTextAndAddToken: ({ text, validateText, resetText, addToken }) => () => {
      if (validateText(text)) {
        addToken(text)
        resetText()
      }
    },
    validateListAndAddTokens: ({ validateText, resetText, addToken }) => phrases => {
      phrases.split(PASTE_SPLIT_REGEX).forEach(text => {
        if (validateText(text)) {
          addToken(text)
        }
      })

      setTimeout(resetText, 50)
    },
    isPillInvalid: ({ meta, name }) => pillText => R.pipe(
      R.pathOr([], ['errors', name]),
      R.any(R.propEq('value', pillText))
    )(meta),
    hasWarning: ({meta, name }) => pillText => R.pipe(
      R.pathOr([], ['context', 'warnings', name, 'values']),
      R.includes(pillText)
    )(meta)
  }),

  withHandlers({
    searchFieldKeyPress: ({ breakKeys, validateTextAndAddToken, scrollTokenFieldContent }) => e => {
      breakKeys.forEach(name => {
        onKey(e, name, () => {
          validateTextAndAddToken()
          setTimeout(scrollTokenFieldContent, 150)
        })
      })
    },
    searchFieldTextPaste: ({ text, setText, validateListAndAddTokens }) => e => {
      e.preventDefault()
      const clipBoardText = getClipboardText(e)
      const inputText = text + clipBoardText
      document.execCommand('insertHTML', false, clipBoardText)

      setText(inputText)
      validateListAndAddTokens(inputText)
    },
    searchFieldFocus: ({ setActive, setParentFocus }) => () => {
      setParentFocus(true)
      setActive(true)
    },
    searchFieldBlur: ({ validateTextAndAddToken, setActive }) => () => {
      validateTextAndAddToken()
      setActive(false)
    },
    searchFieldChange: ({ setText, onTextChange }) => ({ target: { value } }) => {
      setText(value)
      onTextChange(value)
    }
  }),

  withProps(({ isActive, tokens, text }) => ({
    hasPlaceholder: !isActive && isEmpty(tokens) && isEmpty(text)
  })),

  lifecycle({
    componentDidUpdate({ tokens, onChange }) {
      if (this.props.tokens !== tokens) {
        onChange(this.props.tokens)
      }
    },
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (R.not(R.equals(nextProps.value, this.props.value))) {
        this.props.setTokens(nextProps.value)
        this.props.resetText()
      }
    }
  })
)

const TokenField = ({
  isPillInvalid,
  hasWarning,
  text,
  isActive,
  hasPlaceholder,
  placeholder,
  className,
  tokens,
  removeToken,
  setEditableRef,
  editableFocus,
  setTokenFieldContentRef,
  searchFieldKeyPress,
  searchFieldTextPaste,
  searchFieldBlur,
  searchFieldFocus,
  searchFieldChange
}) => (
  <div className={cn('TokenField', className, { isActive, hasPlaceholder })} onClick={editableFocus}>
    <div className='TokenField-content' ref={setTokenFieldContentRef}>
      { hasPlaceholder &&
        <div className='TokenField-placeholder'>{placeholder}</div>
      }
      <div className='TokenField-pills'>
        { tokens && tokens.map((name, i) => (
          <div className='TokenField-pill' key={name}>
            <FilterPill
              content={name}
              isInvalid={isPillInvalid(name)}
              onDestroy={removeToken(i)}
              hasWarning={hasWarning(name)}
            />
          </div>
        )) }

        <SearchField
          isActive={true}
          placeholder={null}
          className='TokenField-search'
          editableClassName='TokenField-searchField'
          onChange={searchFieldChange}
          onKeyDown={noop}
          onKeyPress={searchFieldKeyPress}
          onPaste={searchFieldTextPaste}
          onBlur={searchFieldBlur}
          onFocus={searchFieldFocus}
          editableRef={setEditableRef}
          text={text}
        />
      </div>
    </div>
  </div>
)

TokenField.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hasPlaceholder: PropTypes.bool,
  setText: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  tokens: PropTypes.arrayOf(PropTypes.string),
  removeToken: PropTypes.func.isRequired,
  breakKeys: PropTypes.array,
  isPillInvalid: PropTypes.func,
  hasWarning: PropTypes.func,
  setEditableRef: PropTypes.func,
  editableFocus: PropTypes.func,
  setTokenFieldContentRef: PropTypes.func,
  scrollTokenFieldContent: PropTypes.func,
  searchFieldKeyPress: PropTypes.func.isRequired,
  searchFieldTextPaste: PropTypes.func.isRequired,
  searchFieldFocus: PropTypes.func,
  searchFieldBlur: PropTypes.func,
  searchFieldChange: PropTypes.func,
  validateTextAndAddToken: PropTypes.func.isRequired,
  validateText: PropTypes.func,
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  onTextChange: PropTypes.func
}

export default enhance(TokenField)
