import React from 'react'
import PropTypes from 'prop-types'
import { CloseUrl } from '../../Svg'

const CloseButtonTemplate = ({ onClick, closeButtonRef = null }) => (
  <button type='button' className='Modal-close' onClick={onClick} ref={closeButtonRef}>
    <img className='Modal-closeImage' src={CloseUrl} alt='close' />
  </button>
)

CloseButtonTemplate.propTypes = {
  onClick: PropTypes.func.required,
  closeButtonRef: PropTypes.object
}

export default CloseButtonTemplate
