/* eslint no-console: 0 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(_error, info) {
    this.setState({ hasError: true })
    console.error(info)
  }

  render() {
    if (this.state.hasError) {
      return <div className='HasErrorInReactComponent' />
    }

    return this.props.children
  }
}

export default ErrorBoundary
