import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ClickOutHandler from 'react-onclickout'
import classNames from 'classnames'
import * as R from 'ramda'
import { onKey, watchKey } from '../../../../lib/utils/dom'
import { isTouchScreen } from '../../../../lib/utils/viewport'
import Options from './Options'
import SearchField from './SearchField'
import SelectedOption from './SelectedOption'
import { isEmptyObject, noop } from '../../../../lib/utils/common'

class SearchableSelect extends Component {
  _isMounted = false

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onSearchTextChange: PropTypes.func,
    onActiveChanged: PropTypes.func,
    optionIdentity: PropTypes.func,
    filterIdentity: PropTypes.func,
    optionTemplate: PropTypes.func,
    selectedOptionTemplate: PropTypes.func,
    skipMatchWrapping: PropTypes.bool,
    height: PropTypes.number,
    tabindex: PropTypes.number,
    wrapperComponent: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    scrollToTopOnActive: PropTypes.func,
    isDisabled: PropTypes.bool
  }

  static defaultProps = {
    options: [],
    value: '',
    optionIdentity: R.prop('id'),
    filterIdentity: R.prop('name'),
    optionTemplate: R.prop('name'),
    skipMatchWrapping: false,
    onChange: noop,
    onSearchTextChange: noop,
    onActiveChanged: noop,
    height: 53,
    tabindex: 0,
    wrapperComponent: (({children}) => children),
    scrollToTopOnActive: noop,
    isDisabled: false
  }

  state = {
    ...this.getInitialState(this.props),
    isActive: false,
    selectedIndex: 0,
    searchText: ''
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(_, prevState) {
    if (prevState.isActive !== this.state.isActive) {
      this.props.onActiveChanged(this.state.isActive)
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState(() => this.getInitialState(props))
  }

  getInitialState({ value, options, optionIdentity }) {
    return {
      value: options.find(o => optionIdentity(o) === value) || {}
    }
  }

  setActiveOption = option => {
    let index = this.availableOptions.findIndex(o => o === option)

    if (index === -1) {
      index = this.availableAdditionalOptions.findIndex(o => o === option)
      index = index + this.availableOptions.length
    }

    this.setState(() => ({
      selectedIndex: index
    }))
  }

  unSetActiveOption = () => {}

  nextActiveOption = () => {
    const max = this.availableOptions.length - 1

    this.setState(state => ({
      selectedIndex: Math.min(state.selectedIndex + 1, max)
    }))
  }

  prevActiveOption = () => {
    const min = 0

    this.setState(state => ({
      selectedIndex: Math.max(state.selectedIndex - 1, min)
    }))
  }

  applySelectedOption = () => {
    const option = this.highlightedOption

    if (option) {
      this.onSelect(option)
    }
  }

  sortByMatch(a, b) {
    const { filterIdentity } = this.props
    const { searchText } = this.state
    const optionA = filterIdentity(a).toLocaleLowerCase().match(searchText.toLowerCase()).index
    const optionB = filterIdentity(b).toLocaleLowerCase().match(searchText.toLowerCase()).index

    return optionA - optionB
  }

  get availableOptions() {
    const { options, filterIdentity } = this.props
    const { searchText } = this.state
    let result = options

    if (searchText !== '') {
      result = result
        .filter(o => filterIdentity(o).toLocaleLowerCase().includes(searchText.toLowerCase()))
        .sort((a, b) => this.sortByMatch(a, b))
    }

    return result
  }

  get style() {
    const { height } = this.props

    return { height: height }
  }

  get hasSelected() {
    return !isEmptyObject(this.state.value)
  }

  get hasOptions() {
    return this.availableOptions.length > 0
  }

  get highlightedOption() {
    const { selectedIndex } = this.state

    return this.availableOptions[selectedIndex] || this.availableOptions[0]
  }

  get searchFieldPlaceHolder() {
    const { selectedOptionTemplate, optionTemplate, placeholder } = this.props

    return (
      this.hasSelected
        ? (selectedOptionTemplate || optionTemplate)(this.state.value)
        : placeholder
    )
  }

  onKeyDown = e => {
    onKey(e, 'ArrowDown', this.nextActiveOption)
    onKey(e, 'ArrowUp', this.prevActiveOption)
    onKey(e, 'Escape', this.onDeactivateOptions)
  }

  onKeyPress = e => {
    watchKey(e, 'Enter', this.onEnterPress)
  }

  onEnterPress = e => {
    if (!isTouchScreen() || !this.hasSelected) {
      e.preventDefault()
      this.applySelectedOption()
      this.onDeactivateOptions()
    }
  }

  onSelect = option => {
    const { onChange, optionIdentity } = this.props

    this.setActiveOption(option)

    this.setState({ value: option },
      () => {
        onChange(optionIdentity(this.state.value))
      }
    )
  }

  onActivateOptions = () => {
    if (this.props.isDisabled) {return}

    this.setState(state => ({ isActive: !state.isActive, searchText: '', selectedIndex: 0 }), () => {
      this.props.onActiveChanged(this.state.isActive)
      this.props.scrollToTopOnActive()
    })
  }

  onDeactivateOptions = () => {
    if (!this._isMounted) {
      return
    }

    this.setState({
      isActive: false,
      selectedIndex: 0,
      searchText: ''
    })
  }

  onSearch = ({target: {value}}) => {
    this.props.onSearchTextChange(value)
    this.setState(() => ({ searchText: value }))
  }

  render() {
    const {
      optionIdentity, optionTemplate, selectedOptionTemplate, skipMatchWrapping,
      tabindex, wrapperComponent, className, placeholder, options, isDisabled, ...otherProps
    } = this.props
    const { isActive, searchText } = this.state
    const hasSelected = this.hasSelected
    const WrapperComponent = wrapperComponent
    const cn = classNames('Multiselect', className, { isActive, hasSelected, isDisabled })

    return (
      <ClickOutHandler onClickOut={this.onDeactivateOptions}>
        <div
          className={cn}
          style={this.style}
          onClick={this.onActivateOptions}
        >
          <WrapperComponent {...this.props}>
            <div className='Multiselect-content'>
              <div className='Multiselect-selectedOptions'>
                <SelectedOption
                  placeholder={placeholder}
                  isActive={isActive}
                  hasSelected={this.hasSelected}
                  value={this.state.value}
                  optionTemplate={selectedOptionTemplate || optionTemplate}
                />
                <SearchField
                  isActive={isActive}
                  placeholder={this.searchFieldPlaceHolder}
                  onChange={this.onSearch}
                  onBlur={this.onDeactivateOptions}
                  onKeyDown={this.onKeyDown}
                  onKeyPress={this.onKeyPress}
                  text={searchText}
                />
              </div>

              <div
                className='Multiselect-focusDetector'
                tabIndex={isActive ? null : tabindex}
                onFocus={this.onActivateOptions}
              />

              <Options
                isActive={isActive && this.hasOptions}
                selectedOption={this.state.value}
                highlightedOption={this.highlightedOption}
                options={this.availableOptions}
                rawOptions={options}
                optionTemplate={optionTemplate}
                optionIdentity={optionIdentity}
                onSelect={this.onSelect}
                onMouseEnter={this.setActiveOption}
                onMouseLeave={this.unSetActiveOption}
                matchText={searchText}
                skipMatchWrapping={skipMatchWrapping}
                hasOptions={this.hasOptions}
                {...otherProps}
              />
            </div>
          </WrapperComponent>
        </div>
      </ClickOutHandler>
    )
  }
}

export default SearchableSelect
