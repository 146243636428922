import React from 'react'
import ReactDOM from 'react-dom'
import HeaderSearchCloseButton from './HeaderSearchCloseButton'

const CloseButtonMobile = props => {
  return ReactDOM.createPortal(
    <HeaderSearchCloseButton {...props}/>,
    document.querySelector('[data-header-search-close-icon=container]')
  )
}

export default CloseButtonMobile
