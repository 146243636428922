import React, { Fragment, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from '../../../../../lib/propTypes'
import FOCUSABLE_ELEMENT_SELECTORS from '../../../../../lib/constants/focusable'
import withMap from './withMap'

const ViewMapOptions = ({
  setFocusableElements,
  openMapView,
  onKeyDown,
  children
}) => {
  const contentRef = useRef()

  useEffect(() => {
    const focusableElements = contentRef.current.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)
    setFocusableElements(Array.from(focusableElements))
  }, [setFocusableElements])

  return (
    <Fragment>
      {children && children}

      <div className='Multiselect-actions' role='presentation' ref={contentRef} onKeyDown={onKeyDown}>
        <div className='Multiselect-action exclude-mobile-viewport'>
          <button onClick={openMapView} className='Button Button--secondary Button--withoutBorder'>
            View map
          </button>
        </div>
      </div>
    </Fragment>
  )
}

ViewMapOptions.propTypes = {
  setFocusableElements: PropTypes.func.isRequired,
  openMapView: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  children: childrenPropType
}

export default withMap(ViewMapOptions)
