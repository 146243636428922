import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withState, withHandlers, compose } from 'recompose'
import Modal from '../../../../Shared/Modal'
import AreaMapSelector from './AreaMapSelector'
import MapSelectorActions from './MapSelectorActions'

export const enhanceWithMap = compose(
  withState('isMapOpen', 'setMapOpen', false),
  withHandlers({
    openMapView: ({ setMapOpen }) => e => {
      e.preventDefault()
      setMapOpen(true)
    }
  })
)

const withMap = WrappedComponent => {
  const Component = props => (
    <Fragment>
      <WrappedComponent {...props} />
      <Modal
        isOpen={props.isMapOpen}
        onRequestClose={() => { props.setMapOpen(false) }}
        ariaHideApp={false}
        contentLabel='Area map'
        portalClassName='ReactModalPortal exclude-mobile-viewport'
        className='Modal-content Modal-content--tablet-fullWidth'
      >
        <AreaMapSelector
          {...props}
          areaMapSelectorActions={() => (
            <MapSelectorActions
              {...props}
              onRequestClose={() => { props.setMapOpen(false) }} />
          )}
        />
      </Modal>
    </Fragment>
  )

  Component.propTypes = {
    isMapOpen: PropTypes.bool.isRequired,
    setMapOpen: PropTypes.func.isRequired
  }

  return Component
}

export default compose(enhanceWithMap, withMap)
