import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import { lifecycle, withHandlers, compose } from 'recompose'
import { Location } from '../../../Svg'

const enhance = compose(
  withHandlers(() => {
    let locationWrapper

    return {
      registerRef: () => ref => {
        locationWrapper = ref
      },
      scrollBottom: () => () => {
        locationWrapper.scrollTop = locationWrapper.scrollHeight
      }
    }
  }),
  lifecycle({
    componentDidUpdate() {
      this.props.scrollBottom()
    }
  })
)

const TownWrapper = ({ children, townWrapperClassName, registerRef }) => (
  <div
    className={className('Multiselect-locationWrapper', townWrapperClassName)}
    ref={registerRef}
  >
    <div className='Multiselect-locationIcon'>
      <Location />
    </div>
    <div className='Multiselect-locations'>
      { children }
    </div>
  </div>
)

TownWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  townWrapperClassName: PropTypes.string,
  registerRef: PropTypes.func.isRequired
}

export default enhance(TownWrapper)
