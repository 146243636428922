import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import Card from '../../Shared/Card'
import { redirect } from '../../../lib/utils/common'
import { useStores } from '../../../shared_components/Hooks'

const RecentViews = () => {
  const { store: { recentViewsStore } } = useStores()
  const updateSaveStatus = recentViewsStore.updateSaveStatus.bind(recentViewsStore)

  const handleOnSave = useCallback((listing, e) => {
    e.preventDefault()
    e.stopPropagation()
    updateSaveStatus(listing)
  }, [])

  const handleOnClick = (listing, e) => {
    e.stopPropagation()
    redirect(listing.links.self)
  }

  return (<div className='Cards'>
    <h3 className='Cards-title'>
      RECENTLY VIEWED
    </h3>
    <ul className='Cards-items'>
      {recentViewsStore.collection.map(item => (
        <li
          key={item.links.self}
          className='Cards-item'
          onClick={() => handleOnClick.bind(null, item)}
        >
          <Card item={item} onSave={handleOnSave.bind(this, item)}/>
        </li>
      ))}
    </ul>
  </div>
  )
}

export default observer(RecentViews)
