import { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { scrollIntoView } from '../../lib/utils/dom'
import { isTouchScreen } from '../../lib/utils/viewport'

const useScrollToActiveOption = activeOption => {
  const activeOptionRef = useRef(null)
  const optionsRef = useRef(null)

  const setActiveOptionRef = useCallback(optionID => el => {
    if (activeOption === optionID) {
      activeOptionRef.current = el
    }
  }, [activeOption])

  const setOptionsRef = useCallback(el => {
    optionsRef.current = el
  }, [])

  const scrollIntoViewOnActive = useCallback(() => {
    if (activeOptionRef.current && optionsRef.current) {
      scrollIntoView(activeOptionRef.current, optionsRef.current)
    }
  }, [activeOption])

  useEffect(() => {
    if (!isTouchScreen()) {
      scrollIntoViewOnActive()
    }
  }, [activeOption, scrollIntoViewOnActive])

  return {
    setActiveOptionRef,
    setOptionsRef
  }
}

useScrollToActiveOption.propTypes = {
  activeOption: PropTypes.any
}

export default useScrollToActiveOption
