/* eslint react/no-did-mount-set-state: 0 */

import PropTypes from 'prop-types'
import { Component } from 'react'
import { isArray, isDefined, isServer } from '../../../lib/utils/common'
import { isViewport } from '../../../lib/utils/viewport'
import { childrenPropType } from '../../../lib/propTypes'

const ViewportType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.string
  )
])

class Viewport extends Component {
  static propTypes = {
    children: childrenPropType.isRequired,
    only: ViewportType,
    except: ViewportType
  }

  subscriptions = {}
  state = { isActive: this.shouldDisplay }

  componentDidMount() {
    this.setState({ isActive: this.shouldDisplay })
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.setState({ isActive: this.shouldDisplay })
    this.subscriptions.resize = this.onResize.bind(this)

    if (!isServer()) {
      global.addEventListener('resize', this.subscriptions.resize)
    }
  }

  componentWillUnmount() {
    if (!isServer()) {
      global.removeEventListener('resize', this.subscriptions.resize)
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.onResize()
  }

  onResize() {
    this.setState({ isActive: this.shouldDisplay })
  }

  get displayOnly() {
    let { only } = this.props
    only = isArray(only) ? only : [only]
    return only.some(name => isViewport(name))
  }

  get displayExcept() {
    let { except } = this.props
    except = isArray(except) ? except : [except]
    return except.every(name => !isViewport(name))
  }

  get shouldDisplay() {
    const { only } = this.props
    return isDefined(only) ? this.displayOnly : this.displayExcept
  }

  render() {
    const { isActive } = this.state

    return isActive ? this.props.children : null
  }
}

export default Viewport
