import React from 'react'
import PropTypes from 'prop-types'
import PillEllipsis from './PillEllipsis'
import Option from './Option'

const OptionsWithEllipsis = ({ height, options, optionIdentity, selectedOptionTemplate, onDestroy }) => (
  <PillEllipsis height={height}>
    {
      options.map(option => (
        <Option
          key={optionIdentity(option)}
          option={option}
          onDestroy={onDestroy}
          optionIdentity={optionIdentity}
          selectedOptionTemplate={selectedOptionTemplate}
        />
      ))
    }
  </PillEllipsis>
)

OptionsWithEllipsis.propTypes = {
  height: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionIdentity: PropTypes.func.isRequired,
  selectedOptionTemplate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired
}

export default OptionsWithEllipsis
