import * as R from 'ramda'
import { isPresent } from '../../../../../lib/utils/collection'
import { deepFindChildrenId } from '../../../../../lib/dataMappings/treeUtils'

export const selectedOptionFilter = options => options.filter(option => (
  option.children || !options.includes(option.parent)
))

export const includesSubstring = identity => (option, substring) => (
  identity(option).toLocaleLowerCase().includes(substring.toLowerCase())
)

export const filterWithSearchText = ({ searchText }, { filterIdentity }) => options => {
  const matchSearch = includesSubstring(filterIdentity)

  return options.filter(o => {
    if (isPresent(o.children)) {
      const allChildrenId = deepFindChildrenId(o.children)(options)
      const childrenNodes = R.filter(node => R.includes(node.id, allChildrenId))(options)

      const isSelfMatch = matchSearch(o, searchText)
      const isAnyChildrenMatch = childrenNodes.some(c => matchSearch(c, searchText))

      return isSelfMatch || isAnyChildrenMatch
    }

    return matchSearch(o, searchText)
  })
}
