import React from 'react'
import PropTypes from 'prop-types'
import { handleAriaLabel } from './constants'

const clickHandler = event => {
  event.stopPropagation()
  event.preventDefault()
  event.target.focus()
}

const Handle = props => {
  const handleKey = props['data-handle-key']
  return (<button {...props} type='button' aria-label={handleAriaLabel[handleKey]} onClick={clickHandler}/>)
}

Handle.propTypes = {
  'data-handle-key': PropTypes.number.isRequired
}

export default Handle
