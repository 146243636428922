import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { listingPropType } from '../../../lib/propTypes'
import { PlaceHolderHouseUrl } from '../../Svg'
import { OpenHouse, StatusInfo, KeyAmenityLabel, PriceChange, DetailInfo } from './Parts'
import { useReplaceBrokenImage } from '../../../shared_components/Hooks'

const Card = ({ item, onSave, className = '' }) => {
  const { src, source, imgRef } = useReplaceBrokenImage({
    src: item.featuredImageUrl.jpeg,
    source: { srcSet: item.featuredImageUrl.webp, type: 'image/webp' },
    fallback: { src: PlaceHolderHouseUrl, type: 'image/png' }
  })

  return (
    <div className={cn('Card Card--withoutBorder', className)}>
      <a href={item.links.self} className='Card-link'>
        <div className='Card-imageContainer'>
          <StatusInfo status={item.statusInfo}/>

          <div className='Card-aspectRatioContent'>
            <picture>
              <source srcSet={source.srcSet} type={source.type}/>
              <img
                ref={imgRef}
                src={src}
                className='Card-image'
                alt=''
              />
            </picture>
          </div>

          <OpenHouse item={item.closestOpenHouse}/>
        </div>

        <div className='Card-content'>
          <div className='Card-address'>{item.address || '(Undisclosed Address)'}</div>
          <h4 className='Card-region'>{item.townName}</h4>

          <div className='Card-price'>
            <span className='Card-priceNumber'>{item.price}</span>
            <PriceChange change={item.priceChange}/>
          </div>

          <DetailInfo item={item}/>

          <div className='Card-keyAmenity'>
            <KeyAmenityLabel item={item.keyAmenityLabel}/>
          </div>

          <div className='Card-listingByContent'>
            <div className='Card-listingBy withEllipsis'>
              {item.listingBy}
            </div>

            <div className='Card-saveAction' onClick={onSave} role='presentation'>
              <button className={cn('Button Button--saveLink', { isActive: item.saved })}/>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

Card.propTypes = {
  item: listingPropType.isRequired,
  onSave: PropTypes.func,
  className: PropTypes.string
}

export default Card
