import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { branch, renderComponent, renderNothing, compose, withProps, lifecycle } from 'recompose'
import * as R from 'ramda'
import { childrenPropType } from '../../../lib/propTypes'
import FOCUSABLE_ELEMENT_SELECTORS from '../../../lib/constants/focusable'

const enhance = compose(
  withProps(() => ({
    contentRef: React.createRef()
  })),
  lifecycle({
    componentDidMount() {
      const contentNode = this.props.contentRef.current
      const focusableElements = contentNode.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)
      this.props.setFocusableElements(Array.from(focusableElements))

      if (focusableElements.item(0)) {
        focusableElements.item(0).focus({ preventScroll: true })
      }
    }
  })
)

const DropdownContentActive = enhance(({ children, contentRef, ariaLabelledby, contentClassName, ...props }) => (
  <div className={cn('Dropdown-content', contentClassName)}
    ref={contentRef}
    role='dialog'
    aria-labelledby={ariaLabelledby}
    {...R.omit(['isActive', 'setFocusableElements'], props)}
  >
    {children}
  </div>
))

DropdownContentActive.propTypes = {
  ariaLabelledby: PropTypes.string.isRequired,
  children: childrenPropType
}

const DropdownContent = branch(
  ({ isActive }) => isActive,
  renderComponent(DropdownContentActive),
  renderNothing
)

DropdownContent.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default DropdownContent()
