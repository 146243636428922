import React from 'react'
import { branch, renderComponent, renderNothing } from 'recompose'
import cn from 'classnames'
import PropTypes from 'prop-types'

const StatusInfo = ({ status }) => (
  <div className='CardPreview-status'>
    <div className={cn('StatusFlag', `StatusFlag--${status.type}`)} >
      <div className='StatusFlag-text'>
        { status.label }
      </div>
    </div>
  </div>
)

StatusInfo.propTypes = {
  status: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string
  })
}

export default branch(
  ({ status }) => status,
  renderComponent(StatusInfo),
  renderNothing
)()
