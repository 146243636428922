import React from 'react'
import PropTypes from 'prop-types'

const Placeholder = ({ children }) => (
  <div className='Multiselect-placeholder'>
    { children }
  </div>
)

Placeholder.propTypes = {
  children: PropTypes.string.isRequired
}

export default Placeholder
