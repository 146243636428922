import React from 'react'
import OptionTemplate from './OptionTemplate'
import CheckySelect from '../../../../components/Shared/Form/CheckySelect'
import AgentWrapper from './AgentWrapper'
import OptionsWrapper from './OptionsWrapper'

const selectedOptions = options => options.map(o => o.lastName).sort().join(', ')

const CheckyAgentSelect = props => (
  <CheckySelect
    wrapperComponent={AgentWrapper}
    wrapperOptionsComponent={OptionsWrapper}
    selectedOptionsTemplate={selectedOptions}
    optionTemplate={OptionTemplate}
    {...props}
  />
)

export default CheckyAgentSelect
