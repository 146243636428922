import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { branch, renderComponent, renderNothing } from 'recompose'
import { sanitizeID } from '../../../../lib/utils/string'
import FOCUSABLE_ELEMENT_SELECTORS from '../../../../lib/constants/focusable'
import { withScrollToActiveOption } from '../../../Hocs'
import OptionScroller from '../Shared/OptionScroller'
import Option from './Option'
import { getChildren } from '../../../../lib/utils/selectors'

const ActiveOptions = props => {
  const {
    id,
    ariaLabelledBy,
    optionClassName,
    options,
    selectedOption,
    highlightedOption,
    setOptionsRef,
    setActiveOptionRef,
    setFocusableElements,
    handleSelect,
    onKeyDownContent,
    optionIdentity,
    optionTemplate,
    WrapperOptionsComponent,
    withScrollIntoView
  } = props

  const contentRef = useRef(null)

  useEffect(() => {
    const contentNode = contentRef.current
    const focusableElements = contentNode.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)
    setFocusableElements(Array.from(focusableElements))

    if (withScrollIntoView) {
      contentNode.scrollIntoView()
    }

    contentRef.current.addEventListener('keydown', onKeyDownContent)
  }, [])

  return (
    <div className='Select-optionsContainer' ref={contentRef}>
      <WrapperOptionsComponent {...props}>
        <OptionScroller setOptionsRef={setOptionsRef}>
          <ul id={id} role='listbox' className='Select-listOptions' aria-labelledby={ariaLabelledBy} >
            {
              options.map(option => (
                <Option
                  id={sanitizeID(optionIdentity(option))}
                  key={optionIdentity(option)}
                  className={optionClassName}
                  option={option}
                  optionTemplate={optionTemplate}
                  optionIdentity={optionIdentity}
                  onSelect={handleSelect}
                  isHighlighted={option === highlightedOption}
                  isActive={option === selectedOption}
                  activeRef={setActiveOptionRef}
                />
              ))
            }
          </ul>
        </OptionScroller>
      </WrapperOptionsComponent>
    </div>
  )
}

ActiveOptions.defaultProps = {
  WrapperOptionsComponent: (getChildren)
}

ActiveOptions.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabelledBy: PropTypes.string.isRequired,
  optionClassName: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.array,
    MobxPropTypes.arrayOrObservableArray
  ]).isRequired,
  selectedOption: PropTypes.any,
  highlightedOption: PropTypes.any,
  setOptionsRef: PropTypes.func.isRequired,
  setActiveOptionRef: PropTypes.func.isRequired,
  setFocusableElements: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  onKeyDownContent: PropTypes.func.isRequired,
  optionIdentity: PropTypes.func.isRequired,
  optionTemplate: PropTypes.func.isRequired,
  WrapperOptionsComponent: PropTypes.func.isRequired,
  withScrollIntoView: PropTypes.bool
}

const Options = branch(
  ({ isActive }) => isActive,
  renderComponent(withScrollToActiveOption(ActiveOptions)),
  renderNothing
)()

export default Options
