import React from 'react'
import PropTypes from 'prop-types'
import Search from '../Svg/Search'

const SearchField = ({ onKeyDown, onClick }) => (
  <button
    onClick={onClick}
    onKeyDown={onKeyDown}
    className='HeaderSearch-field'
    aria-label='Search'
    data-without-hide-on-mobile-keyboard
  >
    <span className='HeaderSearch-fieldContent'>
      <span className='HeaderSearch-fieldText'>Search</span>
      <Search className='search-svg'/>
    </span>
  </button>
)

SearchField.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired
}

export default SearchField
