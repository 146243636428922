export const zoomBreakPoint = 18
export const defaultZoom = 16

export const commonGoogleMapOptions = {
  minZoom: 10,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  rotateControl: false,
  fullscreenControl: false
}

export const DEFAULT_CENTER = { lat: 40.955083, lng: -72.359722 }
