const useFormSubmitHandler = () => {
  let formRef

  const setFormRef = el => {
    formRef = el
  }

  const performFormSubmit = () => {
    formRef.submit()
  }

  return { setFormRef, performFormSubmit }
}

export default useFormSubmitHandler
