import PropTypes from 'prop-types'

export const checkySelectPropTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  optionIdentity: PropTypes.func,
  selectedOptionsTemplate: PropTypes.func,
  optionTemplate: PropTypes.func,
  className: PropTypes.string,
  wrapperComponent: PropTypes.func,
  wrapperOptionsComponent: PropTypes.func,
  wrapperClassName: PropTypes.string,
  withClearAll: PropTypes.bool,
  isDisabled: PropTypes.bool,
  withScrollIntoView: PropTypes.bool
}
