import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useScrollToActiveOption, useStores } from '../../../shared_components/Hooks'
import { sanitizeID } from '../../../lib/utils/string'
import Viewport from '../../Shared/Viewport'
import SearchResults from './SearchResults'
import RecentSearches from './RecentSearches'
import RecentViews from './RecentViews'
import FieldWithSuggestion from './FieldWithSuggestion'
import HeaderSearchCloseButton from './HeaderSearchCloseButton'
import CloseButtonMobile from './CloseButtonMobile'

const SearchResultsPanel = ({ activeID, isResultsPresent, results }) => {
  const { setOptionsRef, setActiveOptionRef } = useScrollToActiveOption(activeID)

  return (
    <div className='SearchPanel-searchResults' ref={setOptionsRef}>
      <SearchResults
        isResultsPresent={isResultsPresent}
        results={results}
        activeID={activeID}
        setActiveOptionRef={setActiveOptionRef}
      />
    </div>
  )
}

const RecentSearchesPanel = ({ isRecentSearchesPresent }) => (
  <div className='SearchPanel-recentSearches'>
    {isRecentSearchesPresent && <RecentSearches/>}
  </div>
)

const SearchPanel = props => {
  const { store: { uiStateStore, searchResultsStore} } = useStores()

  const {
    isSearchStarted, isRecentViewsPresent, isRecentSearchesPresent
  } = uiStateStore

  const {
    isResultsPresent, results, activeItem
  } = searchResultsStore

  return (
    <div className='SearchPanel'>
      <div className='SearchPanel-content'>
        <div className='SearchPanel-fieldContainer'>
          <h2 className='SearchPanel-searchTip' id='searchFieldTip'> Start typing an address, town, or area </h2>

          <div className='SearchPanel-searchField'>
            <FieldWithSuggestion
              blissModuleName='ExpandedHeaderSearch'
              placeholder='Address, town, or area'
            />
          </div>
        </div>

        {
          isSearchStarted
            ? <SearchResultsPanel
              activeID={sanitizeID(activeItem)}
              isResultsPresent={isResultsPresent}
              results={results}
            />
            : <RecentSearchesPanel isRecentSearchesPresent={isRecentSearchesPresent}/>
        }

        <div className='SearchPanel-recentViews'>
          { isRecentViewsPresent && <RecentViews /> }
        </div>
      </div>

      <Viewport only={'desktop'}>
        <HeaderSearchCloseButton onClick={props.deactivateSearch}/>
      </Viewport>

      <Viewport only={['tablet', 'mobile']}>
        <CloseButtonMobile onClick={props.deactivateSearch}/>
      </Viewport>
    </div>
  )
}

SearchPanel.propTypes = {
  deactivateSearch: PropTypes.func.isRequired
}

SearchResultsPanel.propTypes = {
  activeID: PropTypes.string,
  isResultsPresent: PropTypes.bool,
  results: PropTypes.object
}

RecentSearchesPanel.propTypes = {
  isRecentSearchesPresent: PropTypes.bool
}

export default observer(SearchPanel)
