import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import { noop } from '../../../../lib/utils/common'
import { SITE_KEY } from '../../../../lib/config/recaptcha'

const Captcha = ({ isCaptchaActive, onChange, setCaptchaRef = noop }) => (
  isCaptchaActive &&
  <div className='Form-row Form-row--mediumSpace' data-qa-captcha>
    <ReCAPTCHA
      ref={setCaptchaRef}
      sitekey={SITE_KEY}
      onChange={onChange}
    />
  </div>
)

Captcha.propTypes = {
  isCaptchaActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Captcha
