import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { defaultProps, withHandlers, compose } from 'recompose'
import { removeFromArray } from '../../../../lib/utils/collection'

const enhance = compose(
  defaultProps({
    onChange: () => {}
  }),
  withHandlers({
    onCheckboxChange: ({ onChange, value }) => option => e => {
      const newValue = value || []

      if (e.target.checked) {
        onChange([...newValue, option])
      } else {
        onChange(removeFromArray(newValue, option))
      }
    }
  })
)

const CheckboxForArray = ({ value, label, option, onCheckboxChange, isDisabled = false }) => (
  <div className={cn('Checkbox', { isDisabled })}>
    <label className='Checkbox-label'>
      <input
        className='Checkbox-input'
        type='checkbox'
        checked={value && value.includes(option) || false}
        onChange={onCheckboxChange(option)}
        disabled={isDisabled}
      />
      <span className='Checkbox-icon'/>
      {label}
    </label>
  </div>
)

CheckboxForArray.propTypes = {
  value: MobxPropTypes.arrayOrObservableArray,
  option: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isDisabled: PropTypes.bool
}

export default enhance(CheckboxForArray)
